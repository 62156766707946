import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Button from "../_UI/Button";
import Form from "../_UI/Form";
import classes from "./ChangePasswordPage.module.css";
import { useNavigate } from "react-router-dom";
import useNotification from "../../hooks/useNotification";
import { isStrongPassword } from "../../helpers/isStrongPassword";
import Input from "../_UI/Input";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useUser from "../../hooks/useUser";

type ChangePassInput = { current: string; new: string; confirm: string };

const PasswordChanger = ({
  onDone,
  payload,
}: {
  onDone: (status: boolean) => void;
  payload: ChangePassInput;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("changingPassword"));
  useSWR(
    { url: `${API}/users/changepass`, payload },
    ({ url, payload }) =>
      fetcher({
        url,
        payload: {
          currentPassword: payload.current,
          password: payload.new,
          repassword: payload.confirm,
        },
        method: "POST",
      }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("changePasswordSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("changePasswordError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const ChangePasswordPage = () => {
  useUser(true);
  const getContent = useLocale();
  const [isSaving, setIsSaving] = useState<ChangePassInput | null>(null);

  const navigate = useNavigate();
  const pushNotification = useNotification();

  const [input, setInput] = useState<ChangePassInput>({
    confirm: "",
    current: "",
    new: "",
  });

  const onChange = () => {
    if (!input.current)
      return pushNotification(getContent("checkInput"), "Error");
    if (input.new !== input.confirm)
      return pushNotification(getContent("passwordsnotMatch"), "Error");
    if (!isStrongPassword(input.new))
      return pushNotification(getContent("passwordNotStrong"), "Error");
    setIsSaving({ ...input });
  };

  const onAfterChange = (status: boolean) => {
    setIsSaving(null);
    if (!status) return;
    navigate("/dashboard");
  };

  return (
    <Fragment>
      <Form className={classes.main} onSubmit={onChange}>
        <h1 className={classes.title}>{getContent("changepassword")}</h1>
        <Input
          required
          type="password"
          label={getContent("currentPassword")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, current: e.target.value }))
          }
        />
        <Input
          required
          type="password"
          label={getContent("newPassword")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, new: e.target.value }))
          }
        />
        <Input
          required
          type="password"
          label={getContent("confirmPassword")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, confirm: e.target.value }))
          }
        />
        <Button type="submit" className={classes.submit}>
          {isSaving ? getContent("changingPassword") : getContent("save")}
        </Button>
      </Form>
      {!!isSaving && (
        <PasswordChanger onDone={onAfterChange} payload={isSaving} />
      )}
    </Fragment>
  );
};
export default ChangePasswordPage;
