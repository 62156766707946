import { useContext, useMemo } from "react";
import classes from "./Notifications.module.css";
import NotificationContext, {
  INotification,
} from "../../store/NotificationContext";
import { useTimer } from "react-timer-hook";

const reverse = <T,>(arr: T[]): T[] => {
  const temp = [...arr];
  temp.reverse();
  return temp;
};

const NOTIICATION_TTL = 10000;

const Notification = ({
  notification,
  index,
}: {
  notification: INotification;
  index: number;
}) => {
  const { dismissNotification } = useContext(NotificationContext);

  const then = useMemo<Date>(() => {
    const then = new Date();
    then.setMilliseconds(then.getMilliseconds() + NOTIICATION_TTL);
    return then;
  }, []);

  const { pause, resume, totalSeconds } = useTimer({
    expiryTimestamp: then,
    onExpire: () => dismissNotification(notification.id),
  });


  return (
    <p
      onClick={() => dismissNotification(notification.id)}
      className={`${classes.notification} ${classes[notification.status]}`}
      style={{
        transform: `translateY(calc(-${index * 100}% - ${index * 0.25}rem))`,
      }}
      onMouseEnter={() => pause()}
      onMouseLeave={() => resume()}
    >
      {notification.message}
      <span className={classes.bar}>
        <span
          className={classes.fill}
          style={{
            insetInlineEnd: `${(totalSeconds * 1000 * 100) / NOTIICATION_TTL}%`,
          }}
        />
      </span>
    </p>
  );
};

const Notifications = () => {
  const { notifications } = useContext(NotificationContext);

  return (
    <div className={classes.main}>
      {reverse(notifications.slice(-6)).map((notification, i) => (
        <Notification
          key={notification.id}
          index={i}
          notification={notification}
        />
      ))}
    </div>
  );
};
export default Notifications;
