import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeleteProfilePopup.module.css";
import { IProfile } from "./ManageSingleServerPage";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";

const ProfileDeleter = ({
  node,
  onDone,
}: {
  node: IProfile;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deletingProfile"));
  useSWR(
    `${API}/apps/destroyProfile/${node.id}`,
    (url) => fetcher({ url, method: "DELETE" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteProfileSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteProfileError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const DeleteProfilePopup = ({
  node,
  refresh,
}: {
  node: IProfile;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { closePopup } = usePopup();

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("deleteProfile")}>
        <div className={classes.main}>
          <p className={classes.message}>{getContent("sureDeleteProfile")}</p>
          <div className={classes.actions}>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {isDeleting
                ? getContent("deletingProfile")
                : getContent("deleteProfile")}
            </Button>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <ProfileDeleter node={node} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default DeleteProfilePopup;
