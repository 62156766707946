import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import PopupCard from "../Popup/PopupCard";
import Button from "./Button";
import classes from "./SignoutPopup.module.css";
import useUser from "../../hooks/useUser";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";
import { mutate } from "swr";

const Signouter = () => {
  const { refreshUser } = useUser(undefined);
  const { closePopup } = usePopup();
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("signingOut"));
  const navigate = useNavigate();
  const { setToken } = useToken();
  useSWR(
    `${API}/users/logout`,
    (url) => fetcher({ url }).then((res) => res.success),
    {
      onSuccess: () => {
        setToken("");
        mutate(() => true, undefined, { revalidate: false });
        setTimeout(refreshUser);
        pushNotification(getContent("SignoutSuccess"), "Success");
        navigate("/");
        closePopup();
      },
      onError: () => {
        pushNotification(getContent("SignoutError"), "Error");
      },
    }
  );

  return null;
};

const SignoutPopup = () => {
  const getContent = useLocale();
  const { closePopup } = usePopup();
  const [isSigningout, setIsSigningout] = useState<boolean>(false);

  return (
    <Fragment>
      <PopupCard title={getContent("signoutPopupTitle")}>
        <div className={classes.main}>
          <p>{getContent("areYouSureSignout")}</p>
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("no")}</Button>
            <Button
              style={{ backgroundColor: "var(--danger)" }}
              onClick={() => setIsSigningout(true)}
            >
              {getContent(isSigningout ? "signingOut" : "sureSignout")}
            </Button>
          </div>
        </div>
      </PopupCard>
      {!!isSigningout && <Signouter />}
    </Fragment>
  );
};
export default SignoutPopup;
