import { ReactNode } from "react";
import classes from "./PopupCard.module.css";
import IconButton from "../_UI/IconButton";
import { CloseIcon } from "../Icons/CloseIcon";
import usePopup from "../../hooks/usePopup";
const PopupCard = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  const { closePopup } = usePopup();
  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <legend>{title}</legend>
        <IconButton onClick={closePopup}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
export default PopupCard;
