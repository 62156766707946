import { Fragment, useRef, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Form from "../_UI/Form";
import PopupCard from "../Popup/PopupCard";
import classes from "./CreateNewServerPopup.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import usePopup from "../../hooks/usePopup";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import { ICountry, IServer } from "./ManageAllServersPage";
import SelectSegment from "../_UI/SelectSegment";

type CreateServerInput = {
  username: null;
  password: null;
  port: null;
  countryId?: number;
  appId?: number[];
  ip?: string;
};

const ServerCreator = ({
  payload,
  onDone,
  edit,
}: {
  payload: CreateServerInput;
  onDone: (status: boolean) => void;
  edit?: string;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(
    getContent(!!edit ? "updatingServer" : "creatingServer")
  );
  useSWR(
    {
      url: !!edit
        ? `${API}/apps/updateServer/${edit}`
        : `${API}/apps/addServer`,
      payload,
    },
    (args) =>
      fetcher({ ...args, method: !!edit ? "PUT" : "POST" }).then(
        (res) => res.success
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(
          getContent(edit ? "updateServerSuccess" : "createServerSuccess"),
          "Success"
        );
        onDone(true);
      },
      onError: () => {
        pushNotification(
          getContent(edit ? "updateServerError" : "createServerError"),
          "Error"
        );
        onDone(false);
      },
    }
  );
  return null;
};

const CreateNewServerPopup = ({
  refresh,
  defaultValue,
}: {
  refresh: Function;
  defaultValue?: IServer;
}) => {
  const getContent = useLocale();

  const { data: countries } = useSWR<ICountry[]>(
    `${API}/apps/countries`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const { closePopup } = usePopup();

  const pushNotification = useNotification();

  const [input, setInput] = useState<CreateServerInput>(
    defaultValue
      ? {
          appId: defaultValue.apps.map((app) => Number(app.app.id)),
          countryId: Number(defaultValue.location.id),
          ip: defaultValue.ip,
          username: null,
          password: null,
          port: null,
        }
      : {
          username: null,
          password: null,
          port: null,
        }
  );

  const nameRef = useRef<HTMLInputElement>(null);

  const [isCreating, setIsCreating] = useState<CreateServerInput | null>(null);

  const onCreate = () => {
    if (
      input.appId === undefined ||
      input.countryId === undefined ||
      !input.appId?.length ||
      !input.ip?.trim().length
    )
      return pushNotification(getContent("checkInput"), "Error");
    setIsCreating(input);
  };

  const onAfterCreate = (status: boolean) => {
    setIsCreating(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("createServer")}>
        <Form className={classes.main} onSubmit={onCreate}>
          <SelectSegment
            required
            defaultValue={defaultValue?.apps.map((app) => Number(app.app.id))}
            multi={true}
            onChange={(val) => setInput((prev) => ({ ...prev, appId: val }))}
            segment="application"
          />
          <SelectSegment
            required
            multi={false}
            onChange={(val) => {
              setInput((prev) => ({ ...prev, countryId: val }));
              if (!nameRef.current || !countries) return;
              const country = countries.find(
                (cunt) => `${cunt.id}` === `${val}`
              );
              if (!country) return;
              nameRef.current.value = `${country.code}_Server`;
              setInput((prev) => ({ ...prev, ip: `${country.code}_Server` }));
            }}
            segment="country"
            defaultValue={
              defaultValue ? Number(defaultValue.location.id) : undefined
            }
          />
          <Input
            ref={nameRef}
            defaultValue={defaultValue?.ip}
            className={classes.name}
            label={getContent("serverName")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, ip: e.target.value }))
            }
          />
          <div className={classes.actions}>
            <Button type="submit">
              {isCreating
                ? getContent("creatingServer")
                : getContent("createServer")}
            </Button>
            <Button
              type="button"
              style={{ backgroundColor: "var(--danger)" }}
              onClick={closePopup}
            >
              {getContent("cancel")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isCreating && (
        <ServerCreator
          edit={defaultValue?.id}
          onDone={onAfterCreate}
          payload={isCreating}
        />
      )}
    </Fragment>
  );
};
export default CreateNewServerPopup;
