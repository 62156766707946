import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import Table, { FilterComponent } from "../_UI/Table";
import { IApp } from "../Servers/ManageAllServersPage";
import classes from "./MyAppsList.module.css";
import { IUser } from "../../hooks/useUser";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import IconButton from "../_UI/IconButton";
import { EyeIcon } from "../Icons/EyeIcon";
import usePopup from "../../hooks/usePopup";
import AppOverviewPopup from "./AppOverviewPopup";
import { CogIcon } from "../Icons/CogIcon";
import { useNavigate } from "react-router-dom";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import { CopyIcon } from "../Icons/CopyIcon";
import { useClipboard } from "../../hooks/useClipboard";

type MyAppsFilter = { name?: string };

const FilterMyApps: FilterComponent<MyAppsFilter> = ({ setFilter }) => {
  const getContent = useLocale();
  return (
    <div className={classes.filter}>
      <Input
        label={getContent("search")}
        onChange={(e) =>
          setFilter((prev) => ({
            ...prev,
            name: e.target.value.trim().toLowerCase(),
          }))
        }
      />
      <Button
        onClick={() => setFilter((prev) => ({ ...prev, name: undefined }))}
      >
        {getContent("clear")}
      </Button>
    </div>
  );
};

const filterer = (node: IApp, filter: MyAppsFilter) => {
  if (
    filter?.name &&
    !node.name.toLowerCase().includes(filter.name.toLowerCase())
  )
    return false;
  return true;
};

const MyAppsList = ({ apps }: { apps: IApp[] }) => {
  const getContent = useLocale();
  const copyTextToClipboard = useClipboard();
  const { data: admins } = useSWR<IUser[]>(
    `${API}/users/appAdmins`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );
  const navigate = useNavigate();

  const { setPopup } = usePopup();

  return (
    <Table
      data={apps.map((app, index) => ({ ...app, index: index + 1 }))}
      filter={{ filter: filterer, filterComponent: FilterMyApps }}
      exporter={{
        name: { name: getContent("name"), valueGetter: (node) => node.name },
        admin: {
          name: getContent("appAdmin"),
          valueGetter: (node) =>
            `${admins?.find((admin) => admin.id === node.adminId)?.name} ${
              admins?.find((admin) => admin.id === node.adminId)?.family
            }`,
        },
        package: {
          name: getContent("packageName"),
          valueGetter: (node) => node.packageName,
        },
        apiKey: {
          name: "Api Key",
          valueGetter: (node) => node.apiKey,
        },
        goolePlayLink: {
          name: getContent("googlePlayLink"),
          valueGetter: (node) => node.googlePlayLink,
        },
      }}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        name: {
          name: getContent("name"),
          component: (node) => <span>{node.name}</span>,
          sort: (a, b) => a.index - b.index,
        },
        package: {
          name: getContent("packageName"),
          component: (node) => <span>{node.packageName}</span>,
          sort: (a, b) => a.packageName.localeCompare(b.packageName),
        },
        admin: {
          name: getContent("appAdmin"),
          component: (node) => (
            <span>
              {admins?.find((admin) => admin.id === node.adminId)?.name}{" "}
              {admins?.find((admin) => admin.id === node.adminId)?.family}
            </span>
          ),
          sort: (a, b) =>
            `${admins?.find((admin) => admin.id === a.adminId)?.name} ${
              admins?.find((admin) => admin.id === a.adminId)?.family
            }`.localeCompare(
              `${admins?.find((admin) => admin.id === b.adminId)?.name} ${
                admins?.find((admin) => admin.id === b.adminId)?.family
              }`
            ),
        },
        apiKey: {
          name: "Api Key",
          component: (node) => (
            <div
              className={classes.key}
              onClick={() => copyTextToClipboard(node.apiKey)}
            >
              <span className={classes.copy}>
                <CopyIcon />
              </span>
              <span>{node.apiKey}</span>
            </div>
          ),
          sort: (a, b) => a.apiKey.localeCompare(b.apiKey),
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                style={{ backgroundColor: "var(--green)" }}
                title={getContent("appDetails")}
                onClick={() => setPopup(<AppOverviewPopup app={node} />)}
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--blue)" }}
                onClick={() => navigate(`/app/${node.id}`)}
              >
                <CogIcon />
              </IconButton>
            </span>
          ),
        },
      }}
      title={getContent("myApplications")}
    />
  );
};
export default MyAppsList;
