import useLocale from "./useLocale";
import useNotification from "./useNotification";

export const useClipboard = () => {
  const getContent = useLocale();
  const pushNotification = useNotification();

  function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      if (successful) {
        pushNotification(getContent("copySuccess"), "Success");
      } else {
        pushNotification(getContent("copyError"), "Error");
      }
    } catch {
      pushNotification(getContent("copyError"), "Error");
    }
    document.body.removeChild(textArea);
  }

  const copyTextToClipboard = async (text: string) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      pushNotification(getContent("copySuccess"), "Success");
    } catch {
      pushNotification(getContent("copyError"), "Error");
    }
  };
  return copyTextToClipboard;
};
