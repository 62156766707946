import { Fragment, ReactNode, useMemo, useState } from "react";
import classes from "./TabSystem.module.css";
const TabSystem = ({
  items,
}: {
  items: { name: string; id: string; content: ReactNode }[];
}) => {
  const [currentTab, setCurrentTab] = useState<string>(items[0]?.id || "");

  const currentContent = useMemo<ReactNode>(
    () => items.find((item) => item.id === currentTab)?.content || <Fragment />,
    [currentTab, items]
  );

  return (
    <div className={classes.main}>
      <nav className={classes.nav}>
        {items.map((item) => (
          <button
            key={item.id}
            className={`${classes.navItem} ${
              currentTab === item.id ? classes.activeNavItem : ""
            }`}
            onClick={() => setCurrentTab(item.id)}
          >
            {item.name}
          </button>
        ))}
      </nav>
      <div className={classes.content}>{currentContent}</div>
    </div>
  );
};
export default TabSystem;
