import { LoaderIcon } from "../Icons/Loader";
import classes from "./Loading.module.css";
const Loading = () => {
  return (
    <div className={classes.main}>
      <LoaderIcon />
    </div>
  );
};
export default Loading;
