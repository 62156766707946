import { ReactNode, useContext, useState } from "react";
import classes from "./Layout.module.css";
import Notifications from "../Notification/Notifications";
import { SWRConfig } from "swr";
import useNotification from "../../hooks/useNotification";
import Header from "./Header";
import Sidebar from "./Sidebar";
import LocaleContext, { Locale } from "../../store/LocaleContext";
import { Outlet } from "react-router-dom";
import { PopupContextProvider } from "../../store/PopupContext";
import Popup from "../Popup/Popup";
import { shouldKeepSidebarOpen } from "../../Constants/shouldKeepSidebarOpen";

const localeToDir: { [key in Locale]: "ltr" | "rtl" } = {
  en: "ltr",
  fa: "rtl",
};

const Layout = (props: { children?: ReactNode }) => {
  const pushNotification = useNotification();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    shouldKeepSidebarOpen()
  );

  const { locale } = useContext(LocaleContext);

  return (
    <SWRConfig
      value={{
        onError: () => {
          pushNotification("خطایی رخ داده");
        },
      }}
    >
      <PopupContextProvider>
        <div className={classes.container} dir={localeToDir[locale]}>
          <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} />
          <div className={classes.main}>
            <Header toggleSidebar={setIsSidebarOpen} />
            <div className={classes.content}>
              <Outlet />
              {props.children}
            </div>
          </div>
        </div>
        <Popup />
        <Notifications />
      </PopupContextProvider>
    </SWRConfig>
  );
};
export default Layout;
