import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import Input from "../_UI/Input";
import SelectSegment from "../_UI/SelectSegment";
import PopupCard from "../Popup/PopupCard";
import classes from "./CreateDevicePopup.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import { Fragment, useEffect, useState } from "react";
import { IApp } from "../Servers/ManageAllServersPage";
import Button from "../_UI/Button";
import usePopup from "../../hooks/usePopup";
import useNotification from "../../hooks/useNotification";
import { IDevice } from "./ManageDevicesPage";
import Loading from "../_UI/Loading";
import ErrorPage from "../Error/ErrorPage";

type CreateDeviceInput = {
  fcmToken: string;
  uniqueDeviceId: string;
  appapikey: string;
};

const DeviceCreator = ({
  payload,
  onDone,
}: {
  payload: CreateDeviceInput;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("creatingDevice"));
  useSWR(
    { url: `${API}/apps/addDevice`, payload },
    ({ url, payload }) =>
      fetcher({
        url,
        payload: {
          fcmToken: payload.fcmToken,
          uniqueDevideId: payload.uniqueDeviceId,
        },
        headers: { appapikey: payload.appapikey },
        method: "POST",
      }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("createDeviceSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("createDeviceError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const CreateDevicePopup = ({
  refresh,
  defaultValue,
}: {
  refresh: Function;
  defaultValue?: IDevice;
}) => {
  const getContent = useLocale();
  const { data: apps, error } = useSWR<IApp[]>(`${API}/apps`, (url: string) =>
    fetcher({ url }).then((res) => res.data)
  );

  const [input, setInput] = useState<{
    [key in keyof CreateDeviceInput]?: string;
  }>({});

  useEffect(() => {
    if (apps && defaultValue) {
      setInput({
        appapikey: apps.find((app) => `${app.id}` === `${defaultValue.app.id}`)
          ?.apiKey,
        fcmToken: defaultValue.fcmToken,
        uniqueDeviceId: defaultValue.uniqueDeviceId,
      });
    }
  }, [apps, defaultValue]);

  const [isCreating, setIsCreating] = useState<CreateDeviceInput | null>(null);

  const { closePopup } = usePopup();

  const pushNotification = useNotification();

  const onCreate = () => {
    if (!input.uniqueDeviceId || !input.fcmToken || !input.appapikey)
      return pushNotification(getContent("checkInput"), "Error");
    setIsCreating({ ...input } as CreateDeviceInput);
  };

  const onAfterCreate = (status: boolean) => {
    setIsCreating(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  if (!apps && error) return <ErrorPage />;
  if (!apps) return <Loading />;
  return (
    <Fragment>
      <PopupCard
        title={getContent(!!defaultValue ? "editDevice" : "createDevice")}
      >
        <div className={classes.main}>
          <SelectSegment
            required
            segment="application"
            multi={false}
            onChange={(e) =>
              setInput((prev) => ({
                ...prev,
                appapikey: apps?.find((app) => `${app.id}` === `${e}`)?.apiKey,
              }))
            }
            defaultValue={Number(
              apps.find((app) => `${app.id}` === `${defaultValue?.app.id}`)?.id
            )}
          />
          <Input
            required
            label={getContent("deviceId")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, uniqueDeviceId: e.target.value }))
            }
            defaultValue={defaultValue?.uniqueDeviceId}
          />
          <Input
            required
            label={getContent("firebaseToken")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, fcmToken: e.target.value }))
            }
            defaultValue={defaultValue?.fcmToken}
          />
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            <Button onClick={onCreate}>{getContent("createDevice")}</Button>
          </div>
        </div>
      </PopupCard>
      {!!isCreating && (
        <DeviceCreator onDone={onAfterCreate} payload={isCreating} />
      )}
    </Fragment>
  );
};
export default CreateDevicePopup;
