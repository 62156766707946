import { ChangeEventHandler, CSSProperties, forwardRef } from "react";
import classes from "./Input.module.css";
import useNotification from "../../hooks/useNotification";
import useLocale from "../../hooks/useLocale";

export type InputProps<T> = {
  onChange?: ChangeEventHandler<T>;
  defaultValue?: string;
  label: string;
  type?: "text" | "password" | "number";
  className?: string;
  style?: CSSProperties;
  readonly?: boolean;
  step?: number;
  min?: number;
  id?: string;
  required?: boolean;
  allowPersian?: boolean;
};

const Input = forwardRef<HTMLInputElement, InputProps<HTMLInputElement>>(
  (props, ref) => {
    const pushNotification = useNotification();
    const getContent = useLocale();
    return (
      <div
        className={`${classes.main} ${props.className || ""}`}
        style={props.style}
      >
        <input
          ref={ref}
          readOnly={props.readonly}
          className={`${classes.input} ${
            props.readonly ? classes.readonly : ""
          }`}
          type={props.type}
          placeholder=""
          onChange={(e) => {
            if (
              !props.allowPersian &&
              !/^[a-zA-Z0-9\s!@#$%^&*()-_+=]*$/.test(e.target.value)
            ) {
              e.target.value = e.target.getAttribute("prev") || "";
              pushNotification(getContent("onlyEnglish"), "Error");
              return;
            }
            e.target.setAttribute("prev", e.target.value);
            props.onChange?.(e);
          }}
          id={props.id || props.label}
          defaultValue={props.defaultValue}
          step={props.step}
          min={props.min}
        />
        <label className={classes.label} htmlFor={props.id || props.label}>
          {!!props.required && <span className={classes.star}>*</span>}
          <span>{props.label}</span>
        </label>
      </div>
    );
  }
);
export default Input;
