import useSWR from "swr";
import { API } from "../../config";
import { useParams } from "react-router-dom";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import TabSystem from "../_UI/TabSystem";
import useLocale from "../../hooks/useLocale";
import BlockNewVersionForm from "./BlockNewVersionForm";
import VersionList from "./VersionList";

export interface IVersion {
  id: number;
  appVersion: string;
  status: boolean;
  appId: number;
  createdAt: Date;
  updatedAt: Date;
  app: {
    name: string;
  };
}

const AppBlockList = () => {
  const { appId } = useParams();
  const { data, error, mutate } = useSWR<IVersion[]>(
    `${API}/apps/versionBlock/${appId}`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const getContent = useLocale();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <TabSystem
      items={[
        {
          name: getContent("blockNewVersion"),
          content: <BlockNewVersionForm refresh={mutate} versions={data} />,
          id: "blockVersion",
        },
        {
          name: getContent("allVersions"),
          content: (
            <VersionList
              refresh={mutate}
              versions={data.filter((version) => version.status === true)}
              title={getContent("allVersions")}
            />
          ),
          id: "allVersions",
        },
        {
          name: getContent("blockedVersions"),
          content: (
            <VersionList
              refresh={mutate}
              title={getContent("blockedVersions")}
              versions={data.filter((version) => version.status === false)}
            />
          ),
          id: "BlockedVersions",
        },
      ]}
    />
  );
};
export default AppBlockList;
