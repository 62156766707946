import { Dispatch, SetStateAction, useContext } from "react";
import classes from "./Header.module.css";
import useUser from "../../hooks/useUser";
import LocaleContext from "../../store/LocaleContext";
import IconButton from "../_UI/IconButton";
import { IranIcon } from "../Icons/IranIcon";
import { UsaIcon } from "../Icons/UsaIcon";
import { OffIcon } from "../Icons/OffIcon";
import { HomeIcon } from "../Icons/HomeIcon";
import { MenuIcon } from "../Icons/MenuIcon";
import { useNavigate } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import SignoutPopup from "../_UI/SignoutPopup";
const Header = ({
  toggleSidebar,
}: {
  toggleSidebar: Dispatch<SetStateAction<boolean>>;
}) => {
  const { setLocale } = useContext(LocaleContext);
  const { user } = useUser(undefined);
  const navigate = useNavigate();
  const getContent = useLocale();
  const { setPopup } = usePopup();
  if (!user) return null;
  return (
    <div className={classes.main}>
      <IconButton
        onClick={() => toggleSidebar((prev) => !prev)}
        style={{ color: "var(--black)", width: "2rem", height: "2rem" }}
      >
        <MenuIcon />
      </IconButton>
      <div className={classes.actions}>
        <div className={classes.langs}>
          <IconButton
            style={{ width: "2rem", height: "2rem" }}
            onClick={() => setLocale("fa")}
            title={getContent("farsi")}
          >
            <IranIcon />
          </IconButton>
          <IconButton
            style={{ width: "2rem", height: "2rem" }}
            onClick={() => setLocale("en")}
            title={getContent("english")}
          >
            <UsaIcon />
          </IconButton>
        </div>
        <div className={classes.user}>
          <IconButton
            onClick={() => navigate("/account/user-info")}
            style={{ width: "2rem", height: "2rem" }}
            title={getContent("profile")}
          >
            <img src={user.avatar} alt={user.name} className={classes.image} />
          </IconButton>
          <IconButton
            title={getContent("dashboard")}
            onClick={() => navigate("/dashboard")}
            style={{
              backgroundColor: "var(--blue)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <HomeIcon />
          </IconButton>
          <IconButton
            title={getContent("signout")}
            onClick={() => setPopup(<SignoutPopup />)}
            style={{
              backgroundColor: "var(--danger)",
              width: "2rem",
              height: "2rem",
            }}
          >
            <OffIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
export default Header;
