import { CSSProperties, ReactNode } from "react";
const Form = (props: {
  onSubmit?: () => unknown;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSubmit?.();
      }}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </form>
  );
};
export default Form;
