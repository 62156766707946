import classes from "./Input.module.css";
import { InputProps } from "./Input";
const Area = (props: InputProps<HTMLTextAreaElement>) => {
  return (
    <div
      className={`${classes.main} ${classes.area} ${props.className || ""}`}
      style={props.style}
    >
      <textarea
        className={`${classes.input}`}
        onChange={(e) => props.onChange?.(e)}
        id={props.label}
        defaultValue={props.defaultValue}
      />
      <label className={classes.label} htmlFor={props.label}>
        {!!props.required && <span className={classes.star}>*</span>}
        <span>{props.label}</span>
      </label>
    </div>
  );
};
export default Area;
