import classes from "./Pair.module.css";
const Pair = ({ title, value }: { title: string; value: string }) => {
  return (
    <p className={classes.main}>
      <span className={classes.title}>{title}</span>
      <span>:</span>
      <span className={classes.value}>{value}</span>
    </p>
  );
};
export default Pair;
