import useSWR from "swr";
import classes from "./AddClientModule.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import useLocale from "../../hooks/useLocale";
import { IMenu } from "./AccessMenuPage";
import { Fragment, useEffect, useMemo, useState } from "react";
import Title from "../_UI/Title";
import Button from "../_UI/Button";
const AddClientModule = () => {
  const { data, error } = useSWR<IMenu[]>(
    `${API}/access/menusLimit`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const [selectedParentId, setSelectedParentId] = useState<string>("");

  const [, setSelectedChildId] = useState<string>("");

  const selectedParent = useMemo<IMenu | undefined>(
    () => data?.find((el) => `${el.id}` === selectedParentId),
    [data, selectedParentId]
  );

  const onSave = () => {};

  useEffect(() => {
    setSelectedChildId("");
  }, [selectedParentId]);

  const getContent = useLocale();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <div className={classes.main}>
      <Title>{getContent("clientModules")}</Title>
      <div className={classes.selects}>
        <div className={classes.select}>
          <legend>انتخاب منو</legend>
          <select onChange={(e) => setSelectedParentId(e.target.value)}>
            <option value="">انتخاب منو</option>
            {data.map((menu) => (
              <option key={menu.id} value={menu.id}>
                {menu.pName}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.select}>
          {!!selectedParent?.children.length && (
            <Fragment>
              <legend>انتخاب زیر منو</legend>
              <select onChange={(e) => setSelectedChildId(e.target.value)}>
                <option value="">انتخاب زیر منو</option>
                {selectedParent.children.map((child) => (
                  <option key={child.id} value={child.id}>
                    {child.pName}
                  </option>
                ))}
              </select>
            </Fragment>
          )}
        </div>
      </div>
      <Button onClick={onSave} className={classes.submit}>
        {getContent("save")}
      </Button>
    </div>
  );
};
export default AddClientModule;
