import useLocale from "../../hooks/useLocale";
import useUser from "../../hooks/useUser";
import VideoBox from "./VideoBox";
const PanelTrainingPage = () => {
  useUser(true);
  const getContent = useLocale();
  return (
    <VideoBox
      text={getContent("toturialText")}
      title={getContent("toturial")}
    />
  );
};
export default PanelTrainingPage;
