import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeleteServerPopup.module.css";
import { IServer } from "./ManageAllServersPage";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";

const ServerDeleter = ({
  id,
  onDone,
}: {
  id: string;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deletingServer"));
  useSWR(
    `${API}/apps/destroyServer/${id}`,
    (url) => fetcher({ url, method: "DELETE" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onError: () => {
        pushNotification(getContent("deleteServerError"), "Error");
        onDone(false);
      },
      onSuccess: () => {
        pushNotification(getContent("deleteServerSuccess"), "Success");
        onDone(true);
      },
    }
  );
  return null;
};

const DeleteServerPopup = (props: { server: IServer; refresh: Function }) => {
  const getContent = useLocale();
  const { closePopup } = usePopup();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (status) {
      closePopup();
      props.refresh();
    }
  };

  return (
    <Fragment>
      <PopupCard title={getContent("DeleteServer")}>
        <div className={classes.main}>
          <p>{getContent("areYouSureDeleteServer")}</p>
          <div className={classes.actions}>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {isDeleting
                ? getContent("deletingServer")
                : getContent("sureDeleteServer")}
            </Button>
            <Button onClick={closePopup}>{getContent("no")}</Button>
          </div>
        </div>
      </PopupCard>
      {!!isDeleting && (
        <ServerDeleter id={props.server.id} onDone={onAfterDelete} />
      )}
    </Fragment>
  );
};
export default DeleteServerPopup;
