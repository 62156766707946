import { Fragment, useState } from "react";
import { IUser } from "../../hooks/useUser";
import Toggle from "../_UI/Toggle";
import classes from "./UserStatus.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import useLocale from "../../hooks/useLocale";

const Toggler = ({
  user,
  onDone,
}: {
  user: IUser;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("togglingStatus"));
  useSWR(
    `${API}/users/changestatus/${user.id}`,
    (url) =>
      fetcher({ url, payload: { active: !user.active }, method: "PUT" }).then(
        (res) => res.success
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("toggleStatusSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("toggleStatusError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const UserStatus = ({ user, refresh }: { user: IUser; refresh: Function }) => {
  const [isToggling, setIsToggling] = useState<boolean>(false);

  const onAfterToggle = (status: boolean) => {
    setIsToggling(false);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <Toggle active={user.active} onClick={() => setIsToggling(true)} />
      </div>
      {isToggling && <Toggler onDone={onAfterToggle} user={user} />}
    </Fragment>
  );
};
export default UserStatus;
