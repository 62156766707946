import { useParams } from "react-router-dom";
import classes from "./ManageSingleServerPage.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import TabSystem from "../_UI/TabSystem";
import useLocale from "../../hooks/useLocale";
import Table from "../_UI/Table";
import Loading from "../_UI/Loading";
import NewProfileForm from "./NewProfileForm";
import BatchImportProfilesForm from "./BatchImportProfilesForm";
import IconButton from "../_UI/IconButton";
import { EditIcon } from "../Icons/EditIcon";
import usePopup from "../../hooks/usePopup";
import { EyeIcon } from "../Icons/EyeIcon";
import PreviewProfilePopup from "./PreviewProfilePopup";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import DeleteProfilePopup from "./DeleteProfilePopup";
import useUser from "../../hooks/useUser";
import BatchConfigDelete from "./BatchConfigDeletePopup";

export const operators = ["irancell", "mci", "wifi", "others"] as const;

export type Operator = (typeof operators)[number];

export const protocols = ["vmess", "vless"] as const;

export type Protocol = (typeof protocols)[number];

export const profileTypes = ["normal", "smart"] as const;

export type ProfileType = (typeof profileTypes)[number];

export interface IProfile {
  address: string;
  count: number;
  createdAt: Date;
  id: number;
  name: string;
  operator: Operator;
  protocol: Protocol;
  serverId: number;
  type: ProfileType;
  updatedAt: Date;
}

const ManageSingleServerPage = () => {
  useUser(true);
  const { serverId } = useParams();

  const getContent = useLocale();

  const { data, error, mutate } = useSWR<IProfile[]>(
    `${API}/apps/profiles/${serverId}`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const { setPopup } = usePopup();

  // const maxName = useMemo<number>(
  //   () =>
  //     Math.max(
  //       ...[
  //         ...Array.from(
  //           data
  //             ?.map((conf) => conf.name)
  //             .join("___")
  //             .matchAll(/S-(\d{2})/g) || []
  //         )
  //           .map((match) => Number(match[1]))
  //           .filter((num) => !isNaN(num)),
  //         0,
  //       ]
  //     ) + 1,
  //   [data]
  // );

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <TabSystem
      items={[
        {
          name: getContent("profileList"),
          id: "ProfileList",
          content: (
            <Table
              data={data.map((node, i) => ({ ...node, index: i + 1 }))}
              title={getContent("profileList")}
              batchActions={[
                {
                  name: getContent("delete"),
                  action: (nodes) =>
                    setPopup(
                      <BatchConfigDelete refresh={mutate} profiles={nodes} />
                    ),
                },
              ]}
              renderer={{
                index: {
                  name: "#",
                  component: (node) => <span>{node.index}</span>,
                  width: "2rem",
                  sort: (a, b) => a.index - b.index,
                },
                name: {
                  name: getContent("name"),
                  component: (node) => <span>{node.name}</span>,
                  sort: (a, b) => a.name.localeCompare(b.name),
                },
                protocol: {
                  name: getContent("protocol"),
                  component: (node) => <span>{node.protocol}</span>,
                  sort: (a, b) => a.protocol.localeCompare(b.protocol),
                },
                operator: {
                  name: getContent("operator"),
                  component: (node) => <span>{node.operator}</span>,
                  sort: (a, b) => a.operator.localeCompare(b.operator),
                },
                actions: {
                  name: getContent("actions"),
                  component: (node) => (
                    <span className={classes.actions}>
                      <IconButton
                        title={getContent("profileDetails")}
                        style={{ backgroundColor: "var(--green)" }}
                        onClick={() =>
                          setPopup(<PreviewProfilePopup profile={node} />)
                        }
                      >
                        <EyeIcon />
                      </IconButton>
                      <IconButton
                        title={getContent("editProfile")}
                        onClick={() =>
                          setPopup(
                            <NewProfileForm
                              serverId={`${serverId}`}
                              refresh={mutate}
                              defaultValue={{ ...node, address: "" }}
                              start={1}
                            />
                          )
                        }
                        style={{ backgroundColor: "var(--cyan)" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        title={getContent("deleteProfile")}
                        style={{ backgroundColor: "var(--danger)" }}
                        onClick={() =>
                          setPopup(
                            <DeleteProfilePopup node={node} refresh={mutate} />
                          )
                        }
                      >
                        <GarbageIcon />
                      </IconButton>
                    </span>
                  ),
                },
              }}
            />
          ),
        },
        {
          name: getContent("batchProfileImport"),
          id: "BatchImport",
          content: (
            <BatchImportProfilesForm
              serverId={`${serverId}`}
              refresh={mutate}
            />
          ),
        },
        {
          name: getContent("newProfile"),
          id: "NewProfile",
          content: (
            <NewProfileForm
              start={data.length + 1}
              serverId={`${serverId}`}
              refresh={mutate}
            />
          ),
        },
      ]}
    />
  );
};
export default ManageSingleServerPage;
