import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import Pair from "../_UI/Pair";
import PopupCard from "../Popup/PopupCard";
import { IServer } from "./ManageAllServersPage";
import classes from "./ServerOverviewPopup.module.css";
const ServerOverviewPopup = ({ server }: { server: IServer }) => {
  const getContent = useLocale();
  const { closePopup } = usePopup();
  return (
    <PopupCard title={getContent("ServerDetails")}>
      <div className={classes.main}>
        <Pair title={getContent("country")} value={server.location.name} />
        <Pair title={getContent("username")} value={server.username || ""} />
        <Pair title={getContent("password")} value={server.password || ""} />
        <Pair title={getContent("provider")} value={server.provider || ""} />
        <Button onClick={closePopup}>{getContent("close")}</Button>
      </div>
    </PopupCard>
  );
};
export default ServerOverviewPopup;
