import useSWR from "swr";
import classes from "./AccessMenuPage.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Loading from "../_UI/Loading";
import ErrorPage from "../Error/ErrorPage";
import Table from "../_UI/Table";
import useLocale from "../../hooks/useLocale";
import MenuToggler from "./MenuToggler";
import IconButton from "../_UI/IconButton";
import usePopup from "../../hooks/usePopup";
import EditMenuPopup from "./EditMenuPopup";
import { EditIcon } from "../Icons/EditIcon";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import DeleteMenuPopup from "./DeleteMenuPopup";
import useUser from "../../hooks/useUser";

export interface IMenuFeature {
  id: number;
  menu_id: number;
  feature: string;
  web_services: string;
  status: boolean;
  key: string;
  description: string | null;
  flag: null;
  createdAt: Date;
  updatedAt: Date;
}

export interface IMenu {
  id: number;
  enName: string;
  pName: string;
  route: string;
  icon: string;
  parent_id: number | null;
  status: boolean;
  flag: null | string;
  createdAt: Date;
  updatedAt: Date;
  MenuFeature: IMenuFeature[];
  children: IMenu[];
}

const AccessMenuPage = () => {
  useUser(true);
  const { data, error, mutate } = useSWR<IMenu[]>(
    `${API}/access/menus`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const getContent = useLocale();

  const { setPopup } = usePopup();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <Table
      data={data.map((node, index) => ({ ...node, index: index + 1 }))}
      title={getContent("menus")}
      create={{
        popup: <EditMenuPopup refresh={mutate} />,
        title: getContent("createMenu"),
      }}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        name: {
          name: getContent("persianName"),
          component: (node) => <span>{node.pName}</span>,
        },
        enName: {
          name: getContent("eName"),
          component: (node) => <span>{node.enName}</span>,
        },
        route: {
          name: getContent("menuRoute"),
          component: (node) => <span dir="ltr">{node.route}</span>,
        },
        status: {
          name: getContent("status"),
          component: (node) => <MenuToggler menu={node} refresh={mutate} />,
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                onClick={() =>
                  setPopup(
                    <EditMenuPopup refresh={mutate} defaultValue={node} />
                  )
                }
                style={{ backgroundColor: "var(--cyan)" }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  setPopup(<DeleteMenuPopup menu={node} refresh={mutate} />)
                }
                style={{ backgroundColor: "var(--danger)" }}
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
    />
  );
};
export default AccessMenuPage;
