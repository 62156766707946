import { Fragment, useContext } from "react";
import classes from "./Popup.module.css";
import PopupContext from "../../store/PopupContext";
const Popup = () => {
  const { popup, closePopup } = useContext(PopupContext);
  if (!popup) return null;
  return (
    <Fragment>
      <div className={classes.bg} onClick={closePopup} />
      <div className={classes.content}>{popup}</div>
    </Fragment>
  );
};
export default Popup;
