import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import classes from "./Button.module.css";
const Button = (props: {
  children: string;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
  type?: "button" | "submit";
}) => {
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={`${classes.main} ${props.className || ""}`}
      style={props.style}
    >
      {props.children}
    </button>
  );
};
export default Button;
