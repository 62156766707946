import useSWR from "swr";
import classes from "./UsersListPage.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useUser, { IUser } from "../../hooks/useUser";
import Loading from "../_UI/Loading";
import ErrorPage from "../Error/ErrorPage";
import Table, { FilterComponent } from "../_UI/Table";
import useLocale from "../../hooks/useLocale";
import IconButton from "../_UI/IconButton";
import { EyeIcon } from "../Icons/EyeIcon";
import usePopup from "../../hooks/usePopup";
import UserOverviewPopup from "./UserOverviewPopup";
import EditUserPopup from "./EditUserPopup";
import { EditIcon } from "../Icons/EditIcon";
import { CogIcon } from "../Icons/CogIcon";
import UserPassPopup from "./UserPassPopup";
import { DiamondIcon } from "../Icons/DiamondIcon";
import EditUserRolePopup from "./EditUserRolePopup";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import DeleteUserPopup from "./DeleteUserPopup";
import UserStatus from "./UserStatus";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import SelectSegment from "../_UI/SelectSegment";

const stringToState = {
  all: undefined,
  active: true,
  inactive: false,
} as const;

type UserFilter = { name?: string; role?: number; status?: boolean };

const FilterUsers: FilterComponent<UserFilter> = ({ filter, setFilter }) => {
  const getContent = useLocale();
  return (
    <div className={classes.filter}>
      <div className={classes.segment}>
        <Input
          label={getContent("search")}
          onChange={(e) =>
            setFilter((prev) => ({
              ...prev,
              name: e.target.value.trim().toLowerCase(),
            }))
          }
          allowPersian
        />
        <Button
          onClick={() => setFilter((prev) => ({ ...prev, name: undefined }))}
        >
          {getContent("clear")}
        </Button>
      </div>
      <div className={classes.segment}>
        <SelectSegment
          multi={false}
          onChange={(val) => setFilter((prev) => ({ ...prev, role: val }))}
          segment="role"
        />
        <Button
          onClick={() => setFilter((prev) => ({ ...prev, role: undefined }))}
        >
          {getContent("clear")}
        </Button>
      </div>
      <div className={classes.segment}>
        <select
          className={classes.select}
          onChange={(e) =>
            setFilter((prev) => ({
              ...prev,
              status: (stringToState as any)[e.target.value],
            }))
          }
          defaultValue="all"
        >
          <option value="all">{getContent("allUsers")}</option>
          <option value="active">{getContent("activeUsers")}</option>
          <option value="inactive">{getContent("inactiveUsers")}</option>
        </select>
      </div>
    </div>
  );
};

const filterer = (node: IUser, filter: UserFilter) => {
  if (
    filter?.name &&
    !node.name?.toLowerCase().includes(filter.name.toLowerCase())
  )
    return false;
  if (filter?.role && node.role_id !== filter.role) return false;
  if (filter?.status !== undefined && node.active !== filter.status)
    return false;
  return true;
};

const UsersListPage = () => {
  useUser(true);
  const { data, error, mutate } = useSWR<IUser[]>(
    `${API}/users`,
    (url: string) => fetcher({ url }).then((res) => res.data),
    { dedupingInterval: 0 }
  );

  const getContent = useLocale();

  const { setPopup } = usePopup();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <Table
      data={data.map((user, index) => ({ ...user, index: index + 1 }))}
      renderer={{
        index: {
          name: "#",
          component: (node) => <span>{node.index}</span>,
          sort: (a, b) => a.index - b.index,
        },
        name: {
          name: getContent("name"),
          component: (node) => (
            <span>
              {node.name} {node.family}
            </span>
          ),
          sort: (a, b) =>
            `${a.name} ${a.family}`.localeCompare(`${b.name} ${b.family}`),
        },
        role: {
          name: getContent("role"),
          component: (node) => <span>{node.role.name}</span>,
          sort: (a, b) => a.role.name.localeCompare(b.role.name),
        },
        status: {
          name: getContent("status"),
          component: (node) => <UserStatus user={node} refresh={mutate} />,
          sort: (a, b) => `${a.active}`.localeCompare(`${b.active}`),
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                title={getContent("userDetails")}
                onClick={() => setPopup(<UserOverviewPopup user={node} />)}
                style={{ backgroundColor: "var(--green)" }}
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                title={getContent("editUser")}
                style={{ backgroundColor: "var(--cyan)" }}
                onClick={() =>
                  setPopup(<EditUserPopup refresh={mutate} user={node} />)
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                title={getContent("changePass")}
                style={{ backgroundColor: "var(--blue)" }}
                onClick={() =>
                  setPopup(<UserPassPopup refresh={mutate} user={node} />)
                }
              >
                <CogIcon />
              </IconButton>
              <IconButton
                title={getContent("changeRole")}
                style={{ backgroundColor: "var(--primary)" }}
                onClick={() =>
                  setPopup(<EditUserRolePopup refresh={mutate} user={node} />)
                }
              >
                <DiamondIcon />
              </IconButton>
              <IconButton
                title={getContent("deleteUser")}
                style={{ backgroundColor: "var(--danger)" }}
                onClick={() =>
                  setPopup(<DeleteUserPopup user={node} refresh={mutate} />)
                }
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
      title={getContent("usersList")}
      filter={{ filter: filterer, filterComponent: FilterUsers }}
    />
  );
};
export default UsersListPage;
