import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import PopupCard from "../Popup/PopupCard";
import classes from "./AddNewModule.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import { IModule, IModuleContainer, ISubModule } from "./PermissionList";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import { Fragment, useEffect, useMemo, useState } from "react";
import Button from "../_UI/Button";
import usePopup from "../../hooks/usePopup";
import { useParams } from "react-router-dom";
import useNotification from "../../hooks/useNotification";

const Savor = ({
  id,
  payload,
  onDone,
}: {
  id: number;
  payload: number[];
  onDone: (status: boolean) => void;
}) => {
  const { roleId } = useParams();
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("saving"));
  useSWR(
    {
      url: `${API}/access/storePermission`,
      payload: { module: id, role_id: Number(roleId), sub_modules: payload },
    },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("saveSettingsSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("saveSettingsError"), "Error");
        onDone(false);
      },
    }
  );

  return null;
};

const AddNewModule = ({
  refresh,
  nodes,
}: {
  nodes: IModuleContainer[];
  refresh: Function;
}) => {
  const getContent = useLocale();
  const { data: modules, error } = useSWR<IModule[]>(
    `${API}/modules`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );
  const { closePopup } = usePopup();

  const [selectedModId, setSelectedModId] = useState<string>("");

  const selectedMod = useMemo<IModule | undefined>(
    () => modules?.find((mod) => `${mod.id}` === selectedModId),
    [modules, selectedModId]
  );

  const [selectedSubs, setSelectedSubs] = useState<number[]>([]);

  useEffect(() => {
    setSelectedSubs(
      nodes
        .find((el) => el.module_id === selectedMod?.id)
        ?.items.reduce((acc, el) => [...acc, el.SubModule], [] as ISubModule[])
        .map((el) => el.id) || []
    );
  }, [nodes, selectedMod?.id]);

  const [isSaving, setIsSaving] = useState<number[] | null>(null);

  const onSave = () => {
    setIsSaving([...selectedSubs]);
  };

  const onAfterSave = (status: boolean) => {
    setIsSaving(null);
    if (!status) return;
    refresh();
  };

  if (!modules && error) return <ErrorPage />;
  if (!modules) return <Loading />;
  return (
    <Fragment>
      <PopupCard title={getContent("addModule")}>
        <div className={classes.main}>
          <select onChange={(e) => setSelectedModId(e.target.value)}>
            <option value="">select module</option>
            {modules.map((mod) => (
              <option key={mod.id} value={mod.id}>
                {mod.name}
              </option>
            ))}
          </select>
          {!!selectedMod?.SubModule.length ? (
            <div className={classes.subs}>
              {selectedMod.SubModule.map((sub) => (
                <div
                  className={`${classes.sub} ${
                    selectedSubs.includes(sub.id) ? classes.active : ""
                  }`}
                  key={`${sub.id} ${selectedMod.id}`}
                  onClick={() =>
                    setSelectedSubs((prev) => {
                      const clone = [...prev];
                      if (prev.includes(sub.id)) {
                        clone.splice(prev.indexOf(sub.id), 1);
                      } else {
                        clone.push(sub.id);
                      }
                      return clone;
                    })
                  }
                >
                  <span className={classes.check} />
                  <span>{sub.name}</span>
                  <span>{sub.route}</span>
                </div>
              ))}
            </div>
          ) : (
            <p>این ماژول وب سرویسی ندارد</p>
          )}
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            {!!selectedMod && (
              <Button onClick={onSave}>{getContent("save")}</Button>
            )}
          </div>
        </div>
      </PopupCard>
      {!!selectedMod && !!isSaving && (
        <Savor id={selectedMod.id} payload={isSaving} onDone={onAfterSave} />
      )}
    </Fragment>
  );
};
export default AddNewModule;
