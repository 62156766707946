import useSWR from "swr";
import classes from "./AccessRolePage.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import Table from "../_UI/Table";
import useUser, { IRole } from "../../hooks/useUser";
import useLocale from "../../hooks/useLocale";
import { Fragment, useContext } from "react";
import LocaleContext from "../../store/LocaleContext";
import IconButton from "../_UI/IconButton";
import { CogIcon } from "../Icons/CogIcon";
import usePopup from "../../hooks/usePopup";
import EditRolePopup from "./EditRolePopup";
import { EditIcon } from "../Icons/EditIcon";
import { useNavigate } from "react-router-dom";
const AccessRolePage = () => {
  useUser(true);
  const { data, error, mutate } = useSWR<IRole[]>(
    `${API}/access/roles`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const getContent = useLocale();

  const { locale } = useContext(LocaleContext);

  const { setPopup } = usePopup();

  const navigate = useNavigate();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <Table
      data={data.map((role, index) => ({ ...role, index: index + 1 }))}
      title={getContent("roleManagement")}
      create={{
        title: getContent("createRole"),
        popup: <EditRolePopup refresh={mutate} />,
      }}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        role: {
          name: getContent("role"),
          component: (node) => <span>{node.name}</span>,
        },
        createdAt: {
          name: getContent("createdAt"),
          component: (node) => (
            <span>
              {new Date(node.createdAt).toLocaleString(
                locale === "en" ? "en-US" : "fa-IR"
              )}
            </span>
          ),
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              {node.name !== "admin" && (
                <Fragment>
                  <IconButton
                    style={{ backgroundColor: "var(--cyan)" }}
                    onClick={() =>
                      setPopup(<EditRolePopup role={node} refresh={mutate} />)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    style={{ backgroundColor: "var(--blue)" }}
                    onClick={() => navigate(`/access/${node.id}`)}
                  >
                    <CogIcon />
                  </IconButton>
                </Fragment>
              )}
            </span>
          ),
        },
      }}
    />
  );
};
export default AccessRolePage;
