import { Fragment, useState } from "react";
import RTFEditor from "../_UI/RTFEditor";
import classes from "./PrivacySettingsPage.module.css";
import Button from "../_UI/Button";
import useUser from "../../hooks/useUser";
import useLocale from "../../hooks/useLocale";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";

const ContentSaver = ({
  onDone,
  payload,
}: {
  onDone: () => void;
  payload: string;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("saving"));
  useSWR(
    `${API}/apps/setPrivacy`,
    (url) =>
      fetcher({
        url,
        method: "POST",
        payload: { privacy: payload },
      }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("settingsSaveSuccess"), "Success");
        onDone();
      },
      onError: () => {
        pushNotification(getContent("settingsSaveError"), "Error");
        onDone();
      },
    }
  );
  return null;
};

const PrivacySettingsPage = () => {
  useUser(true);
  const [input, setInput] = useState<string>("");
  const getContent = useLocale();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  return (
    <Fragment>
      <div className={classes.main}>
        <legend className={classes.title}>{getContent("privacy")}</legend>
        <RTFEditor onChange={(val) => setInput(val)} />
        <Button onClick={() => setIsSaving(true)} className={classes.submit}>
          {isSaving ? getContent("saving") : getContent("save")}
        </Button>
      </div>
      {isSaving && (
        <ContentSaver onDone={() => setIsSaving(false)} payload={input} />
      )}
    </Fragment>
  );
};
export default PrivacySettingsPage;
