import classes from "./LoginPage.module.css";
import { Fragment, useState } from "react";
import { API } from "../../config";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { fetcher } from "../../helpers/fetcher";
import { useNavigate } from "react-router-dom";
import Form from "../_UI/Form";
import useUser from "../../hooks/useUser";
import Title from "../_UI/Title";
import useToken from "../../hooks/useToken";

type LoginInput = { email: string; password: string };

const Loginer = ({
  input,
  onDone,
}: {
  input: LoginInput;
  onDone: (status: boolean) => void;
}) => {
  const pushNotification = useNotification("در حال ورود به حساب");
  const { refreshUser } = useUser(undefined);
  const { setToken } = useToken();
  useSWR(
    { url: `${API}/users/signin`, payload: input },
    (args) => fetcher({ ...args, method: "POST" }),
    {
      dedupingInterval: 0,
      onSuccess: (data) => {
        setToken(data.token);
        pushNotification("خوش برگشتید", "Success");
        setTimeout(refreshUser);
        onDone(true);
      },
      onError: () => {
        onDone(false);
        pushNotification("نام کاربری یا رمز عبور اشتباه است", "Error");
      },
    }
  );
  return null;
};

const LoginPage = () => {
  useUser(false);
  const [input, setInput] = useState<LoginInput>({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const [isLogingIn, setIsLogingIn] = useState<LoginInput | null>(null);

  const pushNotification = useNotification();

  const onLogin = async () => {
    if (!input.email.trim().length || !input.password.trim().length)
      return pushNotification(
        "لطفا اطلاعات وارد شده را بررسی فرمایید",
        "Error"
      );
    setIsLogingIn(input);
  };

  const onAfterLogin = (status: boolean) => {
    if (status) navigate("/dashboard");
    setIsLogingIn(null);
  };

  return (
    <Fragment>
      <Form onSubmit={onLogin} className={classes.main}>
        <div className={classes.card}>
          <div className={classes.logo}>
            <img src="/Logo.png" alt="logo" className={classes.image} />
          </div>
          <div className={classes.fields}>
            <Title>ورود به پنل مدیریت</Title>
            <Input
              label="ایمیل یا نام کاربری"
              onChange={(e) =>
                setInput((prev) => ({ ...prev, email: e.target.value }))
              }
            />
            <Input
              label="رمز عبور"
              onChange={(e) =>
                setInput((prev) => ({ ...prev, password: e.target.value }))
              }
              type="password"
            />
            <Button type="submit" className={classes.submit}>
              {!!isLogingIn ? "در حال ورود" : "ورود"}
            </Button>
          </div>
        </div>
      </Form>
      {!!isLogingIn && <Loginer input={isLogingIn} onDone={onAfterLogin} />}
    </Fragment>
  );
};
export default LoginPage;
