import useLocale from "../../hooks/useLocale";
import TabSystem from "../_UI/TabSystem";
import AddClientModule from "./AddClientModule";
import PermissionList from "./PermissionList";
import useUser from "../../hooks/useUser";
const ManageRolePage = () => {
  useUser(true);
  const getContent = useLocale();
  return (
    <TabSystem
      items={[
        {
          name: getContent("permissions"),
          content: <PermissionList />,
          id: "permissions",
        },
        {
          name: getContent("clientModules"),
          content: <AddClientModule />,
          id: "clientModules",
        },
      ]}
    />
  );
};
export default ManageRolePage;
