import useSWR from "swr";
import classes from "./ServerSettingsPage.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useUser from "../../hooks/useUser";
import Input from "../_UI/Input";
import Loading from "../_UI/Loading";
import useLocale from "../../hooks/useLocale";
import { Fragment, useState } from "react";
import Form from "../_UI/Form";
import Button from "../_UI/Button";
import useNotification from "../../hooks/useNotification";

const keys = [
  "wifiOperatorName",
  "mciOperatorName",
  "irancellOperatorName",
  "othersOperatorName",
] as const;

type Key = (typeof keys)[number];

type OperatorConfig = { [key in Key]: { value: string } };

type OperatorInput = { [key in Key]: string };

const SettingsSaver = ({
  onDone,
  payload,
}: {
  onDone: (status: boolean) => void;
  payload: OperatorInput;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("saving"));
  useSWR(
    { url: `${API}/apps/settings/operators`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("settingsSaveSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("settingsSaveError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const SettingsForm = ({
  defaultValue,
  refresh,
}: {
  defaultValue: OperatorConfig;
  refresh: Function;
}) => {
  const [input, setInput] = useState<OperatorInput>(
    Object.keys(defaultValue).reduce(
      (acc, key) => ({
        ...acc,
        [key]: defaultValue[key as Key].value,
      }),
      {} as OperatorInput
    )
  );
  const getContent = useLocale();
  const [isSaving, setIsSaving] = useState<OperatorInput | null>(null);
  const pushNotification = useNotification();

  const onSave = () => {
    for (const key in input) {
      if (!input[key as Key].trim().length)
        return pushNotification(getContent("checkInput"), "Error");
    }
    setIsSaving({ ...input });
  };

  const onAfterSave = (status: boolean) => {
    setIsSaving(null);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <Form className={classes.main} onSubmit={onSave}>
        {keys.map((key) => (
          <Input
            required
            key={key}
            label={getContent(key)}
            defaultValue={defaultValue[key].value}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, [key]: e.target.value }))
            }
          />
        ))}
        <Button style={{ marginInlineStart: "auto" }} type="submit">
          {getContent("save")}
        </Button>
      </Form>
      {!!isSaving && <SettingsSaver payload={isSaving} onDone={onAfterSave} />}
    </Fragment>
  );
};

const ServerSettingsPage = () => {
  useUser(true);
  const { data, mutate } = useSWR<OperatorConfig>(
    `${API}/apps/settings/operators`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  if (!data) return <Loading />;
  return <SettingsForm defaultValue={data} refresh={mutate} />;
};
export default ServerSettingsPage;
