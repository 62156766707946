import useLocale from "../../hooks/useLocale";
import Pair from "../_UI/Pair";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeviceOverviewPopup.module.css";
import { IDevice } from "./ManageDevicesPage";
const DeviceOverviewPopup = ({ device }: { device: IDevice }) => {
  const getContent = useLocale();
  return (
    <PopupCard title={getContent("deviceDetails")}>
      <div className={classes.main}>
        <Pair
          title={getContent("deviceAppVersionCode")}
          value={device.appVersionCode}
        />
        <Pair title={getContent("firebaseToken")} value={device.fcmToken} />
      </div>
    </PopupCard>
  );
};
export default DeviceOverviewPopup;
