const fetchMethods = ["GET", "POST", "PUT", "PATCH", "DELETE"] as const;
type FetchMethod = (typeof fetchMethods)[number];

export class FetchError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

type FetcherArgs = {
  url: string;
  method?: FetchMethod;
  payload?: { [key: string]: unknown };
  headers?: { [key: string]: string };
};

const fetcherInner = async ({
  url,
  method = "GET",
  payload,
  headers: additionalHeaders,
}: FetcherArgs) => {
  const token = localStorage.getItem("token");
  const headers: any = {
    "content-type": "application/json",
    ...(additionalHeaders || {}),
  };
  if (token) headers.Authorization = `JWT ${token}`;
  const response = await fetch(url, {
    headers,
    method,
    body: payload ? JSON.stringify(payload) : undefined,
  });
  if (!response.ok)
    throw new FetchError("خطا در ارتباط با سرور", response.status);
  if (!response.headers.get("content-type")?.includes("json")) return true;
  const data = await response.json();
  return data;
};

export const fetcher = ({
  url,
  method = "GET",
  payload,
  headers,
}: FetcherArgs) =>
  fetcherInner({ url, method, payload, headers }).then((res) => res);
