export const SortIcon = ({ fill }: { fill: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill ? "currentColor" : "none"}
        stroke={fill ? "none" : "currentColor"}
        d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19"
      ></path>
    </svg>
  );
};
