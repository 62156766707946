import { LocaleMap } from "../useLocale";

export const fa: LocaleMap = {
  username: "نام کاربری",
  password: "رمز عبور",
  dashboard: "داشبورد",
  profile: "پروفایل",
  signout: "خروج",
  farsi: "فارسی",
  english: "انگلیسی",
  welcome: "خوش آمدید",
  dear: "عزیز",
  today: "امروز",
  now: "اکنون",
  signoutPopupTitle: "خروج از حساب کاربری",
  sureSignout: "بله، خارج شو",
  areYouSureSignout: "آیا مطمئنید که میخواهید از حساب خارج شوید؟",
  no: "خیر",
  signingOut: "در حال خروج",
  SignoutError: "مشکلی هنگام خروج از حساب پیش آمد",
  SignoutSuccess: "خدانگهدار",
  ServerDetails: "جزئیات سرور",
  ServerProfiles: "کانفیگ های سرور",
  EditServer: "اصلاح سرور",
  DeleteServer: "حذف سرور",
  country: "کشور",
  provider: "پروایدر",
  close: "بستن",
  actions: "عملیات",
  application: "اپلیکیشن",
  location: "موقعیت",
  serverName: "نام سرور",
  serversList: "لیست سرور ها",
  createServer: "ثبت سرور جدید",
  creatingServer: "در حال ساخت سرور جدید",
  createServerError: "خطا در ساخت سرور",
  createServerSuccess: "سرور با موفقیت ساخته شد",
  checkInput: "لطفا اطلاعات وارد شده را بررسی فرمایید",
  loading: "در حال بارگزاری",
  noOption: "هیچ اطلاعاتی یافت نشد",
  cancel: "انصراف",
  areYouSureDeleteServer: "آیا از حذف این سرور مطمئنید؟",
  sureDeleteServer: "بله، سرور رو حذف کن",
  deleteServerError: "عملیات حذف سزور با خطا مواجه شد",
  deleteServerSuccess: "سرور با موفقیت حذف شد",
  deletingServer: "در حال حذف سرور",
  itemsPerPage: "تعداد رکورد در هر صفحه",
  search: "جستجو",
  clear: "پاک کن",
  updatingServer: "در حال اصلاح سرور",
  updateServerSuccess: "سرور با موفقیت آپدیت شد",
  updateServerError: "خطایی در آپدیت سرور رخ داد",
  profileList: "لیست کانفیگ ها",
  batchProfileImport: "ورود کانفیگ گروهی",
  newProfile: "ثبت کانفیگ جدید",
  name: "نام",
  operator: "اپراتور",
  protocol: "پروتکل",
  batchImportGuide: "راهنمای ورود گروهی",
  SelectFile: "انتخاب فایل",
  downloadSampleBatchFile: "دانلود فایل نمونه ورود گروهی",
  upload: "آپلود",
  selectFileFirst: "لطفا ابتدا یک فایل انتخاب کنید",
  uploading: "در حال آپلود",
  uploadError: "خطایی هنگام آپلود رخ داد",
  uploadSuccess: "فایل با موفقیت آپلود شد",
  address: "آدرس",
  profileType: "تیپ",
  createNewProfile: "ساخت کانفیگ جدید",
  creatingProfile: "در حال ساخت کانفیگ",
  createProfileError: "خطایی در هنگام ساخت کانفیگ به وجود آمد",
  createProfileSuccess: "ساخت کانفیگ با موفقیت انجام شد",
  editProfile: "اصلاح کانفیگ",
  editingProfile: "در حال اصلاح کانفیگ",
  editProfileError: "اصلاح کانفیگ با خطا مواجه شد",
  editProfileSuccess: "اصلاح کانفیگ با موفقیت به اتمام رسید",
  profileDetails: "جزئیات کانفیگ",
  revealAddress: "برای مشاهده آدرس کلیک کنید",
  revealing: "در حال نمایش",
  deleteProfile: "حذف کانفیگ",
  deletingProfile: "در حال حذف کانفیگ",
  deleteProfileError: "خطایی هنگام حذف کانفیگ رخ داد",
  deleteProfileSuccess: "کانفیگ با موفقیت حذف شد",
  sureDeleteProfile: "آیا از حذف این کانفیگ مطمئنید؟",
  noContent: "هیچ داده ای مطابق با شرایط شما یافت نشد",
  irancellOperatorName: "اپراتور ایرانسل",
  mciOperatorName: "اپراتور همراه اول",
  othersOperatorName: "دیگر اپراتورها",
  wifiOperatorName: "اپراتور وای فای",
  save: "ذخیره",
  saving: "در حال ذخیره",
  settingsSaveError: "خطایی هنگام ذخیره تنظیمات رخ داد",
  settingsSaveSuccess: "تنظیمات با موفقیت ذخیره شد",
  privacy: "سیاست حریم خصوصی",
  encInput: "عبارتی که میخواهد رمز گذاری شود",
  encrypt: "رمز گذاری",
  encrypting: "در حال رمز گذاری",
  encryptionSuccess: "عملیات رمز کذاری با موئفقیت خاتمه یافت",
  encyptionError: "خطایی هنگام رمز گذاری رخ داد",
  toturialText: "متن آموزش",
  toturial: "آموزش",
  adminToturial: "آموزش ادمین",
  adminTutorialText: "متن آموزش ادمین",
  apiTitle: "آموزش API",
  apiDescription: "توضیحات API",
  joinedAt: "ثبت نام",
  passwordNotStrong:
    "رمز عبور باید از حداقل هشت کاراکتر شامل حروف کوچک و بزرگ و اعداد و کاراکتر های ویژه باشد",
  passwordsnotMatch: "رمز جدید با تاییدش یکسان نیست",
  confirmPassword: "تایید رمز عبور",
  currentPassword: "رمز عبور فعلی",
  newPassword: "رمز عبور جدید",
  changepassword: "تغییر رمز عبور",
  changePasswordError: "خطایی هنگام تغییر رمز عبور رخ داد",
  changePasswordSuccess: "رمز عبور با موفقیت تغییر کرد",
  changingPassword: "در حال تغییر رمز عبور",
  allApplications: "همه اپلیکیشن ها",
  myApplications: "اپلیکیشن های من",
  createApplication: "اپلیکیشن جدید",
  packageName: "نام پکیج",
  active: "فعال",
  appAdmin: "ادمین اپلیکیشن",
  inactive: "غیر فعال",
  status: "وضعیت",
  toggleStatusError: "خطایی هنگام تغییر وضعیت رخ داد",
  toggleStatusSuccess: "وضعیت با موفقیت تغییر کرد",
  togglingStatus: "در حال تغییر وضعیت",
  appDetails: "جزئیات اپلیکیشن",
  sign: "ساین",
  signcpp: "ساین cpp",
  firebaseConf: "کد های تنظیمات فایربیس",
  creatingApp: "در حال ساخت اپلیکیشن",
  createAppError: "خطا در ساخت اپلیکیشن رخ داده",
  createAppSuccess: "اپلیکیشن با موفقیت ساخته شد",
  admobSettings: "تنظیمات ادموب",
  applicationSettings: "تنظیمات اپلیکیشن",
  blockList: "بلاک لیست",
  aboutLink: "لینک درباره ما",
  activeBlockVersion:
    "اگر میخواهید ورژن های قدیمی را از کار بندازید این تیک را بزنید",
  activeEncryptSetting: "تنظیمات را به صورت کد شده به اپلیکیشن ارسال کن",
  admobText:
    "در صورت خاموش بودن گزینه نمایش تبلیغات ادموب هیچ تبلیغاتی از گوگل در اپلیکیشن نمایش داده نمی شود.",
  adsForceByVpn: "فورس VPN به صورت کلی",
  adsForceByVpnInForeign: "فورس VPN برای نمایش تبلیغ در خارج",
  adsForceByVpnInIran: "فورس VPN برای نمایش تبلیغ در ایران",
  appVersion: "ورژن اپلیکیشن",
  configsActive: "سختگیری امنیت",
  connectDelay: "کانکت دیلی(ثانیه)",
  detectLocationBy: "تشخیص لوکیشن با",
  disconnectDelay: "دیس کانکت دیلی(ثانیه)",
  email: "ایمیل",
  englishUpdateText: "متن آپدیت انگلیسی",
  forceUpdate: "فورس آپدیت",
  googlePlayLink: "لینک انتشار",
  instagram: "اینستاگرام",
  policyLink: "لینک حریم شخصی",
  telegram: "تلگرام",
  updateText: "متن آپدیت",
  userConnectionLimit: "محدودیت اتصال کاربر",
  youtube: "یوتیوب",
  updateApplication: "بروزرسانی اپلیکیشن",
  autoConnect: "اتصال خودکار",
  hour: "ساعت",
  minute: "دقیقه",
  saveSettingsError: "خطایی هنگام ذخیره تنظیمات رخ داد",
  saveSettingsSuccess: "تنظیمات با موفقیت دخیره شد",
  savingSettings: "در حال ذخیره تنظیمات",
  allVersions: "همه ورژن ها",
  blockedVersions: "لیست ورژن های بلاک شده",
  blockNewVersion: "بلاک ورژن جدید",
  block: "بلاک کن",
  blockError: "خطایی هنگام بلاک کردن رخ داد",
  blocking: "در حال بلاک",
  blockSuccess: "ورژن با موفقیت بلاک شد",
  selectVersionToBlock: "نسخه ای که میخواهید بلاک کنید را انتخاب کنید",
  version: "ورژن",
  delete: "حذف",
  deleteError: "خطایی هنگام حذف رخ داد",
  deleteSuccess: "حذف با موفقیت انجام شد",
  deleting: "در حال حذف",
  areYouSureDelete: "آیا از حذف این آیتم مطمئنید؟",
  deviceList: "لیست دستگاه ها",
  deviceBrand: "برند دستگاه",
  deviceId: "شناسه یونیک دستگاه",
  createDevice: "ثبت دستگاه جدید",
  deleteDevice: "حذف دستگاه",
  deviceAppVersionCode: "ورژن کد اپلیکیشن",
  deviceDetails: "جزئیات دستگاه",
  editDevice: "اصلاح دستگاه",
  firebaseToken: "توکن فایربیس",
  update: "به روز رسانی",
  createDeviceError: "خطایی هنگام ساخت دستگاه رخ داد",
  createDeviceSuccess: "دستگاه با موفقیت ساخته شد",
  creatingDevice: "در حال ساخت دستگاه",
  usersList: "لیست کاربران",
  role: "نقش",
  changePass: "تغییر پسوورد",
  changeRole: "تغییر نقش",
  deleteUser: "حذف کاربر",
  editUser: "اصلاح کاربر",
  userDetails: "جزئیات کاربر",
  family: "نام خانوادگی",
  about: "درباره",
  eFamily: "نام خانوادگی انگلیسی",
  eName: "نام انگلیسی",
  mobile: "موبایل",
  website: "وبسایت",
  editingUser: "در حال اصلاح کاربر",
  editUserError: "خطایی هنگام اصلاح کاربر رخ داد",
  editUserSuccess: "کاربر با موفقیت اصلاح شد",
  repassword: "تایید رمز عبور",
  createUser: "ایجاد کاربر",
  creatingUser: "در حال ایجاد کاربر",
  gender: "جنسیت",
  female: "زن",
  male: "مرد",
  createUserError: "خطایی هنگام ایجاد کاربر رخ داد",
  createUserSuccess: "کاربر با موفقیت ایجاد شد",
  changingRole: "در حال تغییر نقش",
  roleChangeError: "خطایی هنگام تغییر نقش رخ داد",
  roleChangeSuccess: "تغییر نقش با موفقیت انجام شد",
  menus: "منو ها",
  persianName: "نام فارسی",
  englishName: "نام انگلیسی",
  menuRoute: "مسیر منو",
  createMenu: "ساخت منو جدید",
  editMenu: "اصلاح منو",
  creatingMenu: "در حال ساخت منو",
  editingMenu: "دز حال اصلاح منو",
  createMenuError: "خطایی هنگام ایجاد منو رخ داد",
  createMenuSuccess: "منو با موفقیت ساخته شد",
  editMenuError: "خطایی هنگام اصلاح منو رخ داد",
  editMenuSuccess: "منو با موفقیت اصلاح شد",
  selectParentMenu: "انتخاب منو والد",
  deleteMenu: "حذف منو",
  roleManagement: "مدیریت نقش ها",
  createRole: "ساخت نقش جدید",
  createRoleError: "ساخت نقش با خطا مواجه شد",
  createRoleSuccess: "ساخت نقش با موفقیت انجام شد",
  creatingRole: "در حال ساخت نقش",
  editingRole: "در حال اصلاح نقش",
  editRole: "اصلاح نقش",
  editRoleError: "اصلاح نقش با خطا مواجه شد",
  editRoleSuccess: "اصلاح نقش با موفقیت انجام شد",
  description: "توضیحات",
  createdAt: "تاریخ ایجاد",
  addModule: "اضافه کردن مجوز جدید",
  apis: "وب سرویس ها",
  clientModules: "مجوز های کلاینت",
  module: "ماژول",
  permissionManagement: "مدیریت مجوز ها",
  permissions: "مجوز ها",
  method: "متد",
  route: "مسیر",
  copyError: "مشکلی در کپی کردن متن به وجود آمد",
  copySuccess: "متن با موفقیت کپی شد",
  activeApplications: "اپلیکیشن های فعال",
  inactiveApplications: "اپلیکیشن های غیر فعال",
  editAppError: "خطایی هنگام اصلاح اپلیکیشن رخ داد",
  editApplication: "اصلاح اپلیکیشن",
  editAppSuccess: "اصلاح اپلیکیشن با موفقیت انجام شد",
  editingApp: "در حال اصلاح اپلیکیشن",
  deleteApplication: "حذف اپلیکیشن",
  activeUsers: "کاربران فعال",
  allUsers: "همه کاربران",
  inactiveUsers: "کاربران غیر فعال",
  proTip: "متنی که میخواهیم رمزگذاری شود : https://www.example.com",
  anErrorOccured: "خطایی پیش آمده",
  reload: "امتحان دوباره",
  AreYouSure: "از انجام این عملیات مطمئنید؟",
  yes: "بله",
  onlyEnglish: "فقط اعداد، حروف انگلیسی و کاراکتر های ویژه مجاز هستند",
  checkDownload: "دانلود های خود را چک کنید",
  emptyTable: "جدول خالی میباشد",
  exporting: "در حال گرفتن خروجی",
  export: "گرفتن خروجی",
};
