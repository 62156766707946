import { LocaleMap } from "../useLocale";

export const en: LocaleMap = {
  username: "User Name",
  password: "Password",
  dashboard: "Dashboard",
  profile: "Profile",
  signout: "Signout",
  farsi: "Farsi",
  english: "English",
  welcome: "Welcome",
  dear: "dear",
  today: "Today",
  now: "now",
  signoutPopupTitle: "Signing out",
  areYouSureSignout: "Are you sure you want to leave?",
  no: "No",
  sureSignout: "Yes, Signout",
  signingOut: "Signing out",
  SignoutError: "An error occured while attempting to sign out",
  SignoutSuccess: "bye",
  ServerDetails: "Server Details",
  ServerProfiles: "Server Configs",
  EditServer: "Edit Server",
  DeleteServer: "Delete Server",
  country: "Country",
  provider: "Provider",
  close: "Close",
  application: "Application",
  location: "Location",
  serversList: "Servers List",
  serverName: "Server Name",
  actions: "Actions",
  createServer: "Create Server",
  creatingServer: "Creating Server",
  createServerError: "An Error occured while create server",
  createServerSuccess: "Server created successfuly",
  checkInput: "Please check entered data",
  noOption: "No Option Found",
  loading: "Loading",
  cancel: "Cancel",
  areYouSureDeleteServer: "Are you sure you want to delete this server?",
  sureDeleteServer: "Yes, delete server",
  deletingServer: "Deleting Server",
  deleteServerError: "An error occured while deleting server",
  deleteServerSuccess: "Server deleted successfuly",
  itemsPerPage: "Items per page",
  search: "Search",
  clear: "Clear",
  updatingServer: "Updating Server",
  updateServerError: "An error occured during server update",
  updateServerSuccess: "Server Updated Successfuly",
  profileList: "Config List",
  batchProfileImport: "Batch Config Import",
  newProfile: "New Profile",
  name: "Name",
  operator: "Operator",
  protocol: "Protocol",
  batchImportGuide: "Batch Import Guide",
  downloadSampleBatchFile: "Download Sample Batch File",
  SelectFile: "Select File",
  upload: "Upload",
  selectFileFirst: "Please Select A File First",
  uploading: "Uploading",
  uploadError: "An error occured during File Upload",
  uploadSuccess: "File Uploaded Successfuly",
  address: "Address",
  profileType: "type",
  createNewProfile: "Create New Config",
  creatingProfile: "Creating Config",
  createProfileError: "An error occurede while creating Config",
  createProfileSuccess: "Config Created Successfuly",
  editProfile: "Edit Config",
  editingProfile: "Editing Config",
  editProfileError: "An error occured while editing config",
  editProfileSuccess: "Config edited successfuly",
  profileDetails: "Config details",
  revealAddress: "Click To Reveal Address",
  revealing: "Revealing",
  deleteProfile: "Delete Config",
  deletingProfile: "Deleting Config",
  deleteProfileSuccess: "Config Deleted Successfuly",
  deleteProfileError: "An error occured while deleting config",
  sureDeleteProfile: "Are you sure you want to delete this Config?",
  noContent: "Nothing matches your criteria",
  irancellOperatorName: "irancell Operator Name",
  mciOperatorName: "mci Operator Name",
  othersOperatorName: "others Operator Name",
  wifiOperatorName: "wifi Operator Name",
  save: "Save",
  saving: "Saving",
  settingsSaveError: "An error occured while saving settings",
  settingsSaveSuccess: "Settings Saved Successfuly",
  privacy: "Privacy",
  encInput: "Encryption Phrase",
  encrypt: "Encrypt",
  encrypting: "Encrypting",
  encryptionSuccess: "Encrypotion was successful",
  encyptionError: "An error occured while encrypting",
  toturial: "Toturial",
  toturialText: "ToturialText",
  adminToturial: "Admin Toturial",
  adminTutorialText: "Admin Tutorial Text",
  apiDescription: "API Description",
  apiTitle: "API Tutorial",
  joinedAt: "Joined At",
  passwordNotStrong:
    "Password must be at least 8 characters long including all casings, digits and special characters",
  passwordsnotMatch: "Provided Passwords do not match",
  confirmPassword: "Confirm Password",
  currentPassword: "Current Password",
  newPassword: "New Password",
  changepassword: "Change Password",
  changePasswordError: "An error occured while changing Password",
  changePasswordSuccess: "Password changed successfuly",
  changingPassword: "Changing Password",
  allApplications: "All Applications",
  myApplications: "My Applications",
  createApplication: "Create Application",
  packageName: "Package Name",
  active: "Active",
  inactive: "Inactive",
  appAdmin: "Application Admin",
  status: "Status",
  togglingStatus: "Changing Status",
  toggleStatusError: "An error Occured while Changing status",
  toggleStatusSuccess: "Status Changed Successfuly",
  appDetails: "Application Details",
  sign: "Sign",
  signcpp: "sign cpp",
  firebaseConf: "Firebase Config",
  creatingApp: "Creating Application",
  createAppError: "An error occured while creating application",
  createAppSuccess: "Application created successfuly",
  admobSettings: "Admob Settings",
  applicationSettings: "Application Settings",
  blockList: "Block List",
  telegram: "Telegram",
  aboutLink: "About Link",
  activeBlockVersion: "Disable Old Versions",
  activeEncryptSetting: "Encryption",
  admobText: "To Prevent Ads disable Show Admob Ads",
  adsForceByVpn: "Force VPN Ads Every where",
  adsForceByVpnInForeign: "Force VPN Ads in Foreign",
  adsForceByVpnInIran: "Force VPN Ads in Iran",
  appVersion: "Application Version",
  configsActive: "Configs Apply",
  connectDelay: "Connect Delay(seconds)",
  detectLocationBy: "Location Detection Method",
  disconnectDelay: "Disconnect Delay",
  email: "Email",
  englishUpdateText: "English Update Text",
  forceUpdate: "Force Update",
  googlePlayLink: "Publish Link",
  instagram: "Instagram",
  policyLink: "Policy Link",
  updateText: "Update Text",
  userConnectionLimit: "User Connection Limit",
  youtube: "Youtube",
  updateApplication: "Update Application",
  autoConnect: "Auto Connect",
  hour: "hour",
  minute: "minute",
  saveSettingsError: "An error occured while Saving settings",
  saveSettingsSuccess: "Settings saved Successfuly",
  savingSettings: "Saving Settings",
  allVersions: "All Versions",
  blockedVersions: "Blocked Versions",
  blockNewVersion: "Block A Version",
  block: "Block",
  blockError: "An error occured while blocking a version",
  blocking: "Blocking",
  blockSuccess: "Selected Version Blocked Successfuly",
  selectVersionToBlock: "Select a version to block",
  version: "Version",
  delete: "Delete",
  deleteError: "An error occured while deleting",
  deleteSuccess: "Data deleted successfuly",
  deleting: "Deleting",
  areYouSureDelete: "Are You Sure You want to delete this resource?",
  deviceList: "Device List",
  deviceBrand: "Device Brand",
  deviceId: "Device ID",
  createDevice: "Create Device",
  deleteDevice: "Delete Device",
  deviceAppVersionCode: "Application Version Code",
  deviceDetails: "Device Details",
  editDevice: "Edit Device",
  firebaseToken: "Firebase Token",
  update: "Update",
  createDeviceError: "An error occured while creating device",
  createDeviceSuccess: "Device created successfuly",
  creatingDevice: "Creating Device",
  usersList: "Users List",
  role: "Role",
  changePass: "Change Password",
  changeRole: "Change Role",
  deleteUser: "Delete User",
  editUser: "Edit User",
  userDetails: "User Details",
  family: "Last Name",
  about: "About",
  eFamily: "English Last Name",
  eName: "English Name",
  mobile: "Mobile",
  website: "Website",
  editingUser: "Editing User",
  editUserError: "An error occured while Editing User",
  editUserSuccess: "User updated Successfuly",
  repassword: "Confirm Password",
  createUser: "Create User",
  creatingUser: "Creating User",
  gender: "Gender",
  female: "Female",
  male: "Male",
  createUserError: "An error occured while creating user",
  createUserSuccess: "User created Successfuly",
  changingRole: "Changing Role",
  roleChangeError: "An error occured while changing role",
  roleChangeSuccess: "Role Changed Successfuly",
  englishName: "English Name",
  menus: "Menus",
  persianName: "Persian Name",
  menuRoute: "Menu Route",
  createMenu: "Create Menu",
  editMenu: "Edit Menu",
  creatingMenu: "Creating Menu",
  editingMenu: "Editing Menu",
  createMenuError: "An error occured while creating menu",
  createMenuSuccess: "Menu Created Successfuly",
  editMenuError: "An error occured while editing menu",
  editMenuSuccess: "Menu edited successfuly",
  selectParentMenu: "Perent Menu",
  deleteMenu: "Delete Menu",
  roleManagement: "Role Management",
  createRole: "Create Role",
  createRoleError: "An error occured while creating role",
  createRoleSuccess: "Role created successfuly",
  creatingRole: "Creating Role",
  editingRole: "Editing Role",
  editRole: "Edit Role",
  editRoleError: "An Error occured while editing Role",
  editRoleSuccess: "Role Created Successfuly",
  description: "Description",
  createdAt: "Created At",
  addModule: "Add Module",
  apis: "Apis",
  clientModules: "Client Modules",
  module: "Module",
  permissionManagement: "Permission Management",
  permissions: "Permissions",
  method: "Method",
  route: "Route",
  copyError: "An error occured while attempting to copy text",
  copySuccess: "Text copied successfuly",
  activeApplications: "Active Applications",
  inactiveApplications: "Inactive Applications",
  editAppError: "An error occured while editing apllication",
  editApplication: "Edit Application",
  editAppSuccess: "Application Edited Successfuly",
  editingApp: "Editing Application",
  deleteApplication: "Delete Application",
  activeUsers: "Active Users",
  allUsers: "All Users",
  inactiveUsers: "Inactive Users",
  proTip: "Text to encryt: https://www.example.com",
  anErrorOccured: "An Error Occured",
  reload: "Reload",
  AreYouSure: "Are you sure?",
  yes: "Yes",
  onlyEnglish:
    "Only English Charachters digits and special charachters are allowed",
  checkDownload: "Check Your Downloads",
  emptyTable: "Table is empty",
  exporting: "Exporting",
  export: "export",
};
