import useSWR from "swr";
import useUser from "../../hooks/useUser";
import { API } from "../../config";
import ServerList from "./ServerList";
import { fetcher } from "../../helpers/fetcher";
const ManageMyServersPage = () => {
  useUser(true);
  const { data, mutate } = useSWR(
    `${API}/apps/servers?state=appAdmin`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );
  return <ServerList data={data} mutate={mutate} batch />;
};
export default ManageMyServersPage;
