import useLocale from "../../hooks/useLocale";
import IconButton from "../_UI/IconButton";
import Table from "../_UI/Table";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import { IVersion } from "./AppBlockList";
import classes from "./VersionList.module.css";
import usePopup from "../../hooks/usePopup";
import DeleteVersionpopup from "./DeleteVersionPopup";

const VersionList = ({
  versions,
  title,
  refresh,
}: {
  versions: IVersion[];
  title: string;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const { setPopup } = usePopup();

  return (
    <Table
      title={title}
      data={versions.map((version, index) => ({
        ...version,
        index: index + 1,
      }))}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        name: {
          name: getContent("application"),
          component: (node) => <span>{node.app.name}</span>,
        },
        version: {
          name: getContent("version"),
          component: (node) => <span>{node.appVersion}</span>,
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                style={{ backgroundColor: "var(--danger)" }}
                onClick={() =>
                  setPopup(
                    <DeleteVersionpopup refresh={refresh} version={node} />
                  )
                }
                title={getContent("delete")}
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
    />
  );
};
export default VersionList;
