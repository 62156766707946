import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useLocale from "../../hooks/useLocale";
import Form from "../_UI/Form";
import Input from "../_UI/Input";
import Toggle from "../_UI/Toggle";
import { IApp } from "../Servers/ManageAllServersPage";
import classes from "./AdmobSettingsForm.module.css";
import useUser from "../../hooks/useUser";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Loading from "../_UI/Loading";
import ErrorPage from "../Error/ErrorPage";
import Button from "../_UI/Button";

type AdmobSettingsInput = {
  IDApp: string;
  intCon: string | null;
  intConInterval: number;
  intConActive: boolean;
  rewIntCon: string | null;
  rewIntConInterval: number;
  rewIntConActive: boolean;
  intDis: string | null;
  intDisInterval: number;
  intDisActive: boolean;
  rewIntDis: string | null;
  rewIntDisInterval: number;
  rewIntDisActive: boolean;
  rew: string | null;
  rewInterval: number;
  rewActive: boolean;
  rew2: string | null;
  rew2Interval: number;
  rew2Active: boolean;
  appOpen: string | null;
  appOpenInterval: number;
  appOpenActive: boolean;
  gdprActive: boolean;
  rewIntServerlist?: string | null;
  rewIntServerlistActive?: boolean;
  rewIntServerlistInterval?: number;
  //smart
  smartIntCon: string | null;
  smartIntConInterval: number;
  smartIntConActive: boolean;
  smartIntDis: string | null;
  smartIntDisInterval: number;
  smartIntDisActive: boolean;
  smartActive: boolean;
};

const AdmobSettingsSaver = ({
  onDone,
  payload,
  admob,
  id,
}: {
  onDone: (status: boolean) => void;
  payload: AdmobSettingsInput;
  admob: boolean;
  id: string;
}) => {
  const [one, setOne] = useState<boolean>(false);
  const [two, setTwo] = useState<boolean>(false);
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("saving"));
  useSWR(
    { url: `${API}/apps/showAds/${id}`, admob },
    ({ url, admob }) =>
      fetcher({
        url,
        method: "POST",
        payload: { key: "adMob", state: admob },
      }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        setOne(true);
      },
      onError: () => {
        pushNotification(getContent("saveSettingsError"), "Error");
        onDone(false);
      },
    }
  );
  useSWR(
    { url: `${API}/apps/updateSettingsAdmob/${id}`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        setTwo(true);
      },
      onError: () => {
        pushNotification(getContent("saveSettingsError"), "Error");
        onDone(false);
      },
    }
  );

  useEffect(() => {
    if (one && two) {
      pushNotification(getContent("saveSettingsSuccess"), "Success");
      onDone(true);
    }
  }, [getContent, onDone, one, pushNotification, two]);
  return null;
};

const Triple = ({
  label,
  app,
  str,
  num,
  bool,
  input,
  setInput,
  onToggle,
}: {
  label: string;
  app: IApp;
  str?: keyof AdmobSettingsInput;
  num?: keyof AdmobSettingsInput;
  bool?: keyof AdmobSettingsInput;
  input: AdmobSettingsInput;
  setInput: Dispatch<SetStateAction<AdmobSettingsInput>>;
  onToggle?: () => void;
}) => {
  const { user } = useUser(true);
  const readonly = useMemo<boolean>(
    () => user?.role.name !== "admin",
    [user?.role.name]
  );

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: keyof AdmobSettingsInput
  ) => setInput((prev) => ({ ...prev, [name]: e.target.value }));

  return (
    <div className={classes.triple}>
      {!!str && (
        <Input
          readonly={readonly}
          defaultValue={app[str]?.toString() || ""}
          label={label}
          onChange={(e) => onChange(e, str)}
          className={classes.string}
        />
      )}
      <div className={classes.tail}>
        {!!num && (
          <Input
            readonly={readonly}
            defaultValue={`${app[num]}`}
            className={classes.number}
            type="number"
            label="Interval(Sec)"
            id={num}
            step={1}
            min={0}
            onChange={(e) => onChange(e, num)}
          />
        )}
        {!!bool && <Toggle active={!!input[bool]} onClick={onToggle} />}
      </div>
    </div>
  );
};

const AdmobSettingsForm = ({
  app,
  refresh,
}: {
  app: IApp;
  refresh: Function;
}) => {
  const [admobActive, setAdmobActive] = useState<boolean>(false);

  const { data: ready, error } = useSWR<boolean>(
    `${API}/apps/adsSettings/${app.id}`,
    (url: string) =>
      fetcher({ url }).then((res) => {
        return !!res.data.find(
          (el: { key: string; value: boolean }) => el.key === "adMob"
        )?.value;
      }),
    {
      dedupingInterval: 0,
      onSuccess: (data) => {
        setAdmobActive(data);
      },
    }
  );
  const { user } = useUser(true);

  const [input, setInput] = useState<AdmobSettingsInput>({
    appOpen: app.appOpen,
    appOpenActive: app.appOpenActive,
    appOpenInterval: app.appOpenInterval,
    IDApp: app.IDApp,
    intCon: app.intCon,
    intConActive: app.intConActive,
    intConInterval: app.intConInterval,
    intDis: app.intDis,
    intDisActive: app.intDisActive,
    intDisInterval: app.intDisInterval,
    rewIntCon: app.rewIntCon,
    rewIntConActive: app.rewIntConActive,
    rewIntConInterval: app.rewIntConInterval,
    rewIntDis: app.rewIntDis,
    rewIntDisActive: app.rewIntDisActive,
    rewIntDisInterval: app.rewIntDisInterval,
    rew: app.rew,
    rewActive: app.rewActive,
    rewInterval: app.rewInterval,
    rew2: app.rew2,
    rew2Interval: app.rew2Interval,
    rew2Active: app.rew2Active,
    smartIntConInterval: app.smartIntConInterval,
    smartIntConActive: app.smartIntConActive,
    smartIntDisInterval: app.smartIntDisInterval,
    smartIntDisActive: app.smartIntDisActive,
    gdprActive: app.gdprActive,
    smartActive: app.smartActive,
    smartIntCon: app.smartIntCon,
    smartIntDis: app.smartIntDis,
    rewIntServerlist: app.rewIntServerlist,
    rewIntServerlistActive: app.rewIntServerlistActive,
    rewIntServerlistInterval: app.rewIntServerlistInterval,
  });

  const [isSaving, setIsSaving] = useState<AdmobSettingsInput | null>(null);

  const pushNotification = useNotification();

  const readonly = useMemo<boolean>(
    () => user?.role.name !== "admin",
    [user?.role.name]
  );

  const onSave = () => {
    setIsSaving({ ...input, gdprActive: admobActive });
  };

  const onAfterSave = useCallback(
    (status: boolean) => {
      setIsSaving(null);
      if (!status) return;
      refresh();
    },
    [refresh]
  );

  const isUserDumb = useMemo<boolean>(
    () => user?.role_id === 5,
    [user?.role_id]
  );

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    name: keyof AdmobSettingsInput
  ) => setInput((prev) => ({ ...prev, [name]: e.target.value }));
  const getContent = useLocale();

  if (ready === undefined && error) return <ErrorPage />;
  if (ready === undefined) return <Loading />;
  return (
    <Fragment>
      <Form className={classes.main} onSubmit={onSave}>
        <p className={classes.text}>{getContent("admobText")}</p>
        <div className={classes.toggle}>
          <Toggle
            active={admobActive}
            onClick={() => {
              if (admobActive) {
                setInput((prev) => ({
                  ...prev,
                  rewActive: false,
                  intConActive: false,
                  intDisActive: false,
                  appOpenActive: false,
                  rewIntConActive: false,
                  rewIntDisActive: false,
                }));
              }
              setAdmobActive((prev) => !prev);
            }}
          />
          <legend>نمایش تبلیغات ادموب</legend>
        </div>
        <Input
          defaultValue={app.IDApp}
          label="Application ID"
          style={{ direction: "ltr" }}
          readonly={readonly}
          onChange={(e) => onChange(e, "IDApp")}
        />
        <Triple
          app={app}
          input={input}
          setInput={setInput}
          str="intCon"
          num="intConInterval"
          bool="intConActive"
          label="Interstitial Connect"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            if (input.rewIntConActive && !input.intConActive) {
              return pushNotification(
                "intCon و rewIntCon نمیتوانند با هم فعال شوند",
                "Error"
              );
            }
            setInput((prev) => ({ ...prev, intConActive: !prev.intConActive }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Rewarded Interstitial Connect"
          setInput={setInput}
          bool="rewIntConActive"
          str="rewIntCon"
          num="rewIntConInterval"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            if (input.intConActive && !input.rewIntConActive) {
              return pushNotification(
                "intCon و rewIntCon نمیتوانند با هم فعال شوند",
                "Error"
              );
            }
            setInput((prev) => ({
              ...prev,
              rewIntConActive: !prev.rewIntConActive,
            }));
          }}
        />
        <Triple
          app={app}
          input={input}
          setInput={setInput}
          label="Interstitial Disconnect"
          bool="intDisActive"
          num="intDisInterval"
          str="intDis"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            if (input.rewIntDisActive && !input.intDisActive) {
              return pushNotification(
                "rewIntDis و intDis نمیتوانند با هم فعال شوند",
                "Error"
              );
            }
            setInput((prev) => ({ ...prev, intDisActive: !prev.intDisActive }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Rewarded Interstitial Disconnect"
          setInput={setInput}
          bool="rewIntDisActive"
          num="rewIntDisInterval"
          str="rewIntDis"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            if (input.intDisActive && !input.rewIntDisActive) {
              return pushNotification(
                "rewIntDis و intDis نمیتوانند با هم فعال شوند",
                "Error"
              );
            }
            setInput((prev) => ({
              ...prev,
              rewIntDisActive: !prev.rewIntDisActive,
            }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Rewarded Interstitial"
          setInput={setInput}
          bool="rewActive"
          str="rew"
          num="rewInterval"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            setInput((prev) => ({ ...prev, rewActive: !prev.rewActive }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Rewarded"
          setInput={setInput}
          bool="rew2Active"
          num="rew2Interval"
          str="rew2"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            setInput((prev) => ({ ...prev, rew2Active: !prev.rew2Active }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Rewarded Interstitial Server List"
          setInput={setInput}
          bool="rewIntServerlistActive"
          num="rewIntServerlistInterval"
          str="rewIntServerlist"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            setInput((prev) => ({
              ...prev,
              rewIntServerlistActive: !prev.rewIntServerlistActive,
            }));
          }}
        />
        <Triple
          app={app}
          input={input}
          label="Application Open"
          setInput={setInput}
          bool="appOpenActive"
          str="appOpen"
          num="appOpenInterval"
          onToggle={() => {
            if (!admobActive)
              return pushNotification("نمایش تبلیغات خاموشه", "Error");
            setInput((prev) => ({
              ...prev,
              appOpenActive: !prev.appOpenActive,
            }));
          }}
        />
        <div className={classes.toggle} style={{ direction: "ltr" }}>
          <legend>GDPR Active</legend>
          <Toggle active={admobActive} />
        </div>
        {!isUserDumb && (
          <Fragment>
            <div className={classes.toggle}>
              <Toggle
                active={input.smartActive}
                onClick={() =>
                  setInput((prev) => ({
                    ...prev,
                    smartActive: !prev.smartActive,
                    smartIntConActive: prev.smartActive
                      ? false
                      : prev.smartIntConActive,
                    smartIntDisActive: prev.smartActive
                      ? false
                      : prev.smartIntDisActive,
                  }))
                }
              />
              <legend>فعال سازی حالت اسمارت</legend>
            </div>
            <Triple
              app={app}
              input={input}
              label="Smart Interstitial Connect"
              setInput={setInput}
              bool="smartIntConActive"
              num="smartIntConInterval"
              str="smartIntCon"
              onToggle={() => {
                if (!input.smartIntConActive && !input.smartActive)
                  return pushNotification("حالت اسمارت خاموشه", "Error");
                setInput((prev) => ({
                  ...prev,
                  smartIntConActive: !prev.smartIntConActive,
                }));
              }}
            />
            <Triple
              app={app}
              input={input}
              label="Smart Interstitial disconnect"
              setInput={setInput}
              bool="smartIntDisActive"
              num="smartIntDisInterval"
              str="smartIntDis"
              onToggle={() => {
                if (!input.smartIntDisActive && !input.smartActive)
                  return pushNotification("حالت اسمارت خاموشه", "Error");
                setInput((prev) => ({
                  ...prev,
                  smartIntDisActive: !prev.smartIntDisActive,
                }));
              }}
            />
          </Fragment>
        )}
        <Button type="submit" className={classes.submit}>
          {getContent(!!isSaving ? "savingSettings" : "save")}
        </Button>
      </Form>
      {!!isSaving && (
        <AdmobSettingsSaver
          admob={admobActive}
          id={`${app.id}`}
          onDone={onAfterSave}
          payload={isSaving}
        />
      )}
    </Fragment>
  );
};
export default AdmobSettingsForm;
