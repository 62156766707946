import { Fragment, useState } from "react";
import Toggle from "../_UI/Toggle";
import { IMenu } from "./AccessMenuPage";
import classes from "./MenuToggler.module.css";
import useLocale from "../../hooks/useLocale";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const Toggler = ({
  menu,
  onDone,
}: {
  menu: IMenu;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("togglingStatus"));
  useSWR(
    `${API}/access/changeStatusMenu/${menu.id}`,
    (url) =>
      fetcher({ url, method: "PUT", payload: { status: !menu.status } }).then(
        (res) => res.success
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("toggleStatusSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("toggleStatusError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const MenuToggler = ({ menu, refresh }: { menu: IMenu; refresh: Function }) => {
  const [isToggling, setIsToggling] = useState<boolean>(false);

  const onAfterToggle = (status: boolean) => {
    setIsToggling(false);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <span className={classes.main}>
        <Toggle onClick={() => setIsToggling(true)} active={menu.status} />
      </span>
      {isToggling && <Toggler menu={menu} onDone={onAfterToggle} />}
    </Fragment>
  );
};
export default MenuToggler;
