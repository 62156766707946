import { useCallback, useContext } from "react";
import LocaleContext from "../store/LocaleContext";
import { localables } from "./Locale/Fields";
import { fa } from "./Locale/Fa";
import { en } from "./Locale/En";

type Localable = (typeof localables)[number];

export type LocaleMap = { [key in Localable]: string };

const localeToMap = { fa, en };

const useLocale = (): ((key: Localable) => string) => {
  const { locale } = useContext(LocaleContext);
  const getContent = useCallback(
    (key: Localable) => localeToMap[locale][key],
    [locale]
  );
  return getContent;
};
export default useLocale;
