import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Toggle from "../_UI/Toggle";
import { IApp } from "../Servers/ManageAllServersPage";
import classes from "./AppStatus.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import usePopup from "../../hooks/usePopup";
import PopupCard from "../Popup/PopupCard";
import Title from "../_UI/Title";
import Button from "../_UI/Button";

const ConfirmTogglePopup = ({ onConfirm }: { onConfirm: () => void }) => {
  const { closePopup } = usePopup();
  const getContent = useLocale();
  return (
    <PopupCard title={getContent("AreYouSure")}>
      <div className={classes.confirm}>
        <Title>{getContent("AreYouSure")}</Title>
        <div className={classes.actions}>
          <Button
            onClick={() => {
              onConfirm();
              closePopup();
            }}
          >
            {getContent("yes")}
          </Button>
          <Button onClick={closePopup}>{getContent("no")}</Button>
        </div>
      </div>
    </PopupCard>
  );
};

const Toggler = ({
  state,
  onDone,
  id,
}: {
  state: boolean;
  onDone: (status: boolean) => void;
  id: string;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("togglingStatus"));
  useSWR(
    `${API}/apps/changeStatus/${id}/${state}`,
    (url: string) => fetcher({ url, method: "PUT" }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("toggleStatusSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("toggleStatusError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const AppStatus = ({ refresh, node }: { refresh: Function; node: IApp }) => {
  const getContent = useLocale();
  const [isToggling, setIsToggling] = useState<boolean | null>(null);

  const { setPopup } = usePopup();

  const onAfterToggle = (status: boolean) => {
    setIsToggling(null);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <Toggle
          onClick={() => {
            if (node.active) {
              setPopup(
                <ConfirmTogglePopup
                  onConfirm={() => setIsToggling(!node.active)}
                />
              );
            } else {
              setIsToggling(!node.active);
            }
          }}
          active={node.active}
        />
        <span>{getContent(node.active ? "active" : "inactive")}</span>
      </div>
      {isToggling !== null && (
        <Toggler id={node.id} onDone={onAfterToggle} state={isToggling} />
      )}
    </Fragment>
  );
};
export default AppStatus;
