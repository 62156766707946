import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import { IUser } from "../../hooks/useUser";
import Form from "../_UI/Form";
import SelectSegment from "../_UI/SelectSegment";
import PopupCard from "../Popup/PopupCard";
import classes from "./EditUserRolePopup.module.css";
import Button from "../_UI/Button";
import usePopup from "../../hooks/usePopup";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const RoleChanger = ({
  user,
  payload,
  onDone,
}: {
  user: IUser;
  payload: number;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("changingRole"));
  useSWR(
    { url: `${API}/users/updateAccess/${user.id}`, payload },
    ({ url, payload }) =>
      fetcher({ url, payload: { role_id: payload }, method: "PUT" }).then(
        (res) => res.success
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification("roleChangeSuccess");
        onDone(true);
      },
      onError: () => {
        pushNotification("roleChangeError");
        onDone(false);
      },
    }
  );
  return null;
};

const EditUserRolePopup = ({
  user,
  refresh,
}: {
  user: IUser;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const [selected, setSelected] = useState<number>();

  const [isChangingRole, setIsChangingRole] = useState<number | null>(null);

  const { closePopup } = usePopup();
  const pushNotification = useNotification();

  const onChangeRole = () => {
    if (!selected) return pushNotification(getContent("checkInput"), "Error");
    setIsChangingRole(selected);
  };

  const onAfterRoleChanged = (status: boolean) => {
    setIsChangingRole(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("changeRole")}>
        <Form className={classes.main} onSubmit={onChangeRole}>
          <SelectSegment
            required
            segment="role"
            onChange={(e) => setSelected(e)}
            multi={false}
          />
          <div className={classes.actions}>
            <Button type="button" onClick={closePopup}>
              {getContent("cancel")}
            </Button>
            <Button type="submit">
              {getContent(isChangingRole ? "changingRole" : "changeRole")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isChangingRole && (
        <RoleChanger
          onDone={onAfterRoleChanged}
          payload={isChangingRole}
          user={user}
        />
      )}
    </Fragment>
  );
};
export default EditUserRolePopup;
