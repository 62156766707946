import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import Title from "../_UI/Title";
import PopupCard from "../Popup/PopupCard";
import classes from "./BatchServerDeleterPopup.module.css";
import { IServer } from "./ManageAllServersPage";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const Deletor = ({
  servers,
  onDone,
}: {
  servers: IServer[];
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/apps/destroyMultipleServers`,
    (url) =>
      fetcher({
        url,
        method: "POST",
        payload: { ids: servers.map((server) => server.id) },
      }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const BatchServerDeleterPopup = ({
  servers,
  refresh,
}: {
  servers: IServer[];
  refresh: Function;
}) => {
  const getContent = useLocale();
  const { closePopup } = usePopup();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("delete")}>
        <div className={classes.main}>
          <Title>{`آیا از حذف ${servers.length} آیتم مطمئنید؟`}</Title>
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {getContent("delete")}
            </Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <Deletor onDone={onAfterDelete} servers={servers} />}
    </Fragment>
  );
};
export default BatchServerDeleterPopup;
