import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-quill/dist/quill.snow.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoadingPage from "./components/Loading/LoadingPage";
import LoginPage from "./components/Login/LoginPage";
import DashboardPage from "./components/Dashboard/DashboardPage";
import Layout from "./components/Layout/Layout";
import { NotificationContextProvider } from "./store/NotificationContext";
import { LocaleContextProvider } from "./store/LocaleContext";
import { TokenContextProvider } from "./store/TokenContext";
import ErrorPage from "./components/Error/ErrorPage";
import ManageAllServersPage from "./components/Servers/ManageAllServersPage";
import ManageMyServersPage from "./components/Servers/ManageMyServersPage";
import ServerSettingsPage from "./components/Servers/ServerSettingsPage";
import PrivacySettingsPage from "./components/Settings/PrivacySettingsPage";
import DomainEncryptionPage from "./components/Settings/DomainEncryptionPage";
import PanelTrainingPage from "./components/Help/PanelTrainingPage";
import AppAdminPanelTrainingPage from "./components/Help/AppAdminPanelTrainingPage";
import ApiHelpPage from "./components/Help/ApiHelpPage";
import UserInfoPage from "./components/Account/UserInfoPage";
import ChangePasswordPage from "./components/Account/ChangePasswordPage";
import ManageAllAppsPage from "./components/Apps/ManageAllAppsPage";
import ManageMyAppsPage from "./components/Apps/ManageMyAppsPage";
import ManageDevicesPage from "./components/Apps/ManageDevicesPage";
import UsersListPage from "./components/Users/UsersListPage";
import NewUserPage from "./components/Users/NewUserPage";
import AccessMenuPage from "./components/Access/AccessMenuPage";
import AccessModulePage from "./components/Access/AccessModulePage";
import AccessRolePage from "./components/Access/AccessRolePage";
import ManageSingleServerPage from "./components/Servers/ManageSingleServerPage";
import ManageSingleAppPage from "./components/Apps/ManageSingleAppPage";
import ManageRolePage from "./components/Access/ManageRolePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <LoadingPage />
      </Layout>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: (
      <Layout>
        <LoginPage />
      </Layout>
    ),
  },
  {
    element: <Layout />,
    children: [{ path: "dashboard", element: <DashboardPage /> }],
  },
  {
    element: <Layout />,
    children: [
      {
        path: "servers",
        children: [
          { path: "manage", element: <ManageAllServersPage /> },
          { path: "my-servers", element: <ManageMyServersPage /> },
          { path: "settings", element: <ServerSettingsPage /> },
          { path: ":serverId", element: <ManageSingleServerPage /> },
        ],
      },
      {
        path: "settings",
        children: [
          { path: "privacy", element: <PrivacySettingsPage /> },
          { path: "domain-encryption", element: <DomainEncryptionPage /> },
        ],
      },
      {
        path: "help",
        children: [
          { path: "panel-training", element: <PanelTrainingPage /> },
          {
            path: "app-admin-panel-training",
            element: <AppAdminPanelTrainingPage />,
          },
          { path: "api", element: <ApiHelpPage /> },
        ],
      },
      {
        path: "account",
        children: [
          { path: "user-info", element: <UserInfoPage /> },
          { path: "change-password", element: <ChangePasswordPage /> },
        ],
      },
      {
        path: "app",
        children: [
          { path: "manage", element: <ManageAllAppsPage /> },
          { path: "my-apps", element: <ManageMyAppsPage /> },
          { path: "devices", element: <ManageDevicesPage /> },
          { path: ":appId", element: <ManageSingleAppPage /> },
        ],
      },
      {
        path: "users",
        children: [
          { path: "list", element: <UsersListPage /> },
          { path: "add-new", element: <NewUserPage /> },
        ],
      },
      {
        path: "access",
        children: [
          { path: "menu", element: <AccessMenuPage /> },
          { path: "module", element: <AccessModulePage /> },
          { path: "role", element: <AccessRolePage /> },
          { path: ":roleId", element: <ManageRolePage /> },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <TokenContextProvider>
      <LocaleContextProvider>
        <NotificationContextProvider>
          <RouterProvider router={router} />
        </NotificationContextProvider>
      </LocaleContextProvider>
    </TokenContextProvider>
  </React.StrictMode>
);
