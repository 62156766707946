import { Fragment } from "react/jsx-runtime";
import { IRole } from "../../hooks/useUser";
import PopupCard from "../Popup/PopupCard";
import classes from "./EditRolePopup.module.css";
import useSWR from "swr";
import { API } from "../../config";
import useLocale from "../../hooks/useLocale";
import useNotification from "../../hooks/useNotification";
import { useState } from "react";
import Form from "../_UI/Form";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import usePopup from "../../hooks/usePopup";
import { fetcher } from "../../helpers/fetcher";

type EditRolePayload = {
  name: string;
  description: string | null;
};

const RoleEditor = ({
  role,
  onDone,
  payload,
}: {
  role?: IRole;
  onDone: (status: boolean) => void;
  payload: EditRolePayload;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(
    getContent(!!role ? "editingRole" : "creatingRole")
  );
  useSWR(
    `${API}/access/${!!role ? `updateRole/${role.id}` : "storeRole"}`,
    (url: string) =>
      fetcher({ url, method: !!role ? "PUT" : "POST", payload }).then((res) => {
        if (res.success) {
          return true;
        } else {
          throw new Error();
        }
      }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(
          getContent(!!role ? "editRoleSuccess" : "createRoleSuccess"),
          "Success"
        );
        onDone(true);
      },
      onError: () => {
        pushNotification(
          getContent(!!role ? "editRoleError" : "createRoleError"),
          "Error"
        );
        onDone(false);
      },
    }
  );
  return null;
};

const EditRolePopup = ({
  role,
  refresh,
}: {
  role?: IRole;
  refresh: Function;
}) => {
  const [input, setInput] = useState<EditRolePayload>({
    description: role?.description || "",
    name: role?.name || "",
  });
  const [isEditing, setIsEditing] = useState<EditRolePayload | null>(null);
  const getContent = useLocale();
  const { closePopup } = usePopup();

  const pushNotification = useNotification();
  const onEdit = () => {
    if (!input.name.trim().length)
      return pushNotification(getContent("checkInput"));
    setIsEditing({ ...input });
  };

  const onAfterEdit = (status: boolean) => {
    setIsEditing(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent(!!role ? "editRole" : "createRole")}>
        <Form className={classes.main} onSubmit={onEdit}>
          <Input
            required
            label={getContent("name")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, name: e.target.value }))
            }
            defaultValue={role?.name}
          />
          <Input
            label={getContent("description")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, description: e.target.value }))
            }
            defaultValue={role?.description}
          />
          <div className={classes.actions}>
            <Button type="button" onClick={closePopup}>
              {getContent("cancel")}
            </Button>
            <Button type="submit">
              {getContent(!!isEditing ? "saving" : "save")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isEditing && (
        <RoleEditor onDone={onAfterEdit} payload={isEditing} role={role} />
      )}
    </Fragment>
  );
};
export default EditRolePopup;
