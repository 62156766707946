import { useNavigate } from "react-router-dom";
import useLocale from "../../hooks/useLocale";
import Button from "../_UI/Button";
import classes from "./ErrorPage.module.css";
const ErrorPage = () => {
  const getContent = useLocale();
  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      <h1 className={classes.title}>{getContent("anErrorOccured")}</h1>
      <div className={classes.actions}>
        <Button onClick={() => navigate("/dashboard")}>
          {getContent("dashboard")}
        </Button>
        <Button onClick={() => window.location.reload()}>
          {getContent("reload")}
        </Button>
      </div>
    </div>
  );
};
export default ErrorPage;
