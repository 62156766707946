import { Fragment, useState } from "react";
import IconButton from "../_UI/IconButton";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import { IModuleParent } from "./PermissionList";
import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import useNotification from "../../hooks/useNotification";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const Deletor = ({
  id,
  onDone,
}: {
  id: number;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/access/deletePermission/${id}`,
    (url) => fetcher({ url, method: "DELETE" }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const RemoveSingleSubModule = ({
  node,
  refresh,
}: {
  node: IModuleParent;
  refresh: Function;
}) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <IconButton
        style={{ backgroundColor: "var(--danger)" }}
        onClick={() => setIsDeleting(true)}
      >
        <GarbageIcon />
      </IconButton>
      {isDeleting && <Deletor id={node.SubModule.id} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default RemoveSingleSubModule;
