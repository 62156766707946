import { Fragment, useState } from "react";
import SelectSegment from "../_UI/SelectSegment";
import classes from "./DomainEncryptionPage.module.css";
import Input from "../_UI/Input";
import useLocale from "../../hooks/useLocale";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Button from "../_UI/Button";
import useUser from "../../hooks/useUser";

type EncInput = { appId: number; word: string };

const Encryptor = ({
  payload,
  onDone,
}: {
  payload: EncInput;
  onDone: (result?: string) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("encrypting"));
  useSWR(
    { url: `${API}/apps/wordEncryption`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.data),
    {
      dedupingInterval: 0,
      onSuccess: (data) => {
        pushNotification(getContent("encryptionSuccess"), "Success");
        onDone(data);
      },
      onError: () => {
        pushNotification(getContent("encyptionError"), "Error");
        onDone();
      },
    }
  );
  return null;
};

const DomainEncryptionPage = () => {
  useUser(true);
  const [input, setInput] = useState<{
    [key in keyof EncInput]?: EncInput[key];
  }>({});

  const pushNotification = useNotification();

  const [isEncrypting, setIsEncrypting] = useState<EncInput | null>(null);

  const [result, setResult] = useState<string | null>(null);

  const getContent = useLocale();

  const onEncrypt = () => {
    if (input.appId === undefined || !input.word?.trim().length)
      return pushNotification(getContent("checkInput"), "Error");
    setResult(null);
    setIsEncrypting({ ...input } as EncInput);
  };

  const onAfterEncrypt = (result?: string) => {
    setIsEncrypting(null);
    if (!result) return;
    setResult(result);
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <SelectSegment
          required
          segment="application"
          onChange={(e) => setInput((prev) => ({ ...prev, appId: e }))}
          multi={false}
        />
        <Input
          required
          label={getContent("encInput")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, word: e.target.value }))
          }
        />
        <p className={classes.proTip}>{getContent("proTip")}</p>
        {!!result && <p className={classes.result}>{result}</p>}
        <Button onClick={onEncrypt} className={classes.submit}>
          {!!isEncrypting ? getContent("encrypting") : getContent("encrypt")}
        </Button>
      </div>
      {!!isEncrypting && (
        <Encryptor onDone={onAfterEncrypt} payload={isEncrypting} />
      )}
    </Fragment>
  );
};
export default DomainEncryptionPage;
