import useLocale from "../../hooks/useLocale";
import useUser from "../../hooks/useUser";
import Pair from "../_UI/Pair";
import classes from "./ApiHelpPage.module.css";
const ApiHelpPage = () => {
  useUser(true);

  const getContent = useLocale();

  return (
    <div className={classes.main}>
      <h1 className={classes.title}>{getContent("apiTitle")}</h1>
      <legend className={classes.text}>{getContent("apiDescription")}</legend>
      <a
        className={classes.link}
        target="_blank"
        href="https://api.gempanel.top/swagger"
        rel="noreferrer"
      >
        https://api.gempanel.top/swagger
      </a>
      <Pair title={getContent("username")} value="admin" />
      <Pair title={getContent("password")} value="123@qwe" />
    </div>
  );
};
export default ApiHelpPage;
