import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Form from "../_UI/Form";
import { IVersion } from "./AppBlockList";
import classes from "./BlockNewVersionForm.module.css";
import useNotification from "../../hooks/useNotification";
import Button from "../_UI/Button";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import { useParams } from "react-router-dom";

const Blocker = ({
  payload,
  onDone,
}: {
  payload: { appId: string; appVersion: string };
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("blocking"));
  useSWR(
    { url: `${API}/apps/versionBlock`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("blockSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("blockError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const BlockNewVersionForm = ({
  versions,
  refresh,
}: {
  versions: IVersion[];
  refresh: Function;
}) => {
  const getContent = useLocale();
  const { appId } = useParams();

  const [input, setInput] = useState<string>("");

  const [isBlocking, setIsBlocking] = useState<string | null>(null);

  const pushNotification = useNotification();

  const onBlock = () => {
    if (!input) return pushNotification(getContent("checkInput"), "Error");
    setIsBlocking(input);
  };

  const onAfterBlock = (status: boolean) => {
    setIsBlocking(null);
    if (!status) return;
    refresh();
  };

  return (
    <Fragment>
      <Form onSubmit={onBlock} className={classes.main}>
        <legend className={classes.title}>
          {getContent("selectVersionToBlock")}
        </legend>
        <select
          onChange={(e) => setInput(e.target.value)}
          className={classes.select}
        >
          <option value="">{getContent("selectVersionToBlock")}</option>
          {versions
            .filter((version) => version.status === true)
            .map((version) => (
              <option key={version.id} value={version.appVersion}>
                {version.appVersion}
              </option>
            ))}
        </select>
        <Button type="submit" className={classes.submit}>
          {getContent(!!isBlocking ? "blocking" : "block")}
        </Button>
      </Form>
      {!!isBlocking && (
        <Blocker
          payload={{ appId: `${appId}`, appVersion: isBlocking }}
          onDone={onAfterBlock}
        />
      )}
    </Fragment>
  );
};
export default BlockNewVersionForm;
