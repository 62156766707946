import { Fragment, useContext, useRef, useState } from "react";
import useUser from "../../hooks/useUser";
import Loading from "../_UI/Loading";
import classes from "./UserInfoPage.module.css";
import LocaleContext from "../../store/LocaleContext";
import Pair from "../_UI/Pair";
import useLocale from "../../hooks/useLocale";
import { UploadImageIcon } from "../Icons/UploadImageIcon";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { FetchError } from "../../helpers/fetcher";

const Uploader = ({
  payload,
  onDone,
}: {
  payload: FormData;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("uploading"));
  useSWR(
    { url: `${API}/users/avatar`, payload: payload },
    (args) =>
      (async () => {
        pushNotification(getContent("uploading"));
        const response = await fetch(args.url, {
          headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
          method: "POST",
          body: args.payload,
        });
        if (!response.ok)
          throw new FetchError(`Something went wrong`, response.status);
      })().then((res) => res),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("uploadSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("uploadError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const UserInfoPage = () => {
  const { user, refreshUser } = useUser(true);

  const { locale } = useContext(LocaleContext);

  const [isUploading, setIsUploading] = useState<FormData | null>(null);

  const getContent = useLocale();

  const fileRef = useRef<HTMLInputElement>(null);

  const onImageSelected = () => {
    if (!fileRef.current?.files?.[0]) return;
    const form = new FormData();
    form.append("avatar", fileRef.current.files[0]);
    setIsUploading(form);
  };

  const onAfterImageUploaded = (status: boolean) => {
    setIsUploading(null);
    if (!status) return;
    refreshUser();
    if (fileRef.current) fileRef.current.value = "";
  };

  if (!user) return <Loading />;
  return (
    <Fragment>
      <div className={classes.main}>
        <div className={classes.user}>
          <div className={classes.imageContainer}>
            <div className={classes.image}>
              <img alt={user.name} src={user.avatar} />
            </div>
            <label className={classes.changeButton} htmlFor="AVATAR">
              <UploadImageIcon />
            </label>
            <input
              ref={fileRef}
              onChange={onImageSelected}
              className={classes.hide}
              accept="image/,.jpg"
              type="file"
              id="AVATAR"
            />
          </div>
          <p className={classes.name}>
            {user.name} {user.family}
          </p>
          <p className={classes.username}>@{user.username}</p>
        </div>
        <div className={classes.details}>
          <span className={classes.role}>{user.role.name}</span>
          <Pair
            title={getContent("joinedAt")}
            value={new Date(user.updatedAt || new Date()).toLocaleString(
              locale === "en" ? "en-US" : "fa-IR",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
              }
            )}
          />
        </div>
      </div>
      {!!isUploading && (
        <Uploader onDone={onAfterImageUploaded} payload={isUploading} />
      )}
    </Fragment>
  );
};
export default UserInfoPage;
