import { useState } from "react";
import classes from "./TimeSelector.module.css";
import { ChevronIcon } from "../Icons/ChevronIcon";
import useLocale from "../../hooks/useLocale";
import { clamp } from "../../helpers/clamp";
import { pad } from "../../helpers/pad";

const MINUTE_STEP = 30;

const TimeSelector = ({
  onChange,
  defaultValue,
  title,
}: {
  onChange: (val: number) => void;
  defaultValue?: number;
  title: string;
}) => {
  const [value, setValue] = useState<number>(defaultValue || 0);
  const getContent = useLocale();
  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>
      <div className={classes.main}>
        <div className={classes.col}>
          <span
            className={classes.chevron}
            onClick={() => {
              const val = value + 60 * 60;
              onChange(val);
              setValue(val);
            }}
          >
            <ChevronIcon />
          </span>
          <span className={classes.value}>
            {pad(Math.floor(value / (60 * 60)))}
          </span>
          <span
            className={classes.chevron}
            onClick={() => {
              const val = clamp(0, value - 60 * 60, Number.MAX_SAFE_INTEGER);
              onChange(val);
              setValue(val);
            }}
            style={{ transform: "rotateZ(180deg)" }}
          >
            <ChevronIcon />
          </span>
          <span className={classes.label}>{getContent("hour")}</span>
        </div>
        <div className={classes.col}>
          <span
            className={classes.chevron}
            onClick={() => {
              const val = value + MINUTE_STEP * 60;
              setValue(val);
              onChange(val);
            }}
          >
            <ChevronIcon />
          </span>
          <span className={classes.value}>{pad((value / 60) % 60)}</span>
          <span
            style={{ transform: "rotateZ(180deg)" }}
            className={classes.chevron}
            onClick={() => {
              const val = clamp(
                0,
                value - MINUTE_STEP * 60,
                Number.MAX_SAFE_INTEGER
              );
              setValue(val);
              onChange(val);
            }}
          >
            <ChevronIcon />
          </span>
          <span className={classes.label}>{getContent("minute")}</span>
        </div>
      </div>
    </div>
  );
};
export default TimeSelector;
