import useSWR from "swr";
import { API } from "../../config";
import { useParams } from "react-router-dom";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import TabSystem from "../_UI/TabSystem";
import useUser from "../../hooks/useUser";
import useLocale from "../../hooks/useLocale";
import ApplicationSettingsForm from "./ApplicationSettingsForm";
import AdmobSettingsForm from "./AdmobSettingsForm";
import AppBlockList from "./AppBlockList";
import { IApp } from "../Servers/ManageAllServersPage";
const ManageSingleAppPage = () => {
  useUser(true);
  const { appId } = useParams();
  const { data, error, mutate } = useSWR<IApp>(
    `${API}/apps/getApp/${appId}`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );
  const getContent = useLocale();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <div>
      <TabSystem
        items={[
          {
            name: getContent("applicationSettings"),
            content: <ApplicationSettingsForm app={data} refresh={mutate} />,
            id: "applciationSettings",
          },
          {
            name: getContent("admobSettings"),
            content: <AdmobSettingsForm app={data} refresh={mutate} />,
            id: "admobSettings",
          },
          {
            name: getContent("blockList"),
            content: <AppBlockList />,
            id: "blockList",
          },
        ]}
      />
    </div>
  );
};
export default ManageSingleAppPage;
