import { CSSProperties } from "react";
import classes from "./Title.module.css";
const Title = (props: {
  children: string;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <h1
      className={`${classes.main} ${props.className || ""}`}
      style={props.style}
    >
      {props.children}
    </h1>
  );
};
export default Title;
