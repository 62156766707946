import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import { IUser } from "../../hooks/useUser";
import Button from "../_UI/Button";
import Title from "../_UI/Title";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeleteUserPopup.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";

const Deleter = ({
  user,
  onDone,
}: {
  user: IUser;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));

  useSWR(
    `${API}/users/destroy/${user.id}`,
    (url) => fetcher({ url, method: "DELETE" }).then((res) => res.data),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const DeleteUserPopup = ({
  user,
  refresh,
}: {
  user: IUser;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { closePopup } = usePopup();

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("deleteUser")}>
        <div className={classes.main}>
          <Title>{getContent("areYouSureDelete")}</Title>
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {getContent(isDeleting ? "deleting" : "delete")}
            </Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <Deleter user={user} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default DeleteUserPopup;
