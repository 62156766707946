import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import classes from "./IconButton.module.css";
const IconButton = (props: {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  title?: string;
  className?: string;
  style?: CSSProperties;
  type?: "button" | "submit";
}) => {
  return (
    <button
      className={`${classes.main} ${props.className || ""}`}
      onClick={props.onClick}
      type={props.type}
      title={props.title}
      style={props.style}
    >
      {props.children}
    </button>
  );
};
export default IconButton;
