import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeleteMenuPopup.module.css";
import Title from "../_UI/Title";
import Button from "../_UI/Button";
import { IMenu } from "./AccessMenuPage";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const MenuDeleter = ({
  menu,
  onDone,
}: {
  menu: IMenu;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/access/destroyMenu/${menu.id}`,
    (url) => fetcher({ url, method: "DELETE" }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const DeleteMenuPopup = ({
  menu,
  refresh,
}: {
  menu: IMenu;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const { closePopup } = usePopup();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("deleteMenu")}>
        <div className={classes.main}>
          <Title>{getContent("areYouSureDelete")}</Title>
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {getContent(isDeleting ? "deleting" : "delete")}
            </Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <MenuDeleter menu={menu} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default DeleteMenuPopup;
