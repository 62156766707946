import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import classes from "./BatchConfigDeletePopup.module.css";
import { IProfile } from "./ManageSingleServerPage";
import usePopup from "../../hooks/usePopup";
import PopupCard from "../Popup/PopupCard";
import Title from "../_UI/Title";
import Button from "../_UI/Button";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const Deletor = ({
  profiles,
  onDone,
}: {
  profiles: IProfile[];
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/apps/destroyMultipleProfiles`,
    (url) =>
      fetcher({
        url,
        method: "POST",
        payload: { ids: profiles.map((profile) => profile.id) },
      }),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const BatchConfigDelete = ({
  profiles,
  refresh,
}: {
  profiles: IProfile[];
  refresh: Function;
}) => {
  const getContent = useLocale();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { closePopup } = usePopup();

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("delete")}>
        <div className={classes.main}>
          <Title>{`آیا از حذف ${profiles.length} کانفیگ مطمئنید؟`}</Title>
          <div className={classes.actions}>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
            <Button
              onClick={() => setIsDeleting(true)}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {getContent("delete")}
            </Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <Deletor onDone={onAfterDelete} profiles={profiles} />}
    </Fragment>
  );
};
export default BatchConfigDelete;
