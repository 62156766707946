import useSWR from "swr";
import classes from "./PermissionList.module.css";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import { useParams } from "react-router-dom";
import Table from "../_UI/Table";
import useLocale from "../../hooks/useLocale";
import AddNewModule from "./AddNewModule";
import IconButton from "../_UI/IconButton";
import { EyeIcon } from "../Icons/EyeIcon";
import usePopup from "../../hooks/usePopup";
import PreviewPermissionPopup from "./PreviewPermissionPopup";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import DeletePermissionPopup from "./DeletePermissionPopup";

export interface IModule {
  id: number;
  name: string;
  route: string;
  description: string | null;
  status: boolean;
  flag: null;
  createdAt: Date;
  updatedAt: Date;
  SubModule: ISubModule[];
}

export interface ISubModule {
  id: number;
  route: string;
  name: string;
  module_id: number;
  method: string;
  description: null | string;
  status: boolean;
  flag: null;
  createdAt: Date;
  updatedAt: Date;
  Module: IModule;
}

export interface IModuleParent {
  id: number;
  role_id: number;
  sub_module_id: number;
  module_id: number;
  description: string | null;
  status: boolean;
  createdAt: Date;
  updatedAt: Date;
  SubModule: ISubModule;
}

export interface IModuleContainer {
  module_id: number;
  module_name: string;
  items: IModuleParent[];
}

const PermissionList = () => {
  const { roleId } = useParams();
  const { data, error, mutate } = useSWR<IModuleContainer[]>(
    `${API}/access/permissions/${roleId}`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const getContent = useLocale();

  const { setPopup } = usePopup();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <Table
      create={{
        title: getContent("addModule"),
        popup: <AddNewModule nodes={data} refresh={mutate} />,
      }}
      data={data.map((mod, index) => ({
        ...mod,
        id: mod.module_id,
        index: index + 1,
      }))}
      title={getContent("permissionManagement")}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        name: {
          name: getContent("module"),
          component: (node) => <span>{node.module_name}</span>,
        },
        apis: {
          name: getContent("apis"),
          component: (node) => <span>{node.items.length}</span>,
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                style={{ backgroundColor: "var(--green)" }}
                onClick={() =>
                  setPopup(
                    <PreviewPermissionPopup refresh={mutate} module={node} />
                  )
                }
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  setPopup(
                    <DeletePermissionPopup node={node} refresh={mutate} />
                  )
                }
                style={{ backgroundColor: "var(--danger)" }}
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
    />
  );
};
export default PermissionList;
