import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import Table, { ServerPage } from "../_UI/Table";
import classes from "./ManageDevicesPage.module.css";
import useSWR from "swr";
import { fetcher } from "../../helpers/fetcher";
import { API } from "../../config";
import Loading from "../_UI/Loading";
import ErrorPage from "../Error/ErrorPage";
import useLocale from "../../hooks/useLocale";
import useUser from "../../hooks/useUser";
import { LoaderIcon } from "../Icons/Loader";
import IconButton from "../_UI/IconButton";
import { EyeIcon } from "../Icons/EyeIcon";
import usePopup from "../../hooks/usePopup";
import DeviceOverviewPopup from "./DeviceOverviewPopup";
import { EditIcon } from "../Icons/EditIcon";
import DeviceDeletePopup from "./DeviceDeletePopup";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import CreateDevicePopup from "./CreateDevicePopup";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import SelectSegment from "../_UI/SelectSegment";

export interface IDevice {
  id: number;
  uniqueDeviceId: string;
  fcmToken: string;
  os: string;
  osVersionCode: string;
  manufacture: string;
  appVersionCode: string;
  versionRelease: string;
  model: string;
  deletedAt: null | Date;
  appId: number;
  createdAt: Date;
  updatedAt: Date;
  app: {
    name: string;
    id: number;
  };
}

type DeviceFilter = {
  appId: number | null;
  filter: string | null;
};

const Filter = ({
  setFilter,
}: {
  setFilter: Dispatch<SetStateAction<DeviceFilter>>;
}) => {
  const getContent = useLocale();
  return (
    <div className={classes.filter}>
      <div className={classes.segment}>
        <Input
          label={getContent("search")}
          onChange={(e) =>
            setFilter((prev) => ({
              ...prev,
              filter: e.target.value.trim().toLowerCase(),
            }))
          }
        />
        <Button
          onClick={() => setFilter((prev) => ({ ...prev, filter: null }))}
        >
          {getContent("clear")}
        </Button>
      </div>
      <div className={classes.segment}>
        <SelectSegment
          multi={false}
          onChange={(val) => setFilter((prev) => ({ ...prev, appId: val }))}
          segment="application"
        />
        <Button onClick={() => setFilter((prev) => ({ ...prev, appId: null }))}>
          {getContent("clear")}
        </Button>
      </div>
    </div>
  );
};

const ManageDevicesPage = () => {
  useUser(true);
  const [filter, setFilter] = useState<DeviceFilter>({
    appId: null,
    filter: null,
  });
  const [page, setPage] = useState<ServerPage>({ page: 1, size: 30 });
  const { data, error, isLoading, mutate } = useSWR<{
    count: number;
    rows: IDevice[];
  }>(
    `${API}/apps/devices?page=${page.page}&size=${page.size}&appId=${filter.appId}&filter=${filter.filter}`,
    (url: string) => fetcher({ url }).then((res) => res.data),
    { keepPreviousData: true }
  );

  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (data) setCount(data.count);
  }, [data]);

  const getContent = useLocale();

  const { setPopup } = usePopup();

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return (
    <Fragment>
      <Table
        create={{
          title: getContent("createDevice"),
          popup: <CreateDevicePopup refresh={mutate} />,
        }}
        serverFilter={<Filter setFilter={setFilter} />}
        data={data.rows.map((device, index) => ({
          ...device,
          index: index + 1,
        }))}
        renderer={{
          index: {
            name: "#",
            component: (node) => <span>{node.index}</span>,
            width: "2rem",
          },
          application: {
            name: getContent("application"),
            component: (node) => <span>{node.app.name}</span>,
          },
          brand: {
            name: getContent("deviceBrand"),
            component: (node) => <span>{node.model}</span>,
          },
          id: {
            name: getContent("deviceId"),
            component: (node) => <span>{node.uniqueDeviceId}</span>,
          },
          actions: {
            name: getContent("actions"),
            component: (node) => (
              <span className={classes.actions}>
                <IconButton
                  style={{ backgroundColor: "var(--green)" }}
                  onClick={() =>
                    setPopup(<DeviceOverviewPopup device={node} />)
                  }
                >
                  <EyeIcon />
                </IconButton>
                <IconButton
                  style={{ backgroundColor: "var(--blue)" }}
                  onClick={() =>
                    setPopup(
                      <CreateDevicePopup refresh={mutate} defaultValue={node} />
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  style={{ backgroundColor: "var(--danger)" }}
                  onClick={() =>
                    setPopup(
                      <DeviceDeletePopup device={node} refresh={mutate} />
                    )
                  }
                >
                  <GarbageIcon />
                </IconButton>
              </span>
            ),
          },
        }}
        title={getContent("deviceList")}
        serverPage={{ page, setPage, total: count }}
      />
      {isLoading && (
        <span className={classes.loader}>
          <LoaderIcon />
        </span>
      )}
    </Fragment>
  );
};
export default ManageDevicesPage;
