import { useEffect } from "react";
import classes from "./LoadingPage.module.css";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { LoadingIcon } from "../Icons/Loading";
import useToken from "../../hooks/useToken";
const LoadingPage = () => {
  const { user } = useUser(undefined);

  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (!token) return navigate("/login");
    if (user) return navigate("/dashboard");
  }, [navigate, token, user]);

  return (
    <div className={classes.main}>
      <div className={classes.icon}>
        <LoadingIcon />
      </div>
    </div>
  );
};
export default LoadingPage;
