import useSWR from "swr";
import classes from "./SelectSegment.module.css";
import Select from "react-select";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import { IApp, ICountry } from "../Servers/ManageAllServersPage";
import useLocale from "../../hooks/useLocale";
import { useMemo } from "react";

const segments = ["application", "country", "appAdmin", "role"] as const;

type Segment = (typeof segments)[number];

const segmentToUrl: { [key in Segment]: string } = {
  appAdmin: "users/appAdmins",
  application: "apps",
  country: "apps/countries",
  role: "access/roles",
};

const SelectSegment = <TMulti extends boolean>({
  onChange,
  multi,
  defaultValue,
  segment,
  required,
}: {
  onChange: (val: TMulti extends true ? number[] : number) => void;
  multi?: TMulti;
  defaultValue?: TMulti extends true ? number[] : number;
  segment: Segment;
  required?: boolean;
}) => {
  const { data } = useSWR<(IApp | ICountry)[]>(
    `${API}/${segmentToUrl[segment]}`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const getContent = useLocale();

  const defaults = useMemo<any>(() => {
    if (!defaultValue) return undefined;
    if (!data) return undefined;
    if (multi) {
      return data
        .filter((el) => (defaultValue as number[]).includes(Number(el.id)))
        .map((el: any) => ({
          value: el.id,
          label: `${el.name} ${el.family ? ` ${el.family}` : ""}`,
        }));
    } else {
      const target: any = data.find((el) => Number(el.id) === defaultValue);
      if (!target) return;
      return {
        value: target.id,
        label: `${target.name}${target.family ? ` ${target.family}` : ""}`,
      };
    }
  }, [data, defaultValue, multi]);

  return (
    <div className={classes.main}>
      <p className={classes.label}>
        {!!required && <span className={classes.star}>*</span>}
        <span>{getContent(segment)}</span>
      </p>
      <Select
        isLoading={!data}
        options={data?.map((el: any) => ({
          value: el.id,
          label: `${el.name}${el.family ? ` ${el.family}` : ""}`,
        }))}
        noOptionsMessage={() => getContent("noOption")}
        loadingMessage={() => getContent("loading")}
        placeholder={getContent(segment)}
        defaultValue={defaults}
        isMulti={multi}
        onChange={(e) => {
          if (e) {
            multi
              ? onChange((e as any).map((el: any) => Number(el.value)))
              : onChange((e as any).value);
          }
        }}
      />
    </div>
  );
};
export default SelectSegment;
