import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ServerList from "./ServerList";
import useUser from "../../hooks/useUser";
import ErrorPage from "../Error/ErrorPage";

export interface IAppSettings {
  UserConnectionLimit: number;
  appVersion: string;
  detectLocationBy: string;
  googlePlayLink: string;
  email: string;
  instagram: string;
  youtube: string;
  telegram: string;
  forceUpdate: boolean;
  adsForceByVPN: boolean;
  blockVersion: string;
  activeBlockVersion: boolean;
  adsForceByVPNInIran: boolean;
  adsForceByVPNInForeign: boolean;
  connectDelay: number;
  disconnectDelay: number;
  updateText: string;
  englishUpdateText: string;
  policyLink: string;
  aboutLink: string;
  configsActive: boolean;
  activeEncryptSetting: boolean;
  autoConnect: boolean;
  smartActive: boolean;
}

export interface IApp extends IAppSettings {
  id: string;
  name: string;
  packageName: string;
  sign: string;
  cppSign: string;
  policyPrivacy: string;
  apiKey: string;
  adminId: string;
  active: boolean;
  firebaseConfig: string;
  intCon: string | null;
  intConActive: boolean;
  intDis: string | null;
  intDisActive: boolean;
  rewIntCon: string | null;
  rewIntConActive: boolean;
  rewIntDis: string | null;
  rewIntDisActive: boolean;
  rewIntServerlist: string | null;
  rewIntServerlistInterval: number;
  rewIntServerlistActive: boolean;
  rew: string | null;
  rewActive: boolean;
  bannerNative1: null;
  bannerNativeActive1: boolean;
  bannerNative2: null;
  bannerNativeActive2: boolean;
  bannerNative3: null;
  bannerNativeActive3: boolean;
  appOpen: string;
  IDApp: string;
  appOpenActive: boolean;
  gdprActive: boolean;
  description: string;
  intConInIran: boolean;
  intDisInIran: boolean;
  rewIntConInIran: boolean;
  rewIntDisInIran: boolean;
  rewInIran: boolean;
  bannerNative1InIran: boolean;
  bannerNative2InIran: boolean;
  bannerNative3InIran: boolean;
  appOpenInIran: boolean;
  intConInterval: number;
  intDisInterval: number;
  rewIntDisInterval: number;
  rewIntConInterval: number;
  appOpenInterval: number;
  bannerNative1Interval: number;
  bannerNative2Interval: number;
  bannerNative3Interval: number;
  configsActive: boolean;
  activeEncryptSetting: boolean;
  smartIntCon: null;
  smartIntDisInterval: number;
  smartIntConActive: boolean;
  smartIntDis: string | null;
  smartIntConInterval: number;
  smartIntDisActive: boolean;
  queueName: null;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  rewInterval: number;
  rew2: string | null;
  rew2Active: boolean;
  rew2Interval: number;
}

export interface ICountry {
  id: string;
  name: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IServer {
  id: string;
  provider: string;
  flagUrl: string;
  ping: string;
  ip: string;
  port: null;
  username: null;
  password: null;
  countryId: string;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  location: ICountry;
  apps: [
    {
      id: string;
      appId: string;
      serverId: string;
      createdAt: Date;
      updatedAt: Date;
      app: IApp;
    }
  ];
}

const ManageAllServersPage = () => {
  useUser(true);
  const { data, error, mutate } = useSWR<IServer[]>(
    `${API}/apps/servers?appId=null`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );
  if (!data && error) return <ErrorPage />;
  return <ServerList data={data} mutate={mutate} batch />;
};
export default ManageAllServersPage;
