import { Fragment } from "react/jsx-runtime";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import classes from "./CreateNewAppPopup.module.css";
import Form from "../_UI/Form";
import useLocale from "../../hooks/useLocale";
import Input from "../_UI/Input";
import Button from "../_UI/Button";
import SelectSegment from "../_UI/SelectSegment";
import Area from "../_UI/Area";
import PopupCard from "../Popup/PopupCard";
import { useState } from "react";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import usePopup from "../../hooks/usePopup";
import { IApp } from "../Servers/ManageAllServersPage";

type CreateAppInput = {
  adminId: number;
  cppSign: string | null;
  description: string | null;
  firebaseConfig: string | null;
  name: string;
  packageName: string;
  sign: string;
  email?: string;
};

const AppCreator = ({
  onDone,
  payload,
  defaultValue,
}: {
  onDone: (status: boolean) => void;
  payload: CreateAppInput;
  defaultValue?: IApp;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(
    getContent(!!defaultValue ? "editingApp" : "creatingApp")
  );

  useSWR(
    {
      url: `${API}/apps/${
        !!defaultValue ? `updateApp/${defaultValue.id}` : "storeApp"
      }`,
      payload,
    },
    (args) =>
      fetcher({ ...args, method: !!defaultValue ? "PUT" : "POST" }).then(
        (res) => res.success
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(
          getContent(!!defaultValue ? "editAppSuccess" : "createAppSuccess"),
          "Success"
        );
        onDone(true);
      },
      onError: () => {
        pushNotification(
          getContent(!!defaultValue ? "editAppError" : "createAppError"),
          "Error"
        );
        onDone(false);
      },
    }
  );
  return null;
};

const CreateNewAppPopup = ({
  refresh,
  defaultValue,
}: {
  refresh: Function;
  defaultValue?: IApp;
}) => {
  const getContent = useLocale();
  const [input, setInput] = useState<{
    [key in keyof CreateAppInput]?: CreateAppInput[key];
  }>({
    adminId: Number(defaultValue?.adminId),
    cppSign: defaultValue?.cppSign,
    description: defaultValue?.description,
    firebaseConfig: defaultValue?.firebaseConfig,
    name: defaultValue?.name,
    packageName: defaultValue?.packageName,
    sign: defaultValue?.sign,
    email: defaultValue?.email,
  });

  const pushNotification = useNotification();

  const [isCreating, setIsCreating] = useState<CreateAppInput | null>(null);
  const { closePopup } = usePopup();

  const onCreate = () => {
    if (
      !input.name?.trim().length ||
      !input.packageName?.trim().length ||
      !input.sign?.trim().length ||
      input.adminId === undefined
    )
      return pushNotification(getContent("checkInput"), "Error");
    setIsCreating({
      name: input.name,
      adminId: input.adminId,
      cppSign: input.cppSign || null,
      description: null,
      firebaseConfig: input.firebaseConfig || null,
      packageName: input.packageName,
      sign: input.sign,
      email: input.email,
    });
  };

  const onAfterCreate = (status: boolean) => {
    setIsCreating(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard
        title={getContent(
          !!defaultValue ? "editApplication" : "createApplication"
        )}
      >
        <Form className={classes.main} onSubmit={onCreate}>
          <Input
            defaultValue={defaultValue?.name}
            required
            label={getContent("name")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <Input
            defaultValue={defaultValue?.packageName}
            required
            label={getContent("packageName")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, packageName: e.target.value }))
            }
          />
          <SelectSegment
            defaultValue={
              defaultValue?.adminId ? Number(defaultValue.adminId) : undefined
            }
            required
            onChange={(e) => {
              setInput((prev) => ({ ...prev, adminId: e }));
            }}
            multi={false}
            segment="appAdmin"
          />
          <Area
            defaultValue={defaultValue?.sign}
            required
            label={getContent("sign")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, sign: e.target.value }))
            }
          />
          <Area
            defaultValue={defaultValue?.cppSign}
            label={getContent("signcpp")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, cppSign: e.target.value }))
            }
          />
          <Area
            defaultValue={defaultValue?.firebaseConfig}
            label={getContent("firebaseConf")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, firebaseConfig: e.target.value }))
            }
          />
          <Input
            defaultValue={defaultValue?.email}
            label={getContent("email")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <Button className={classes.submit} type="submit">
            {getContent(
              isCreating
                ? "creatingApp"
                : !!defaultValue
                ? "editApplication"
                : "createApplication"
            )}
          </Button>
        </Form>
      </PopupCard>
      {!!isCreating && (
        <AppCreator
          onDone={onAfterCreate}
          payload={isCreating}
          defaultValue={defaultValue}
        />
      )}
    </Fragment>
  );
};
export default CreateNewAppPopup;
