import React, { ReactNode, useCallback, useState } from "react";

const TokenContext = React.createContext<{
  token: string;
  setToken: (val: string) => void;
}>({ token: "", setToken: () => {} });

export const TokenContextProvider = (props: { children: ReactNode }) => {
  const [token, _setToken] = useState<string>(
    localStorage.getItem("token") || ""
  );

  const setToken = useCallback((val: string) => {
    _setToken(val);
    localStorage.setItem("token", val);
  }, []);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {props.children}
    </TokenContext.Provider>
  );
};

export default TokenContext;
