import React, { ReactNode, useCallback, useState } from "react";

const PopupContext = React.createContext<{
  popup: ReactNode | null;
  setPopup: (node: ReactNode) => void;
  closePopup: () => void;
}>({ popup: null, setPopup: () => {}, closePopup: () => {} });

export const PopupContextProvider = (props: { children: ReactNode }) => {
  const [popup, setPopup] = useState<ReactNode>(null);
  const closePopup = useCallback(() => setPopup(null), []);
  return (
    <PopupContext.Provider value={{ popup, setPopup, closePopup }}>
      {props.children}
    </PopupContext.Provider>
  );
};

export default PopupContext;
