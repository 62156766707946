import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Pair from "../_UI/Pair";
import PopupCard from "../Popup/PopupCard";
import { IProfile } from "./ManageSingleServerPage";
import classes from "./PreviewProfilePopup.module.css";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import useUser from "../../hooks/useUser";

const Revealer = ({
  address,
  onDone,
}: {
  address: string;
  onDone: (value?: string) => void;
}) => {
  const getContent = useLocale();
  useNotification(getContent("revealing"));
  useSWR(
    `${API}/apps/convertProfile`,
    (url) =>
      fetcher({ url, method: "POST", payload: { config: address } }).then(
        (res) => res.data
      ),
    {
      dedupingInterval: 0,
      onSuccess: (data) => {
        onDone(data);
      },
      onError: () => {
        onDone();
      },
    }
  );
  return null;
};

const PreviewProfilePopup = ({ profile }: { profile: IProfile }) => {
  const getContent = useLocale();
  const [revealed, setRevealed] = useState<string | null>(null);

  const [isRevealing, setIsRevealing] = useState<string | null>(null);

  const onAfterReveal = (value?: string) => {
    setIsRevealing(null);
    if (!value) return;
    setRevealed(value);
  };

  const { user } = useUser(true);

  return (
    <Fragment>
      <PopupCard title={getContent("profileDetails")}>
        <div className={classes.main}>
          <Pair title={getContent("name")} value={profile.name} />
          <Pair title={getContent("operator")} value={profile.operator} />
          <Pair title={getContent("protocol")} value={profile.protocol} />
          <Pair title={getContent("profileType")} value={profile.type} />
          {user?.role_id === 1 && (
            <p
              className={`${classes.reveal}`}
              onClick={() =>
                revealed ? null : setIsRevealing(profile.address)
              }
            >
              {revealed ||
                (isRevealing
                  ? getContent("revealing")
                  : getContent("revealAddress"))}
            </p>
          )}
        </div>
      </PopupCard>
      {isRevealing && <Revealer address={isRevealing} onDone={onAfterReveal} />}
    </Fragment>
  );
};
export default PreviewProfilePopup;
