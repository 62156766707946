import useLocale from "../../hooks/useLocale";
import Table from "../_UI/Table";
import { IModuleContainer } from "./PermissionList";
import classes from "./PreviewPermissionPopup.module.css";
import RemoveSingleSubModule from "./RemoveSingleSubModule";
const PreviewPermissionPopup = ({
  module,
  refresh,
}: {
  module: IModuleContainer;
  refresh: Function;
}) => {
  const getContent = useLocale();


  return (
    <Table
      className={classes.main}
      data={module.items.map((module, index) => ({
        ...module,
        index: index + 1,
      }))}
      title={getContent("apis")}
      renderer={{
        index: { name: "#", component: (node) => <span>{node.index}</span> },
        name: {
          name: getContent("name"),
          component: (node) => <span>{node.SubModule.name}</span>,
        },
        route: {
          name: getContent("route"),
          component: (node) => <span dir="ltr">{node.SubModule.route}</span>,
        },
        method: {
          name: getContent("method"),
          component: (node) => <span>{node.SubModule.method}</span>,
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <RemoveSingleSubModule node={node} refresh={refresh} />
            </span>
          ),
        },
      }}
    />
  );
};
export default PreviewPermissionPopup;
