import { useState } from "react";
import classes from "./Radio.module.css";
const Radio = <T extends { [key: string]: string }>({
  onChange,
  options,
  title,
  defaultValue,
  required,
}: {
  title: string;
  options: T;
  onChange: (val: keyof T) => void;
  defaultValue?: string;
  required?: boolean;
}) => {
  const [selected, setSelected] = useState<keyof T | undefined>(
    defaultValue || undefined
  );

  return (
    <div className={classes.main}>
      <legend className={classes.title}>
        <span className={classes.star}>*</span>
        <span>{title}</span>
      </legend>
      <div className={classes.options}>
        {Object.keys(options).map((key) => (
          <button
            type="button"
            key={key}
            onClick={() => {
              setSelected(key);
              onChange(key);
            }}
            className={`${classes.option} ${
              selected === key ? classes.active : ""
            }`}
          >
            {options[key]}
          </button>
        ))}
      </div>
    </div>
  );
};
export default Radio;
