import { Fragment, useRef, useState } from "react";
import useLocale from "../../hooks/useLocale";
import classes from "./BatchImportProfilesForm.module.css";
import Button from "../_UI/Button";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { FetchError } from "../../helpers/fetcher";

const BatchImporter = ({
  payload,
  onDone,
}: {
  payload: FormData;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("uploading"));

  useSWR(
    `${API}/apps/group/addProfiles`,
    (url) =>
      (async () => {
        const response = await fetch(url, {
          headers: { Authorization: `JWT ${localStorage.getItem("token")}` },
          method: "POST",
          body: payload,
        });
        if (!response.ok)
          throw new FetchError("something went wrong", response.status);
        const data = await response.json();
        return data.success;
      })().then((res) => res),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("uploadSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("uploadError"), "Error");
        onDone(false);
      },
    }
  );

  return null;
};

const BatchImportProfilesForm = ({
  serverId,
  refresh,
}: {
  serverId: string;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const [filename, setFilename] = useState<string>("");
  const [isUploading, setIsUploading] = useState<FormData | null>(null);

  const pushNotification = useNotification();

  const fileRef = useRef<HTMLInputElement>(null);

  const onUpload = () => {
    if (!fileRef.current?.files?.[0])
      return pushNotification(getContent("selectFileFirst"), "Error");
    const form = new FormData();
    form.append("csvFile", fileRef.current.files?.[0]);
    form.append("serverId", `${serverId}`);
    setIsUploading(form);
  };

  const onAfterUpload = (status: boolean) => {
    setIsUploading(null);
    if (!status) return;
    refresh();
    setFilename("");
    if (!fileRef.current) return;
    fileRef.current.value = "";
  };

  return (
    <Fragment>
      <div className={classes.main}>
        <p className={classes.guide}>{getContent("batchImportGuide")}</p>
        <div className={classes.fileContainer}>
          <div className={classes.file}>
            <input
              ref={fileRef}
              onChange={(e) => setFilename(e.target.files?.[0].name || "")}
              id={"File"}
              type="file"
            />
            <label htmlFor="File">{getContent("SelectFile")}</label>
          </div>
          {filename && <span className={classes.filename}>{filename}</span>}
        </div>
        {filename && (
          <Button className={classes.submit} onClick={onUpload}>
            {getContent("upload")}
          </Button>
        )}
      </div>
      {!!isUploading && (
        <BatchImporter onDone={onAfterUpload} payload={isUploading} />
      )}
    </Fragment>
  );
};
export default BatchImportProfilesForm;
