import useSWR from "swr";
import { API } from "../config";
import { fetcher, FetchError } from "../helpers/fetcher";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "./useToken";
import { mutate as globalMutate } from "swr";

export interface IRole {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IPermission {
  id: string;
  enName: string;
  pName: string;
  route: string;
  icon: string;
  parent_id?: string | null;
  status: boolean;
  flag: string;
  createdAt: Date;
  updatedAt: Date;
  MenuFeature: {
    id: string;
    menu_id: string;
    feature: string;
    web_services: string;
    status: boolean;
    key: string;
    description: null | string;
    flag: null | string;
    createdAt: Date;
    updatedAt: Date;
  }[];
  children: IPermission[];
}

export interface IUser {
  id: string;
  email?: string;
  email_verified_at?: Date | null;
  deactive_account_at?: Date | null;
  username?: string;
  name?: string;
  family?: string;
  eName?: string;
  eFamily?: string;
  address?: string;
  nationalCode?: string | null;
  website?: string | null;
  mobile?: string | null;
  mobile_verified_at?: Date | null;
  avatar?: string;
  about?: string;
  active: boolean;
  needToChangePass: boolean;
  sex: boolean;
  online: boolean;
  birthdate?: Date | null;
  role_id: number;
  verificationCode?: string | null;
  deletedAt?: Date | null;
  createdAt?: Date;
  updatedAt?: Date | null;
  role: IRole;
  permissions: IPermission[];
}

const useUser = (require: boolean | undefined) => {
  const { token, setToken } = useToken();
  const {
    data: user,
    mutate,
    isLoading,
  } = useSWR<IUser>(
    token ? [`${API}/users/current`, token] : null,
    ([url]) => fetcher({ url }).then((res) => res.user),
    {
      onError: (err) => {
        if (err instanceof FetchError && err.status === 401) {
          globalMutate(() => true, undefined, { revalidate: false });
          setToken("");
        }
      },
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (require === undefined) return;
    if (require) {
      if (!token && !isLoading && !user) navigate("/login");
      return;
    }
    if (user) navigate("/dashboard");
  }, [isLoading, navigate, require, token, user]);

  return { user, refreshUser: mutate };
};
export default useUser;
