import classes from "./RTFEditor.module.css";

import ReactQuill from "react-quill";

const RTFEditor = (props: {
  onChange: (val: string) => void;
  defaultValue?: string;
}) => {
  return (
    <ReactQuill
      modules={{
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          ["link", "image"],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
        ],
      }}
      onChange={(val) => props.onChange(val)}
      placeholder=""
      defaultValue={props.defaultValue}
      className={classes.main}
    />
  );
};
export default RTFEditor;
