import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import PopupCard from "../Popup/PopupCard";
import { IVersion } from "./AppBlockList";
import useSWR from "swr";
import useNotification from "../../hooks/useNotification";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const Deleter = ({
  version,
  onDone,
}: {
  version: IVersion;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/apps/version${version.status ? "" : "Block"}/${version.id}`,
    (url: string) =>
      fetcher({ url, method: "DELETE" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const DeleteVersionpopup = ({
  version,
  refresh,
}: {
  version: IVersion;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const { closePopup } = usePopup();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("delete")}>
        <p>{getContent("areYouSureDelete")}</p>
        <div>
          <Button onClick={closePopup}>{getContent("cancel")}</Button>
          <Button onClick={() => setIsDeleting(true)}>
            {getContent(isDeleting ? "deleting" : "delete")}
          </Button>
        </div>
      </PopupCard>
      {isDeleting && <Deleter version={version} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default DeleteVersionpopup;
