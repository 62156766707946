import useLocale from "../../hooks/useLocale";
import { IUser } from "../../hooks/useUser";
import Pair from "../_UI/Pair";
import PopupCard from "../Popup/PopupCard";
import classes from "./UserOverviewPopup.module.css";
const UserOverviewPopup = ({ user }: { user: IUser }) => {
  const getContent = useLocale();
  return (
    <PopupCard title={getContent("userDetails")}>
      <div className={classes.main}>
        <Pair title={getContent("name")} value={user.name || ""} />
        <Pair title={getContent("family")} value={user.family || ""} />
        <Pair title={getContent("email")} value={user.email || ""} />
        <Pair title={getContent("role")} value={user.role.name} />
      </div>
    </PopupCard>
  );
};
export default UserOverviewPopup;
