import { Fragment, useMemo, useState } from "react";
import {
  IProfile,
  Operator,
  operators,
  ProfileType,
  profileTypes,
  Protocol,
  protocols,
} from "./ManageSingleServerPage";
import classes from "./NewProfileForm.module.css";
import Input from "../_UI/Input";
import useLocale from "../../hooks/useLocale";
import Radio from "../_UI/Radio";
import Button from "../_UI/Button";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { fetcher } from "../../helpers/fetcher";
import { API } from "../../config";
import Form from "../_UI/Form";
import usePopup from "../../hooks/usePopup";
import Area from "../_UI/Area";
import { pad } from "../../helpers/pad";
import useUser from "../../hooks/useUser";
import { clamp } from "../../helpers/clamp";

type CreateProfileInput = {
  name: string;
  operator: Operator;
  protocol: Protocol;
  type: ProfileType;
  address: string;
  serverId: string;
};

const ProfileCreator = ({
  payload,
  onDone,
  edit,
}: {
  payload: CreateProfileInput;
  onDone: (status: boolean) => void;
  edit?: string;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(
    getContent(!!edit ? "editingProfile" : "creatingProfile")
  );
  useSWR(
    {
      url: `${API}/apps/${!!edit ? `updateProfile/${edit}` : "addProfile"}`,
      payload,
    },
    (args) =>
      fetcher({ ...args, method: !!edit ? "PUT" : "POST" }).then((res) => res),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(
          getContent(!!edit ? "editProfileSuccess" : "createProfileSuccess"),
          "Success"
        );
        onDone(true);
      },
      onError: () => {
        pushNotification(
          getContent(!!edit ? "editProfileError" : "createProfileError"),
          "Error"
        );
        onDone(false);
      },
    }
  );
  return null;
};

const NewProfileForm = ({
  refresh,
  serverId,
  defaultValue,
  start,
}: {
  refresh: Function;
  serverId: string;
  defaultValue?: IProfile;
  start: number;
}) => {
  const { user } = useUser(true);

  const [currentName, setCurrentName] = useState<number>(start);

  const [input, setInput] = useState<{
    [key in keyof CreateProfileInput]?: CreateProfileInput[key];
  }>(
    defaultValue
      ? {
          name: defaultValue.name,
          address: defaultValue.address,
          operator: defaultValue.operator,
          protocol: defaultValue.protocol,
          type: defaultValue.type,
        }
      : {
          name: `S-${pad(
            currentName,
            clamp(2, currentName.toString().length, Number.MAX_SAFE_INTEGER)
          )}`,
          type: "normal",
        }
  );

  const { closePopup } = usePopup();

  const [shouldShow, setShouldShow] = useState<boolean>(true);

  const [isCreating, setIsCreating] = useState<CreateProfileInput | null>(null);

  const getContent = useLocale();
  const pushNotification = useNotification();

  const onCreate = () => {
    if (
      !input.name ||
      !input.address ||
      !input.operator ||
      !input.protocol ||
      !input.type
    )
      return pushNotification(getContent("checkInput"), "Error");
    setIsCreating({ ...input, serverId } as CreateProfileInput);
  };

  const onAfterCreate = (status: boolean) => {
    setIsCreating(null);
    if (!status) return;
    refresh();
    setCurrentName((prev) => prev + 1);
    setShouldShow(false);
    setTimeout(() => setShouldShow(true));
    closePopup();
  };

  const isUserDumb = useMemo<boolean>(
    () => user?.role_id === 5,
    [user?.role_id]
  );

  if (!shouldShow) return null;

  return (
    <Fragment>
      <Form className={classes.main} onSubmit={onCreate}>
        <h1 className={classes.title}>
          {getContent(!!defaultValue ? "editProfile" : "newProfile")}
        </h1>
        <Input
          required
          defaultValue={
            defaultValue?.name ||
            `S-${pad(
              currentName,
              clamp(2, currentName.toString().length, Number.MAX_SAFE_INTEGER)
            )}`
          }
          label={getContent("name")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, name: e.target.value }))
          }
        />
        <Radio
          defaultValue={defaultValue?.protocol}
          title={getContent("protocol")}
          options={protocols.reduce(
            (acc, protocol) => ({ ...acc, [protocol]: protocol }),
            {}
          )}
          onChange={(protocol) => setInput((prev) => ({ ...prev, protocol }))}
        />
        <Radio
          defaultValue={defaultValue?.operator}
          title={getContent("operator")}
          options={operators.reduce(
            (acc, operator) => ({ ...acc, [operator]: operator }),
            {}
          )}
          onChange={(operator) => setInput((prev) => ({ ...prev, operator }))}
        />
        {!isUserDumb && (
          <Radio
            defaultValue={defaultValue?.type || "normal"}
            title={getContent("profileType")}
            options={profileTypes.reduce(
              (acc, type) => ({ ...acc, [type]: type }),
              {}
            )}
            onChange={(type) => setInput((prev) => ({ ...prev, type }))}
          />
        )}
        <Area
          required
          defaultValue={defaultValue?.address}
          label={getContent("address")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, address: e.target.value }))
          }
        />
        <Button type="submit">
          {getContent(
            isCreating
              ? !!defaultValue
                ? "editingProfile"
                : "creatingProfile"
              : !!defaultValue
              ? "editProfile"
              : "createNewProfile"
          )}
        </Button>
      </Form>
      {!!isCreating && (
        <ProfileCreator
          onDone={onAfterCreate}
          payload={isCreating}
          edit={defaultValue?.id.toString()}
        />
      )}
    </Fragment>
  );
};
export default NewProfileForm;
