import useSWR from "swr";
import useLocale from "../../hooks/useLocale";
import Loading from "../_UI/Loading";
import Table, { FilterComponent } from "../_UI/Table";
import { IApp } from "../Servers/ManageAllServersPage";
import classes from "./AppList.module.css";
import CreateNewAppPopup from "./CreateNewAppPopup";
import { API } from "../../config";
import { IUser } from "../../hooks/useUser";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import AppStatus from "./AppStatus";
import IconButton from "../_UI/IconButton";
import usePopup from "../../hooks/usePopup";
import { EyeIcon } from "../Icons/EyeIcon";
import AppOverviewPopup from "./AppOverviewPopup";
import { EditIcon } from "../Icons/EditIcon";
import { CogIcon } from "../Icons/CogIcon";
import { useNavigate } from "react-router-dom";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import DeleteAppPopup from "./DeleteAppPopup";
import { useClipboard } from "../../hooks/useClipboard";
import { CopyIcon } from "../Icons/CopyIcon";
import Input from "../_UI/Input";
import Button from "../_UI/Button";

type AppFilter = { active?: boolean; name?: string };

const stringToState = {
  all: undefined,
  active: true,
  inactive: false,
} as const;

const Filter: FilterComponent<AppFilter> = ({ filter, setFilter }) => {
  const getContent = useLocale();
  return (
    <div className={classes.filter}>
      <div className={classes.segment}>
        <Input
          label={getContent("search")}
          onChange={(e) =>
            setFilter((prev) => ({
              ...prev,
              name: e.target.value.trim().toLowerCase(),
            }))
          }
        />
        <Button
          onClick={() => setFilter((prev) => ({ ...prev, name: undefined }))}
        >
          {getContent("clear")}
        </Button>
      </div>
      <div className={classes.segment}>
        <select
          className={classes.select}
          onChange={(e) =>
            setFilter((prev) => ({
              ...prev,
              active: (stringToState as any)[e.target.value],
            }))
          }
          defaultValue="active"
        >
          <option value="all">{getContent("allApplications")}</option>
          <option value="active">{getContent("activeApplications")}</option>
          <option value="inactive">{getContent("inactiveApplications")}</option>
        </select>
      </div>
    </div>
  );
};

const filterer = (node: IApp, filter: AppFilter): boolean => {
  if (
    filter?.name &&
    !node.name.toLocaleLowerCase().includes(filter.name.toLowerCase())
  )
    return false;
  if (filter?.active !== undefined && !!node.active !== filter.active)
    return false;
  return true;
};

const AppList = ({
  data,
  refresh,
}: {
  data: IApp[] | undefined;
  refresh: Function;
}) => {
  const copyTextToClipboard = useClipboard();
  const getContent = useLocale();
  const { data: admins, error } = useSWR<IUser[]>(
    `${API}/users/appAdmins`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const navigate = useNavigate();

  const { setPopup } = usePopup();

  if (!data && error) return <ErrorPage />;
  if (!data || !admins) return <Loading />;
  return (
    <Table<IApp & { index: number }, AppFilter>
      data={data.map((app, i) => ({ ...app, index: i + 1 }))}
      title={getContent("allApplications")}
      create={{
        title: getContent("createApplication"),
        popup: <CreateNewAppPopup refresh={refresh} />,
      }}
      filter={{
        filter: filterer,
        filterComponent: Filter,
        defaultFilter: { active: true },
      }}
      exporter={{
        name: { name: getContent("name"), valueGetter: (node) => node.name },
        admin: {
          name: getContent("appAdmin"),
          valueGetter: (node) =>
            `${admins?.find((admin) => admin.id === node.adminId)?.name} ${
              admins?.find((admin) => admin.id === node.adminId)?.family
            }`,
        },
        package: {
          name: getContent("packageName"),
          valueGetter: (node) => node.packageName,
        },
        apiKey: {
          name: "Api Key",
          valueGetter: (node) => node.apiKey,
        },
        goolePlayLink: {
          name: getContent("googlePlayLink"),
          valueGetter: (node) => node.googlePlayLink,
        },
      }}
      renderer={{
        index: {
          name: "#",
          width: "2rem",
          component: (node) => <span>{node.index}</span>,
          sort: (a, b) => a.index - b.index,
        },
        name: {
          name: getContent("name"),
          component: (node) => <span>{node.name}</span>,
          sort: (a, b) => a.name.localeCompare(b.name),
        },
        package: {
          name: getContent("packageName"),
          component: (node) => <span>{node.packageName}</span>,
          sort: (a, b) => a.packageName.localeCompare(b.packageName),
        },
        admin: {
          name: getContent("appAdmin"),
          component: (node) => (
            <span>
              {admins.find((admin) => admin.id === node.adminId)?.name}{" "}
              {admins.find((admin) => admin.id === node.adminId)?.family}
            </span>
          ),
          sort: (a, b) =>
            `${admins.find((admin) => admin.id === a.adminId)?.name} ${
              admins.find((admin) => admin.id === a.adminId)?.family
            }`.localeCompare(
              `${admins.find((admin) => admin.id === b.adminId)?.name} ${
                admins.find((admin) => admin.id === b.adminId)?.family
              }`
            ),
        },
        ApiKey: {
          name: "ApiKey",
          component: (node) => (
            <div
              className={classes.key}
              onClick={() => copyTextToClipboard(node.apiKey)}
            >
              <span className={classes.copy}>
                <CopyIcon />
              </span>
              <span>{node.apiKey}</span>
            </div>
          ),
          sort: (a, b) => a.apiKey.localeCompare(b.apiKey),
        },
        status: {
          name: getContent("status"),
          component: (node) => <AppStatus node={node} refresh={refresh} />,
          sort: (a, b) => `${a.active}`.localeCompare(`${b.active}`),
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                style={{ backgroundColor: "var(--green)" }}
                onClick={() => setPopup(<AppOverviewPopup app={node} />)}
                title={getContent("appDetails")}
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--cyan)" }}
                onClick={() =>
                  setPopup(
                    <CreateNewAppPopup refresh={refresh} defaultValue={node} />
                  )
                }
                title={getContent("editApplication")}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--blue)" }}
                onClick={() => navigate(`/app/${node.id}`)}
                title={getContent("applicationSettings")}
              >
                <CogIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--danger)" }}
                onClick={() =>
                  setPopup(<DeleteAppPopup app={node} refresh={refresh} />)
                }
                title={getContent("deleteApplication")}
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
    />
  );
};
export default AppList;
