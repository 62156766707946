import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import { IApp } from "../Servers/ManageAllServersPage";
import ErrorPage from "../Error/ErrorPage";
import AppList from "./AppList";
import useUser from "../../hooks/useUser";
const ManageAllAppsPage = () => {
  useUser(true);
  const { data, error, mutate } = useSWR<IApp[]>(`${API}/apps`, (url: string) =>
    fetcher({ url }).then((res) => res.data)
  );

  if (!data && error) return <ErrorPage />;
  return <AppList data={data} refresh={mutate} />;
};
export default ManageAllAppsPage;
