import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import { IUser } from "../../hooks/useUser";
import Form from "../_UI/Form";
import Input from "../_UI/Input";
import PopupCard from "../Popup/PopupCard";
import classes from "./EditUserPopup.module.css";
import usePopup from "../../hooks/usePopup";
import useNotification from "../../hooks/useNotification";
import Button from "../_UI/Button";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";

const fields = [
  "email",
  "name",
  "family",
  "eName",
  "eFamily",
  "mobile",
  "address",
  "website",
  "about",
] as const;

const requireds: (typeof fields)[number][] = ["email", "name", "family"];

type EditUserInput = {
  email: string;
  name: string;
  family: string;
  eName?: string;
  eFamily?: string;
  mobile: string;
  sex: number;
  address?: string;
  website?: string;
  about?: string;
  birthdate: null;
};

const UserEditor = ({
  user,
  payload,
  onDone,
}: {
  user: IUser;
  payload: EditUserInput;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("editingUser"));
  useSWR(
    { url: `${API}/users/update/${user.id}`, payload: payload },
    (args) => fetcher({ ...args, method: "PUT" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("editUserSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("editUserError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const EditUserPopup = ({
  user,
  refresh,
}: {
  user: IUser;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const [input, setInput] = useState<EditUserInput>({
    birthdate: null,
    email: user.email || "",
    family: user.family || "",
    mobile: user.mobile || "",
    name: user.name || "",
    sex: user.sex ? 1 : 0,
    about: user.about,
    address: user.address,
    eFamily: user.eFamily,
    eName: user.eName,
    website: user.website || "",
  });
  const [isEditing, setIsEditing] = useState<EditUserInput | null>(null);

  const { closePopup } = usePopup();

  const pushNotification = useNotification();

  const onEdit = () => {
    if (
      !user.email?.trim().length ||
      !user.name?.trim().length ||
      !user.family?.trim().length ||
      !user.mobile?.trim().length
    )
      return pushNotification(getContent("checkInput"), "Error");
    setIsEditing({ ...input });
  };

  const onAfterEdit = (status: boolean) => {
    setIsEditing(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("editUser")}>
        <Form onSubmit={onEdit} className={classes.main}>
          {fields.map((field) => (
            <Input
              required={requireds.includes(field)}
              key={field}
              label={getContent(field)}
              defaultValue={user[field] || ""}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, [field]: e.target.value }))
              }
            />
          ))}
          <div className={classes.actions}>
            <Button type="button" onClick={closePopup}>
              {getContent("cancel")}
            </Button>
            <Button type="submit">
              {getContent(isEditing ? "editingUser" : "editUser")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isEditing && (
        <UserEditor onDone={onAfterEdit} payload={isEditing} user={user} />
      )}
    </Fragment>
  );
};
export default EditUserPopup;
