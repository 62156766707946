import useLocale from "../../hooks/useLocale";
import PopupCard from "../Popup/PopupCard";
import { IMenu } from "./AccessMenuPage";
import classes from "./EditMenuPopup.module.css";
import Input from "../_UI/Input";
import { Fragment, useState } from "react";
import usePopup from "../../hooks/usePopup";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Button from "../_UI/Button";
import Form from "../_UI/Form";

type EditMenuPayload = {
  pName: string;
  enName: string;
  route: string;
  icon: "icon";
  parent_id: number | null;
};

const MenuCreator = ({
  onDone,
  payload,
  menu,
}: {
  onDone: (status: boolean) => void;
  payload: EditMenuPayload;
  menu?: IMenu;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(
    getContent(!!menu ? "editingMenu" : "creatingMenu")
  );
  useSWR(
    {
      url: `${API}/access/${!!menu ? `updateMenu/${menu.id}` : "storeMenu"}`,
      payload,
    },
    (args) =>
      fetcher({ ...args, method: !!menu ? "PUT" : "POST" }).then(
        (res) => res.data
      ),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(
          getContent(!!menu ? "editMenuSuccess" : "createMenuSuccess"),
          "Success"
        );
        onDone(true);
      },
      onError: () => {
        pushNotification(
          getContent(!!menu ? "editMenuError" : "createMenuError"),
          "Error"
        );
        onDone(false);
      },
    }
  );
  return null;
};

const EditMenuPopup = ({
  defaultValue,
  refresh,
}: {
  defaultValue?: IMenu;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const { data: menus } = useSWR<IMenu[]>(
    `${API}/access/menus`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  const [input, setInput] = useState<EditMenuPayload>({
    enName: defaultValue?.enName || "",
    icon: "icon",
    parent_id: defaultValue?.parent_id || null,
    pName: defaultValue?.pName || "",
    route: defaultValue?.route || "",
  });

  const { closePopup } = usePopup();

  const [isCreating, setIsCreating] = useState<EditMenuPayload | null>(null);

  const pushNotification = useNotification();

  const onCreate = () => {
    if (!input.pName || !input.enName || !input.route)
      return pushNotification(getContent("checkInput"), "Error");
    setIsCreating({ ...input });
  };

  const onAfterCreate = (status: boolean) => {
    setIsCreating(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent(defaultValue ? "editMenu" : "createMenu")}>
        <Form onSubmit={onCreate} className={classes.main}>
          <Input
            required
            label={getContent("persianName")}
            defaultValue={defaultValue?.pName}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, pName: e.target.value }))
            }
          />
          <Input
            required
            label={getContent("englishName")}
            defaultValue={defaultValue?.enName}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, enName: e.target.value }))
            }
          />
          <select>
            <option value="">{getContent("selectParentMenu")}</option>
            {menus
              ?.filter((menu) => menu.parent_id === null)
              .map((menu) => (
                <option key={menu.id} value={menu.id}>
                  {menu.pName}
                </option>
              ))}
          </select>
          <Input
            required
            label={getContent("menuRoute")}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, route: e.target.value }))
            }
            defaultValue={defaultValue?.route}
          />
          <div className={classes.actions}>
            <Button type="button" onClick={closePopup}>
              {getContent("cancel")}
            </Button>
            <Button type="submit">
              {getContent(!!defaultValue ? "editMenu" : "createMenu")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isCreating && (
        <MenuCreator
          onDone={onAfterCreate}
          payload={isCreating}
          menu={defaultValue}
        />
      )}
    </Fragment>
  );
};
export default EditMenuPopup;
