import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import useNotification from "../../hooks/useNotification";
import Form from "../_UI/Form";
import PopupCard from "../Popup/PopupCard";
import classes from "./UserPassPopup.module.css";
import { IUser } from "../../hooks/useUser";
import { isStrongPassword } from "../../helpers/isStrongPassword";
import usePopup from "../../hooks/usePopup";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Input from "../_UI/Input";
import Button from "../_UI/Button";

type ChangePassInput = {
  user_id: number;
  password: string;
  repassword: string;
};

const fields = ["password", "repassword"] as const;

const PassChanger = ({
  payload,
  onDone,
}: {
  payload: ChangePassInput;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("changingPassword"));
  useSWR(
    { url: `${API}/users/changepasslist`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("changePasswordSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("changePasswordError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const UserPassPopup = ({
  user,
  refresh,
}: {
  user: IUser;
  refresh: Function;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification();

  const [input, setInput] = useState<ChangePassInput>({
    password: "",
    repassword: "",
    user_id: Number(user.id),
  });

  const [isChanging, setIsChanging] = useState<ChangePassInput | null>(null);

  const { closePopup } = usePopup();

  const onChange = () => {
    if (!isStrongPassword(input.password))
      return pushNotification(getContent("passwordNotStrong"), "Error");
    if (input.password !== input.repassword)
      return pushNotification(getContent("passwordsnotMatch"), "Error");
    setIsChanging({ ...input });
  };

  const onAfterChange = (status: boolean) => {
    setIsChanging(null);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("changePass")}>
        <Form className={classes.main}>
          {fields.map((field) => (
            <Input
              required
              key={field}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, [field]: e.target.value }))
              }
              type="password"
              label={getContent(field)}
            />
          ))}
          <div className={classes.actions}>
            <Button onClick={closePopup} type="button">
              {getContent("cancel")}
            </Button>
            <Button onClick={onChange} type="submit">
              {getContent(isChanging ? "changingPassword" : "changePass")}
            </Button>
          </div>
        </Form>
      </PopupCard>
      {!!isChanging && (
        <PassChanger onDone={onAfterChange} payload={isChanging} />
      )}
    </Fragment>
  );
};
export default UserPassPopup;
