import { Fragment, useMemo, useState } from "react";
import useLocale from "../../hooks/useLocale";
import Button from "../_UI/Button";
import Form from "../_UI/Form";
import classes from "./ApplicationSettingsForm.module.css";
import { IApp, IAppSettings } from "../Servers/ManageAllServersPage";
import Input from "../_UI/Input";
import Toggle from "../_UI/Toggle";
import Area from "../_UI/Area";
import TimeSelector from "../_UI/TimeSelector";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";
import useUser from "../../hooks/useUser";

const SettingsSaver = ({
  onDone,
  payload,
  id,
}: {
  onDone: (status: boolean) => void;
  payload: IAppSettings;
  id: string;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("savingSettings"));
  useSWR(
    { url: `${API}/apps/updateSettings/${id}`, payload },
    (args) =>
      fetcher({
        url: args.url,
        payload: args.payload as any,
        method: "POST",
      }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("saveSettingsSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("saveSettingsError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const ApplicationSettingsForm = ({
  app,
  refresh,
}: {
  app: IApp;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const [input, setInput] = useState<IAppSettings>({
    aboutLink: app.aboutLink,
    activeBlockVersion: app.activeBlockVersion,
    activeEncryptSetting: app.activeEncryptSetting,
    adsForceByVPN: app.adsForceByVPN,
    adsForceByVPNInForeign: app.adsForceByVPNInForeign,
    adsForceByVPNInIran: app.adsForceByVPNInIran,
    appVersion: app.appVersion,
    autoConnect: app.autoConnect,
    blockVersion: app.blockVersion,
    configsActive: app.configsActive,
    connectDelay: app.connectDelay,
    detectLocationBy: app.detectLocationBy,
    disconnectDelay: app.disconnectDelay,
    email: app.email,
    englishUpdateText: app.englishUpdateText,
    forceUpdate: app.forceUpdate,
    googlePlayLink: app.googlePlayLink,
    instagram: app.instagram,
    policyLink: app.policyLink,
    telegram: app.telegram,
    updateText: app.updateText,
    UserConnectionLimit: app.UserConnectionLimit,
    youtube: app.youtube,
    smartActive: app.smartActive,
  });

  const [isSaving, setIsSaving] = useState<IAppSettings | null>(null);

  const onSave = () => {
    setIsSaving({ ...input });
  };

  const onAfterSave = (status: boolean) => {
    setIsSaving(null);
    if (!status) return;
    refresh();
  };

  const { user } = useUser(true);

  const readonly = useMemo<boolean>(() => user?.role_id !== 1, [user?.role_id]);

  return (
    <Fragment>
      <Form onSubmit={onSave} className={classes.main}>
        <h2 className={classes.title}>{getContent("applicationSettings")}</h2>
        <div className={classes.double}>
          <Input
            label={getContent("telegram")}
            defaultValue={app.telegram}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, telegram: e.target.value }))
            }
            readonly={readonly}
          />
          <Input
            label={getContent("instagram")}
            defaultValue={app.instagram}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, instagram: e.target.value }))
            }
            readonly={readonly}
          />
        </div>
        <div className={classes.double}>
          <Input
            label={getContent("youtube")}
            defaultValue={app.youtube}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, youtube: e.target.value }))
            }
            readonly={readonly}
          />
          <Input
            label={getContent("googlePlayLink")}
            defaultValue={app.googlePlayLink}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, googlePlayLink: e.target.value }))
            }
            readonly={readonly}
          />
        </div>
        <Input
          label={getContent("email")}
          defaultValue={app.email}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, email: e.target.value }))
          }
        />
        <TimeSelector
          onChange={(e) =>
            setInput((prev) => ({ ...prev, UserConnectionLimit: e }))
          }
          title={getContent("userConnectionLimit")}
          defaultValue={app.UserConnectionLimit}
        />
        <div className={classes.double}>
          <Input
            required
            type="number"
            label={getContent("connectDelay")}
            defaultValue={`${app.connectDelay}`}
            step={1}
            min={0}
            onChange={(e) =>
              setInput((prev) => ({
                ...prev,
                connectDelay: Number(e.target.value),
              }))
            }
          />
          <Input
            required
            type="number"
            label={getContent("disconnectDelay")}
            defaultValue={`${app.disconnectDelay}`}
            step={1}
            min={0}
            onChange={(e) =>
              setInput((prev) => ({
                ...prev,
                disconnectDelay: Number(e.target.value),
              }))
            }
          />
        </div>
        <div className={classes.selectContainer}>
          <legend className={classes.label}>
            {getContent("detectLocationBy")}
          </legend>
          <select
            className={classes.select}
            defaultValue={app.detectLocationBy}
            onChange={(e) =>
              setInput((prev) => ({
                ...prev,
                detectLocationBy: e.target.value,
              }))
            }
          >
            <option value="Timezone">Timezone</option>
            <option value="IP">IP</option>
          </select>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.adsForceByVPNInIran}
            onClick={() =>
              readonly
                ? null
                : setInput((prev) => ({
                    ...prev,
                    adsForceByVPNInIran: !prev.adsForceByVPNInIran,
                  }))
            }
          />
          <legend>{getContent("adsForceByVpnInIran")}</legend>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.adsForceByVPNInForeign}
            onClick={() =>
              readonly
                ? null
                : setInput((prev) => ({
                    ...prev,
                    adsForceByVPNInForeign: !prev.adsForceByVPNInForeign,
                  }))
            }
          />
          <legend>{getContent("adsForceByVpnInForeign")}</legend>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.adsForceByVPN}
            onClick={() =>
              readonly
                ? null
                : setInput((prev) => ({
                    ...prev,
                    adsForceByVPN: !prev.adsForceByVPN,
                  }))
            }
          />
          <legend>{getContent("adsForceByVpn")}</legend>
        </div>
        <Input
          label={getContent("policyLink")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, policyLink: e.target.value }))
          }
          defaultValue={app.policyLink}
        />
        <Input
          label={getContent("aboutLink")}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, aboutLink: e.target.value }))
          }
          defaultValue={app.aboutLink}
        />
        <h2 className={classes.title}>{getContent("updateApplication")}</h2>
        <div className={classes.inlineToggle}>
          <Input
            style={{ width: "fit-content", flex: 1 }}
            required
            type="number"
            label={getContent("appVersion")}
            min={0}
            step={0.01}
            defaultValue={app.appVersion}
            onChange={(e) =>
              setInput((prev) => ({ ...prev, appVersion: e.target.value }))
            }
          />
          <div className={classes.toggle}>
            <Toggle
              active={input.forceUpdate}
              onClick={() =>
                setInput((prev) => ({
                  ...prev,
                  forceUpdate: !prev.forceUpdate,
                }))
              }
            />
            <legend>{getContent("forceUpdate")}</legend>
          </div>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.activeBlockVersion}
            onClick={() =>
              setInput((prev) => ({
                ...prev,
                activeBlockVersion: !prev.activeBlockVersion,
              }))
            }
          />
          <legend>{getContent("activeBlockVersion")}</legend>
        </div>
        <div className={classes.push} />
        <div className={classes.toggle}>
          <Toggle
            active={input.configsActive}
            onClick={() =>
              readonly
                ? null
                : setInput((prev) => ({
                    ...prev,
                    configsActive: !prev.configsActive,
                  }))
            }
          />
          <legend>{getContent("configsActive")}</legend>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.activeEncryptSetting}
            onClick={() =>
              setInput((prev) => ({
                ...prev,
                activeEncryptSetting: !prev.activeEncryptSetting,
              }))
            }
          />
          <legend>{getContent("activeEncryptSetting")}</legend>
        </div>
        <div className={classes.toggle}>
          <Toggle
            active={input.autoConnect}
            onClick={() =>
              setInput((prev) => ({ ...prev, autoConnect: !prev.autoConnect }))
            }
          />
          <legend>{getContent("autoConnect")}</legend>
        </div>
        <Area
          label={getContent("updateText")}
          defaultValue={app.updateText}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, updateText: e.target.value }))
          }
          allowPersian
        />
        <Area
          label={getContent("englishUpdateText")}
          defaultValue={app.englishUpdateText}
          onChange={(e) =>
            setInput((prev) => ({ ...prev, englishUpdateText: e.target.value }))
          }
        />
        <Button type="submit" className={classes.submit}>
          {getContent(isSaving ? "savingSettings" : "save")}
        </Button>
      </Form>
      {!!isSaving && (
        <SettingsSaver id={app.id} onDone={onAfterSave} payload={isSaving} />
      )}
    </Fragment>
  );
};
export default ApplicationSettingsForm;
