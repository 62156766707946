import classes from "./VideoBox.module.css";
const VideoBox = ({
  title,
  text,
  video,
}: {
  title: string;
  text: string;
  video?: string;
}) => {
  return (
    <div className={classes.main}>
      <h1 className={classes.title}>{title}</h1>
      <legend className={classes.text}>{text}</legend>
      {video && (
        <div className={classes.video}>
          <video controls src={video} />
        </div>
      )}
    </div>
  );
};
export default VideoBox;
