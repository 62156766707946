import { Fragment, useState } from "react";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Button from "../_UI/Button";
import Title from "../_UI/Title";
import PopupCard from "../Popup/PopupCard";
import classes from "./DeviceDeletePopup.module.css";
import { IDevice } from "./ManageDevicesPage";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import useNotification from "../../hooks/useNotification";

const DeviceDeleter = ({
  device,
  onDone,
}: {
  onDone: Function;
  device: IDevice;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("deleting"));
  useSWR(
    `${API}/apps/destroyDevice/${device.id}`,
    (url) => fetcher({ url, method: "DELETE" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("deleteSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("deleteError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const DeviceDeletePopup = ({
  device,
  refresh,
}: {
  device: IDevice;
  refresh: Function;
}) => {
  const getContent = useLocale();

  const { closePopup } = usePopup();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDelete = () => setIsDeleting(true);

  const onAfterDelete = (status: boolean) => {
    setIsDeleting(false);
    if (!status) return;
    refresh();
    closePopup();
  };

  return (
    <Fragment>
      <PopupCard title={getContent("deleteDevice")}>
        <div className={classes.main}>
          <Title>{getContent("areYouSureDelete")}</Title>
          <div className={classes.actions}>
            <Button
              style={{ backgroundColor: "var(--danger)" }}
              onClick={onDelete}
            >
              {getContent("deleteDevice")}
            </Button>
            <Button onClick={closePopup}>{getContent("cancel")}</Button>
          </div>
        </div>
      </PopupCard>
      {isDeleting && <DeviceDeleter device={device} onDone={onAfterDelete} />}
    </Fragment>
  );
};
export default DeviceDeletePopup;
