import {
  Dispatch,
  Fragment,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import useLocale from "../../hooks/useLocale";
import useUser, { IPermission } from "../../hooks/useUser";
import classes from "./Sidebar.module.css";
import LocaleContext from "../../store/LocaleContext";
import { useTime } from "react-timer-hook";
import { persianize } from "../../helpers/persianize";
import { pad } from "../../helpers/pad";
import { MenuIcon } from "../Icons/MenuIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { DateIcon } from "../Icons/DateIcon";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { shouldKeepSidebarOpen } from "../../Constants/shouldKeepSidebarOpen";

const ParentLink = ({
  permission,
  toggleSidebar,
}: {
  permission: IPermission;
  toggleSidebar: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user } = useUser(true);
  const { locale } = useContext(LocaleContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <Fragment>
      <button
        className={`${classes.parent} ${
          location.pathname === permission.route ||
          !!permission.children.find(
            (child) => child.route === location.pathname
          ) ||
          (location.pathname === "/dashboard" && permission.route === "/")
            ? classes.active
            : ""
        }`}
        onClick={() => {
          if (!!permission.children.length) {
            setIsOpen((prev) => !prev);
          } else {
            navigate(
              permission.route === "/" ? "/dashboard" : permission.route
            );
            toggleSidebar(shouldKeepSidebarOpen());
          }
        }}
      >
        <span className={classes.linkIcon}>
          <MenuIcon />
        </span>
        <span>{locale === "fa" ? permission.pName : permission.enName}</span>
        {!!permission.children.length && (
          <span
            className={classes.chevron}
            style={{ transform: `rotateZ(${isOpen ? 180 : 0}deg)` }}
          >
            <ChevronIcon />
          </span>
        )}
      </button>
      {!!permission.children.length && (
        <div
          className={`${classes.children} ${isOpen ? classes.openChild : ""}`}
        >
          {permission.children.map((child) => (
            <Fragment key={child.id}>
              {child.route.includes("my-") && user?.role.name === "admin" ? (
                <Fragment />
              ) : (
                <Fragment>
                  {child.route.includes("manage") &&
                  user?.role.name !== "admin" ? (
                    <Fragment />
                  ) : child.route === "/app/devices" ? (
                    <Fragment />
                  ) : (
                    <button
                      className={`${classes.child} ${
                        location.pathname === child.route ? classes.active : ""
                      }`}
                      onClick={() => {
                        navigate(child.route);
                        toggleSidebar(shouldKeepSidebarOpen());
                      }}
                    >
                      <span className={classes.linkIcon}>
                        <MenuIcon />
                      </span>
                      <span>
                        {locale === "fa" ? child.pName : child.enName}
                      </span>
                    </button>
                  )}
                </Fragment>
              )}
            </Fragment>
          ))}
        </div>
      )}
    </Fragment>
  );
};

const Sidebar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { user } = useUser(undefined);
  const { hours, minutes, seconds } = useTime();

  const { locale } = useContext(LocaleContext);
  const getContent = useLocale();

  useEffect(() => {
    const listener = () => setIsOpen(shouldKeepSidebarOpen());
    window.addEventListener("resize", listener, false);
    return () => window.removeEventListener("resize", listener, false);
  }, [setIsOpen]);

  if (!user) return null;
  return (
    <Fragment>
      {isOpen && (
        <div className={classes.blur} onClick={() => setIsOpen(false)} />
      )}
      <div className={`${classes.main} ${isOpen ? classes.open : ""}`}>
        <div className={classes.image}>
          <img src={user.avatar} alt={user.name} />
        </div>
        <p className={classes.welcome}>{getContent("welcome")}</p>
        <p className={classes.username}>{`${
          locale === "en"
            ? `${getContent("dear")} ${user.eName || ""} ${user.eFamily || ""}`
            : `${user.name || ""} ${user.family || ""} ${getContent("dear")}`
        }`}</p>
        <div className={classes.date}>
          <div className={classes.dateLabel}>
            <span className={classes.linkIcon}>
              <DateIcon />
            </span>
            <span>{getContent("today")}</span>
          </div>
          <div className={classes.dates}>
            <span className={classes.dateValue}>
              {new Date().toLocaleDateString("fa-IR", {
                weekday: "long",
                day: "numeric",
                month: "long",
              })}
            </span>
            <span className={classes.dateValue}>
              {new Date().toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
        <div className={classes.date}>
          <div className={classes.dateLabel}>
            <span className={classes.linkIcon}>
              <DateIcon />
            </span>
            <span>{getContent("now")}</span>
          </div>
          <div className={classes.dates}>
            <span className={classes.dateValue}>
              {persianize(`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`)}
            </span>
          </div>
        </div>
        <div className={classes.links}>
          {user.permissions.map((permission) => (
            <ParentLink
              toggleSidebar={setIsOpen}
              key={permission.id}
              permission={permission}
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};
export default Sidebar;
