import useLocale from "../../hooks/useLocale";
import useUser from "../../hooks/useUser";
import VideoBox from "./VideoBox";
const AppAdminPanelTrainingPage = () => {
  useUser(true);
  const getContent = useLocale();
  return (
    <VideoBox
      text={getContent("adminTutorialText")}
      title={getContent("adminToturial")}
      video="https://new.makeiteasy.top/videos/admin-panel.mp4"
    />
  );
};
export default AppAdminPanelTrainingPage;
