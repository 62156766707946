import React, { ReactNode, useCallback, useState } from "react";

const locales = ["fa", "en"] as const;

export type Locale = (typeof locales)[number];

const LocaleContext = React.createContext<{
  locale: Locale;
  setLocale: (loc: Locale) => void;
}>({ locale: "fa", setLocale: () => {} });

export const LocaleContextProvider = (props: { children: ReactNode }) => {
  const [locale, _setLocale] = useState<Locale>(() => {
    const loc = localStorage.getItem("locale") as Locale;
    return locales.includes(loc) ? loc : "fa";
  });

  const setLocale = useCallback((loc: Locale) => {
    _setLocale(loc);
    localStorage.setItem("locale", loc);
  }, []);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {props.children}
    </LocaleContext.Provider>
  );
};

export default LocaleContext;
