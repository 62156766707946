import useLocale from "../../hooks/useLocale";
import PopupCard from "../Popup/PopupCard";
import { IApp } from "../Servers/ManageAllServersPage";
import classes from "./AppOverviewPopup.module.css";
const AppOverviewPopup = ({ app }: { app: IApp }) => {
  const getContent = useLocale();

  return (
    <PopupCard title={getContent("appDetails")}>
      <div className={classes.main}>
        <legend className={classes.title}>{getContent("sign")}</legend>
        <p className={classes.sign}>{app.sign}</p>
      </div>
    </PopupCard>
  );
};
export default AppOverviewPopup;
