import { useNavigate } from "react-router-dom";
import { IServer } from "./ManageAllServersPage";
import classes from "./ServerList.module.css";
import useLocale from "../../hooks/useLocale";
import usePopup from "../../hooks/usePopup";
import Table from "../_UI/Table";
import CreateNewServerPopup from "./CreateNewServerPopup";
import IconButton from "../_UI/IconButton";
import ServerOverviewPopup from "./ServerOverviewPopup";
import { EyeIcon } from "../Icons/EyeIcon";
import { DiamondIcon } from "../Icons/DiamondIcon";
import { EditIcon } from "../Icons/EditIcon";
import DeleteServerPopup from "./DeleteServerpopup";
import { GarbageIcon } from "../Icons/GarbaageIcon";
import Button from "../_UI/Button";
import SelectSegment from "../_UI/SelectSegment";
import Input from "../_UI/Input";
import Loading from "../_UI/Loading";
import BatchServerDeleterPopup from "./BatchServerDeleterPopup";
import { Dispatch, SetStateAction, useMemo } from "react";

type ServerFilter = { name?: string; app?: number };

const FilterServers: (props: {
  filter: ServerFilter | undefined;
  setFilter: Dispatch<SetStateAction<ServerFilter | undefined>>;
  refresh: Function;
}) => JSX.Element = ({ filter, setFilter, refresh }) => {
  const getContent = useLocale();
  const { setPopup } = usePopup();
  return (
    <div className={classes.filterContainer}>
      <div className={classes.create}>
        <Button
          onClick={() => setPopup(<CreateNewServerPopup refresh={refresh} />)}
        >
          {getContent("createServer")}
        </Button>
      </div>
      <div className={classes.filter}>
        <div className={classes.segment}>
          <Input
            label={getContent("search")}
            onChange={(e) => {
              setFilter((prev) => ({
                ...prev,
                name: e.target.value.trim().toLowerCase(),
              }));
            }}
          />
          <Button
            onClick={() => setFilter((prev) => ({ ...prev, name: undefined }))}
          >
            {getContent("clear")}
          </Button>
        </div>
        <div className={classes.segment}>
          <SelectSegment
            multi={false}
            onChange={(val) => setFilter((prev) => ({ ...prev, app: val }))}
            segment="application"
            defaultValue={filter?.app}
          />
          <Button
            onClick={() => setFilter((prev) => ({ ...prev, app: undefined }))}
          >
            {getContent("clear")}
          </Button>
        </div>
      </div>
    </div>
  );
};

const filterer: (node: IServer, filter: ServerFilter) => boolean = (
  node,
  filter
) => {
  if (
    filter.name !== undefined &&
    !(
      node.ip.toLowerCase().includes(filter.name.toLowerCase()) ||
      node.apps
        .map((app) => app.app.name)
        .join(",")
        .toLowerCase()
        .includes(filter.name.toLowerCase())
    )
  )
    return false;
  if (
    filter.app !== undefined &&
    !node.apps.find((app) => Number(app.app.id) === Number(filter.app))
  )
    return false;
  return true;
};

const ServerList = ({
  data,
  mutate,
  batch,
}: {
  data: IServer[] | undefined;
  mutate: Function;
  batch?: boolean;
}) => {
  const navigate = useNavigate();

  const getContent = useLocale();

  const { setPopup } = usePopup();

  const filter = useMemo(
    () => ({
      filterComponent: (props: {
        filter: ServerFilter | undefined;
        setFilter: Dispatch<SetStateAction<ServerFilter | undefined>>;
      }) => <FilterServers {...props} refresh={mutate} />,
      filter: filterer,
    }),
    [mutate]
  );

  if (!data) return <Loading />;
  return (
    <Table<IServer & { index: number }, ServerFilter>
      data={data.map((node, i) => ({ ...node, index: i + 1 }))}
      batchActions={
        batch
          ? [
              {
                name: getContent("delete"),
                action: (nodes) =>
                  setPopup(
                    <BatchServerDeleterPopup servers={nodes} refresh={mutate} />
                  ),
              },
            ]
          : undefined
      }
      filter={filter}
      title={getContent("serversList")}
      renderer={{
        index: {
          name: "#",
          component: (node) => <span>{node.index}</span>,
          width: "2rem",
          sort: (a, b) => a.index - b.index,
        },
        app: {
          name: getContent("application"),
          component: (node) => (
            <span>{node.apps.map((app) => app.app.name).join(",")}</span>
          ),
          sort: (a, b) =>
            a.apps
              .map((app) => app.app.name)
              .join(",")
              .localeCompare(b.apps.map((app) => app.app.name).join(",")),
        },
        location: {
          name: getContent("location"),
          component: (node) => (
            <span className={classes.location}>
              <span>{node.location.code}</span>
              <span className={classes.flag}>
                <img src={node.flagUrl} alt={node.location.name} />
              </span>
            </span>
          ),
          sort: (a, b) => a.location.name.localeCompare(b.location.name),
        },
        name: {
          name: getContent("serverName"),
          component: (node) => <span>{node.ip}</span>,
          sort: (a, b) => a.ip.localeCompare(b.ip),
        },
        actions: {
          name: getContent("actions"),
          component: (node) => (
            <span className={classes.actions}>
              <IconButton
                style={{ backgroundColor: "var(--green)" }}
                onClick={() => setPopup(<ServerOverviewPopup server={node} />)}
                title={getContent("ServerDetails")}
              >
                <EyeIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--blue)" }}
                onClick={() => navigate(`/servers/${node.id}`)}
                title={getContent("ServerProfiles")}
              >
                <DiamondIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--cyan)" }}
                onClick={() =>
                  setPopup(
                    <CreateNewServerPopup
                      refresh={mutate}
                      defaultValue={node}
                    />
                  )
                }
                title={getContent("EditServer")}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                style={{ backgroundColor: "var(--danger)" }}
                onClick={() =>
                  setPopup(<DeleteServerPopup server={node} refresh={mutate} />)
                }
                title={getContent("DeleteServer")}
              >
                <GarbageIcon />
              </IconButton>
            </span>
          ),
        },
      }}
    />
  );
};
export default ServerList;
