import classes from "./Toggle.module.css";
const Toggle = (props: { active: boolean; onClick?: Function }) => {
  return (
    <div
      className={`${classes.track} ${props.active ? classes.active : ""}`}
      onClick={() => props.onClick?.()}
    >
      <div className={classes.thumb} />
    </div>
  );
};
export default Toggle;
