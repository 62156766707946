import {
  CSSProperties,
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import classes from "./Table.module.css";
import Button from "./Button";
import usePopup from "../../hooks/usePopup";
import useLocale from "../../hooks/useLocale";
import { ChevronIcon } from "../Icons/ChevronIcon";
import { DoubleChevronIcon } from "../Icons/DoubleChevronIcon";
import { SortIcon } from "../Icons/SortIcon";
import useNotification from "../../hooks/useNotification";

const limits = [30, 50, 100] as const;

type Limit = (typeof limits)[number];

export type FilterComponent<K> = (props: {
  filter: K | undefined;
  setFilter: Dispatch<SetStateAction<K | undefined>>;
}) => JSX.Element;

const Pagination = ({
  page,
  setPage,
  maxPages,
}: {
  page: number;
  setPage: (page: number) => void;
  maxPages: number;
}) => {
  return (
    <div className={classes.pagination}>
      <button
        className={page === 1 ? classes.disabled : ""}
        onClick={() => setPage(1)}
        style={{ transform: "rotateZ(90deg)" }}
      >
        <DoubleChevronIcon />
      </button>
      <button
        style={{ transform: "rotateZ(90deg)" }}
        className={page === 1 ? classes.disabled : ""}
        onClick={() => (page !== 1 ? setPage(page - 1) : null)}
      >
        <ChevronIcon />
      </button>
      {page > 3 && <span className={classes.disabled}>...</span>}
      {page === 3 && (
        <button key={"page1"} onClick={() => setPage(1)}>
          1
        </button>
      )}
      {page !== 1 && (
        <button key={`page${page - 1}`} onClick={() => setPage(page - 1)}>
          {page - 1}
        </button>
      )}
      <button key={`page${page}`} className={classes.current}>
        {page}
      </button>
      {page !== maxPages && (
        <button key={`page${page + 1}`} onClick={() => setPage(page + 1)}>
          {page + 1}
        </button>
      )}
      {page === maxPages - 2 && (
        <button key={`page${maxPages}`} onClick={() => setPage(maxPages - 2)}>
          {maxPages}
        </button>
      )}
      {page < maxPages - 2 && <span className={classes.disabled}>...</span>}
      <button
        className={page === maxPages ? classes.disabled : ""}
        onClick={() => (page !== maxPages ? setPage(page + 1) : null)}
        style={{ transform: "rotateZ(-90deg)" }}
      >
        <ChevronIcon />
      </button>
      <button
        className={page === maxPages ? classes.disabled : ""}
        onClick={() => setPage(maxPages)}
        style={{ transform: "rotateZ(-90deg)" }}
      >
        <DoubleChevronIcon />
      </button>
    </div>
  );
};

export type ServerPage = { page: number; size: number };

const Table = <
  T extends { id: string | number; [key: string]: any },
  K extends { [key: string]: unknown }
>({
  data,
  title,
  renderer,
  filter,
  create,
  serverPage,
  serverFilter,
  className,
  style,
  batchActions,
  exporter,
}: {
  create?: { popup: ReactNode; title: string };
  data: T[];
  title: string;
  renderer: {
    [key: string]: {
      name: string;
      component: (node: T) => ReactNode;
      sort?: (a: T, b: T) => number;
      width?: string;
    };
  };
  exporter?: {
    [key: string]: { name: string; valueGetter: (node: T) => string };
  };
  filter?: {
    filterComponent: (props: {
      filter: K | undefined;
      setFilter: Dispatch<SetStateAction<K | undefined>>;
    }) => JSX.Element;
    filter: (node: T, filter: K) => boolean;
    defaultFilter?: K;
  };
  serverFilter?: ReactNode;
  serverPage?: {
    page: ServerPage;
    setPage: Dispatch<SetStateAction<ServerPage>>;
    total: number;
  };
  className?: string;
  style?: CSSProperties;
  batchActions?: {
    action: (nodes: T[]) => unknown;
    name: string;
  }[];
}) => {
  const [filterArgs, setFilter] = useState<K | undefined>(
    filter?.defaultFilter
  );
  const [limit, setLimit] = useState<Limit>(30);
  const [page, setPage] = useState<number>(1);
  const [sort, setSort] = useState<string | null>(null);
  const [asc, setAsc] = useState<boolean>(true);

  const [showbatch, setShowBatch] = useState<boolean>(false);

  useEffect(() => {
    if (showbatch) {
      const listener = () => setShowBatch(false);
      setTimeout(() => window.addEventListener("click", listener, false), 100);
      return () => window.removeEventListener("click", listener, false);
    }
  }, [showbatch]);

  const [selected, setSelected] = useState<T[]>([]);

  useEffect(() => setPage(1), [limit]);

  const getContent = useLocale();

  const { setPopup } = usePopup();

  const readyData = useMemo<T[]>(() => {
    let result = [...data];
    if (filterArgs && filter)
      result = result.filter((node) => filter.filter(node, filterArgs));
    const _sort = renderer[sort as any]?.sort;
    if (_sort) result = result.sort((a, b) => _sort(a, b) * (asc ? 1 : -1));
    return result;
  }, [asc, data, filter, filterArgs, renderer, sort]);

  // useEffect(() => {
  //   setSelected([]);
  // }, [readyData, page, limit]);

  const pushNotification = useNotification();

  const onExport = () => {
    if (!exporter) return;
    if (!readyData.length)
      return pushNotification(getContent("emptyTable"), "Error");
    pushNotification(getContent("exporting"));
    const rows: string[][] = [];
    rows.push(Object.keys(exporter).map((key) => exporter[key].name));
    for (let i = 0; i < readyData.length; i++) {
      rows.push(
        Object.keys(exporter).map((key) =>
          exporter[key].valueGetter(readyData[i])
        )
      );
    }
    const csvContent = "\uFEFF" + rows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    pushNotification(getContent("checkDownload"), "Success");
  };

  return (
    <div className={`${classes.main} ${className || ""}`} style={style}>
      <div className={classes.header}>
        <h1 className={classes.mainTitle}>{title}</h1>
        <div className={classes.headerActions}>
          {!!exporter && (
            <Button onClick={onExport}>{getContent("export")}</Button>
          )}
          {!!create && (
            <Button onClick={() => setPopup(create.popup)}>
              {create.title}
            </Button>
          )}
        </div>
      </div>
      {!!filter && (
        <div className={classes.filter}>
          <filter.filterComponent filter={filterArgs} setFilter={setFilter} />
        </div>
      )}
      {serverFilter}
      <div
        className={classes.body}
        style={{
          gridTemplateColumns: `${batchActions ? "2rem " : ""}${Object.keys(
            renderer
          )
            .map((key) => renderer[key].width || "auto")
            .join(" ")}`,
        }}
      >
        {batchActions && (
          <legend
            className={`${classes.batchButton} `}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (!!selected.length) {
                if (
                  readyData.slice((page - 1) * limit, page * limit).length ===
                  selected.length
                ) {
                  setSelected([]);
                } else {
                  setSelected(
                    readyData.slice((page - 1) * limit, page * limit)
                  );
                }
              } else {
                setSelected(readyData.slice((page - 1) * limit, page * limit));
              }
            }}
          >
            <span
              className={`${classes.delete} ${
                selected.length
                  ? readyData.slice((page - 1) * limit, page * limit).length ===
                    selected.length
                    ? classes.full
                    : classes.half
                  : ""
              }`}
            />
          </legend>
        )}
        {Object.keys(renderer).map((key) => (
          <legend
            key={`${key}Title`}
            style={{
              cursor: !!renderer[key].sort ? "pointer" : "default",
              width: renderer[key].width,
            }}
            onClick={() => {
              if (renderer[key].sort) {
                if (sort === key) {
                  if (asc) {
                    setAsc(false);
                  } else {
                    setAsc(true);
                    setSort(null);
                  }
                } else {
                  setSort(key);
                  setAsc(true);
                }
              }
            }}
          >
            <span className={classes.titleText}>{renderer[key].name}</span>
            {!!renderer[key].sort && (
              <span className={classes.sort}>
                <span className={classes.sortIcon}>
                  <SortIcon fill={sort === key && asc} />
                </span>
                <span
                  className={classes.sortIcon}
                  style={{ transform: "rotateZ(180deg)" }}
                >
                  <SortIcon fill={sort === key && !asc} />
                </span>
              </span>
            )}
          </legend>
        ))}
        {!!readyData.length && (
          <Fragment>
            {readyData
              .slice((page - 1) * limit, page * limit)
              .map((node, i) => (
                <Fragment key={`${node.id}`}>
                  {batchActions && (
                    <div
                      onClick={() =>
                        setSelected((prev) => {
                          const temp = [...prev];
                          if (!!prev.find((el) => el.id === node.id)) {
                            temp.splice(
                              prev.findIndex((el) => el.id === node.id),
                              1
                            );
                          } else {
                            temp.push(node);
                          }
                          return temp;
                        })
                      }
                      className={`${classes.select} ${
                        !!selected.find((el) => el.id === node.id)
                          ? classes.selected
                          : ""
                      }`}
                    />
                  )}
                  {Object.keys(renderer).map((key) => (
                    <div
                      key={`${node.id}${key}`}
                      className={`${classes.row} ${
                        !!(i % 2) ? classes.odd : ""
                      }`}
                      style={{ width: renderer[key].width || undefined }}
                    >
                      <span className={classes.inlineTitle}>
                        {renderer[key].name} :
                      </span>
                      <span className={classes.value}>
                        {renderer[key].component(node)}
                      </span>
                    </div>
                  ))}
                </Fragment>
              ))}
          </Fragment>
        )}
      </div>
      {!readyData.length && (
        <p className={classes.nothing}>{getContent("noContent")}</p>
      )}
      {!!readyData.length && (
        <div className={classes.footer}>
          <div className={classes.limit}>
            <p>{getContent("itemsPerPage")}</p>
            <select
              onChange={(e) =>
                serverPage
                  ? serverPage.setPage({
                      page: 1,
                      size: Number(e.target.value),
                    })
                  : setLimit(Number(e.target.value) as Limit)
              }
              className={classes.selectLimit}
            >
              {limits.map((limit) => (
                <option key={`Limit${limit}`} value={limit}>
                  {limit}
                </option>
              ))}
            </select>
          </div>
          <Pagination
            page={serverPage ? serverPage.page.page : page}
            setPage={(page) =>
              serverPage
                ? serverPage.setPage((prev) => ({ ...prev, page }))
                : setPage(page)
            }
            maxPages={
              serverPage
                ? Math.ceil(serverPage.total / serverPage.page.size) || 1
                : Math.ceil(readyData.length / limit) || 1
            }
          />
        </div>
      )}
      {!!batchActions && !!selected.length && (
        <div className={classes.batches}>
          {batchActions.map((action) => (
            <Button
              key={action.name}
              onClick={() => {
                action.action([...selected]);
                setSelected([]);
              }}
              style={{ backgroundColor: "var(--danger)" }}
            >
              {`${action.name} (${selected.length})`}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
export default Table;
