import { Fragment, useState } from "react";
import Form from "../_UI/Form";
import Input from "../_UI/Input";
import classes from "./NewUserPage.module.css";
import useLocale from "../../hooks/useLocale";
import Button from "../_UI/Button";
import SelectSegment from "../_UI/SelectSegment";
import useNotification from "../../hooks/useNotification";
import useSWR from "swr";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import Title from "../_UI/Title";
import useUser from "../../hooks/useUser";

const genders = ["female", "male"] as const;

const genderToNum: { [key in (typeof genders)[number]]: 0 | 1 } = {
  female: 0,
  male: 1,
};

const requireds = ["name", "family", "email", "password", "username"] as const;

const persians = ["name", "family"];

const rest = [
  "eName",
  "eFamily",
  "address",
  "mobile",
  "website",
  "about",
] as const;

const fields = [...requireds, ...rest] as const;

type SignupInput = { [key in (typeof fields)[number]]: string } & {
  sex: 0 | 1;
  role_id: number;
};

const Signuper = ({
  payload,
  onDone,
}: {
  payload: SignupInput;
  onDone: (status: boolean) => void;
}) => {
  const getContent = useLocale();
  const pushNotification = useNotification(getContent("creatingUser"));
  useSWR(
    { url: `${API}/users/signup`, payload },
    (args) => fetcher({ ...args, method: "POST" }).then((res) => res.success),
    {
      dedupingInterval: 0,
      onSuccess: () => {
        pushNotification(getContent("createUserSuccess"), "Success");
        onDone(true);
      },
      onError: () => {
        pushNotification(getContent("createUserError"), "Error");
        onDone(false);
      },
    }
  );
  return null;
};

const initInputState: SignupInput = {
  about: "",
  address: "",
  eFamily: "",
  email: "",
  eName: "",
  family: "",
  mobile: "",
  name: "",
  password: "",
  role_id: 0,
  sex: 1,
  username: "",
  website: "",
};

const NewUserPage = () => {
  useUser(true);
  const [input, setInput] = useState<SignupInput>(initInputState);

  const [shouldShow, setShouldShow] = useState<boolean>(true);

  const getContent = useLocale();

  const pushNotification = useNotification();

  const [isSigningup, setIsSigningup] = useState<SignupInput | null>(null);

  const onSignup = () => {
    if (
      !input.email.trim().length ||
      !input.username.trim().length ||
      !input.password.trim().length ||
      !input.role_id ||
      !input.name.trim().length ||
      !input.family.trim().length
    )
      return pushNotification(getContent("checkInput"), "Error");
    setIsSigningup({ ...input });
  };

  const onAfterSignup = (status: boolean) => {
    setIsSigningup(null);
    if (!status) return;
    setInput(initInputState);
    setShouldShow(false);
    setTimeout(() => setShouldShow(true));
  };

  return (
    <Fragment>
      {shouldShow && (
        <Form onSubmit={onSignup} className={classes.main}>
          <Title>{getContent("createUser")}</Title>
          {requireds.map((field) => (
            <Input
              required
              type={field === "password" ? "password" : "text"}
              key={field}
              label={getContent(field)}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, [field]: e.target.value }))
              }
              allowPersian={persians.includes(field)}
            />
          ))}
          <SelectSegment
            required
            segment="role"
            multi={false}
            onChange={(e) => setInput((prev) => ({ ...prev, role_id: e }))}
          />
          {rest.map((field) => (
            <Input
              key={field}
              label={getContent(field)}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, [field]: e.target.value }))
              }
            />
          ))}
          <div className={classes.genderSelector}>
            <Title>{getContent("gender")}</Title>
            <div className={classes.genders}>
              {genders.map((gender) => (
                <button
                  type="button"
                  className={`${classes.gender} ${
                    input.sex === genderToNum[gender] ? classes.active : ""
                  }`}
                  onClick={() =>
                    setInput((prev) => ({ ...prev, sex: genderToNum[gender] }))
                  }
                >
                  {getContent(gender)}
                </button>
              ))}
            </div>
          </div>
          <Button type="submit" className={classes.submit}>
            {getContent(isSigningup ? "creatingUser" : "createUser")}
          </Button>
        </Form>
      )}
      {!!isSigningup && (
        <Signuper payload={isSigningup} onDone={onAfterSignup} />
      )}
    </Fragment>
  );
};
export default NewUserPage;
