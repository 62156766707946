import useSWR from "swr";
import { IApp } from "../Servers/ManageAllServersPage";
import { API } from "../../config";
import { fetcher } from "../../helpers/fetcher";
import ErrorPage from "../Error/ErrorPage";
import Loading from "../_UI/Loading";
import MyAppsList from "./MyAppsList";
import useUser from "../../hooks/useUser";
const ManageMyAppsPage = () => {
  useUser(true);
  const { data, error } = useSWR<IApp[]>(
    `${API}/apps?state=appAdmin`,
    (url: string) => fetcher({ url }).then((res) => res.data)
  );

  if (!data && error) return <ErrorPage />;
  if (!data) return <Loading />;
  return <MyAppsList apps={data} />;
};
export default ManageMyAppsPage;
